// *********** VALIDATE FORM VALUES :: return error object ***********
const validateFormValues = (values, validations, language) => {
  const { name, email, message } = values
  const errors = {}
  const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/

  if (name.length > 40) {
    errors.name = validations?.nameLength[language]
  }

  if (email && !emailRegex.test(email)) {
    errors.email = validations?.validEmail[language]
  }

  if (!message.trim()) {
    errors.message = validations?.message[language]
  } else if (message.length > 200) {
    errors.message = validations?.messageLength[language]
  }

  return errors
}

export { validateFormValues }
