import React from 'react'

/**
 *
 * @returns Dropdown Icon
 */
export default function Dropdown({ className }) {
  return (
    <svg viewBox="0 0 20 11" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L9.85 9L19 1" stroke="inherit" strokeWidth="2" />
    </svg>
  )
}
