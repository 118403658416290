import React from 'react'
import { createRoot } from 'react-dom/client'
import './main.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'

// const root = ReactDOM.createRoot(document.getElementById('root'))
// const el = document.getElementById('root')
// const language = el.getAttribute('data-language')

const WidgetDivs = document.querySelectorAll('.MTS__parking')
WidgetDivs.forEach((Div) => {
  const root = createRoot(Div)
  const lang = Div.getAttribute('data-language')
  root.render(
    <React.StrictMode>
      <App language={lang || 'en'} />
    </React.StrictMode>,
  )
})

// root.render(
//   <React.StrictMode>
//     <App language={language || 'en'} />
//   </React.StrictMode>,
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
