import React from 'react'

/**
 * @description Render steps for multi step form
 * @param {*} children children of the component --- accordion body
 * @param {number} step multistep form current step
 * @param {number} id unique id for multi steps
 * @param {boolean} line steps vertical line --- not showing on last step
 * @param {Function} onToggle hide/show accordion
 * @param {number} activeTab active accordion tab
 * @returns
 */
export default function StepIndicator({ children, step, id, line, onToggle, activeTab }) {
  return (
    <>
      {id <= step ? (
        <div className={`MTS__relative MTS__cursor-pointer MTS__h-full ${
          step
            ? id <= step
              ? step === 3 && id < step
                ? 'MTS__opacity-50 MTS__pointer-events-none'
                : 'MTS__opacity-100'
              : 'MTS__opacity-50 MTS__pointer-events-none'
            : ''
        }`} onClick={onToggle}>
          <div
            className={`MTS__flex MTS__w-8 MTS__h-8 MTS__text-base MTS__font-bold MTS__text-white MTS__rounded-full MTS__line lg:MTS__w-12 lg:MTS__h-12 2xl:MTS__w-14 2xl:MTS__h-14 lg:MTS__text-xl MTS__place-content-center MTS__place-items-center ${
              activeTab === id ? 'MTS__bg-light MTS__bg-cover' : ' MTS__bg-dark MTS__bg-cover'
            } MTS__font-secondary`}
          >
            {children}
          </div>
          {line && (
            <div
              className={`MTS__absolute MTS__block MTS__w-10 MTS__left-1/2 MTS__p-0 MTS__border MTS__border-b-0 MTS__border-r-0 MTS__border-t-0  ${
                id <= step - 1 ? 'MTS__border-secondary' : 'MTS__border-tertiary'
              }`}
              style={{
                height: `calc(100%)`,
              }}
            ></div>
          )}
        </div>
      ) : (
        <div className={`MTS__relative MTS__h-full ${
          step
            ? id <= step
              ? step === 3 && id < step
                ? 'MTS__opacity-50 MTS__pointer-events-none'
                : 'MTS__opacity-100'
              : 'MTS__opacity-50 MTS__pointer-events-none'
            : ''
        }`}>
          <div className="MTS__flex MTS__w-8 MTS__h-8 MTS__text-base MTS__rounded-full lg:MTS__w-12 lg:MTS__h-12 2xl:MTS__w-14 2xl:MTS__h-14 MTS__text-tertiary lg:MTS__text-xl MTS__place-content-center MTS__place-items-center MTS__border-whit MTS__bg-whitesmoke MTS__font-secondary">
            {children}
          </div>
          {line && (
            <div
              className={`MTS__absolute MTS__block MTS__w-10 MTS__left-1/2 MTS__p-0 MTS__border MTS__border-b-0 MTS__border-r-0 MTS__border-t-0 ${
                id <= step - 1 ? 'MTS__border-secondary' : 'MTS__border-tertiary'
              }`}
              style={{
                height: `calc(100%)`,
              }}
            ></div>
          )}
        </div>
      )}
    </>
  )
}
