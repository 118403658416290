import React from 'react'

/**
 *
 * @returns Trash Icon
 */
export default function NoCamper({ className }) {
  return (
    // <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <svg width="44" height="46" viewBox="0 0 265 265" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3064_35693)">
        <path d="M61 107C61.46 107 61.92 106.98 62.37 106.98C110.07 106.98 157.77 106.98 205.47 106.98H207.11C207.11 109.26 207.31 111.44 207.06 113.56C206.75 116.14 204.46 118.01 201.84 118.1C199.55 118.18 197.26 118.13 194.97 118.12C194.73 118.12 194.41 118.13 194.25 118C192.83 116.82 191.15 116.96 189.49 116.96C180.16 116.96 170.83 116.96 161.5 116.96C157.29 116.96 155.34 118.93 155.34 123.18C155.34 139.55 155.34 155.93 155.34 172.3C155.34 173.13 155.34 173.95 155.34 174.87H150.06C150.06 174.38 150.06 173.9 150.06 173.42C150.06 160.01 150.06 146.59 150.06 133.18C150.06 130.03 149.01 128.97 145.88 128.96C136.51 128.96 127.13 128.96 117.76 128.96C114.91 128.96 113.75 130.12 113.75 132.97C113.75 146.43 113.75 159.88 113.75 173.34C113.75 173.83 113.75 174.32 113.75 174.88H109.25C109.69 169.86 108.28 165.45 104.98 161.73C101.96 158.32 98.11 156.35 93.55 156C88.2 155.59 83.53 157.21 79.72 161.06C75.95 164.87 74.56 169.58 74.78 174.96H68.44C66.86 174.96 65.27 174.9 63.69 174.98C62.54 175.04 61.66 174.71 61.02 173.75V107H61ZM89.35 128.96C85.4 128.96 81.44 128.95 77.49 128.96C75.64 128.96 74.79 129.8 74.77 131.61C74.75 134.23 74.75 136.86 74.77 139.48C74.78 141.23 75.62 142.11 77.38 142.11C85.33 142.13 93.28 142.13 101.23 142.11C103 142.11 103.74 141.35 103.76 139.59C103.78 136.97 103.77 134.34 103.76 131.72C103.76 129.67 103.04 128.96 100.96 128.96C97.09 128.95 93.22 128.96 89.35 128.96Z" fill="black" />
        <path d="M61 93.25C61.64 92.31 62.5 92 63.65 92C109.44 92.03 155.23 92.02 201.02 92.02C204.91 92.02 207.2 94.33 207.21 98.25C207.21 99.95 207.21 101.64 207.21 103.48H205.49C157.91 103.48 110.33 103.48 62.75 103.48C62.17 103.48 61.58 103.49 61 103.5V93.25Z" fill="black" />
        <path d="M211 173.76C210.36 174.71 209.48 175.03 208.33 174.99C206.34 174.92 204.35 174.97 202.25 174.97C202.45 169.06 200.73 164.01 196.29 160.12C192.95 157.2 189 155.88 184.58 155.94C181.34 155.98 178.32 156.86 175.6 158.59C172.88 160.32 170.8 162.66 169.38 165.59C167.96 168.51 167.59 171.62 167.6 174.93C165.12 174.93 162.72 175.03 160.34 174.85C159.81 174.81 159.29 173.84 158.88 173.22C158.71 172.96 158.84 172.49 158.84 172.11C158.84 155.83 158.84 139.54 158.84 123.26C158.84 121 159.36 120.49 161.64 120.49C171.18 120.49 180.71 120.51 190.25 120.47C191.53 120.47 192.3 120.87 192.95 121.99C198.52 131.6 204.13 141.18 209.74 150.77C210.14 151.45 210.59 152.1 211.01 152.77V173.77L211 173.76ZM181.36 147.43C185.44 147.43 189.52 147.43 193.6 147.43C195.14 147.43 195.73 146.83 195.75 145.34C195.77 143.55 195.81 141.76 195.72 139.97C195.69 139.36 195.43 138.63 195.02 138.19C192.05 135.05 189.04 131.95 185.97 128.9C185.5 128.43 184.68 128.09 184.02 128.08C179.15 128.01 174.28 128.04 169.41 128.05C167.76 128.05 167.04 128.76 167.04 130.39C167.04 135.3 167.04 140.21 167.04 145.12C167.04 146.85 167.62 147.42 169.37 147.43C173.37 147.43 177.36 147.43 181.36 147.43Z" fill="black" />
        <path d="M146.43 174.9H117.34V132.59H146.43V174.9ZM143.92 150.36C143.92 147.72 141.46 145.35 138.7 145.34C136.04 145.32 133.69 147.69 133.69 150.39C133.69 153.22 135.97 155.61 138.66 155.62C141.5 155.62 143.93 153.2 143.92 150.36Z" fill="black" />
        <path d="M105.79 173.28C105.76 180.88 99.5897 187.01 92.0097 186.99C84.4197 186.96 78.2197 180.68 78.2797 173.06C78.3397 165.51 84.5897 159.38 92.1697 159.44C99.7897 159.5 105.83 165.63 105.79 173.28ZM92.0897 179.33C95.2397 179.3 97.9197 176.48 97.9197 173.2C97.9197 169.91 95.1097 167.01 91.9597 167.04C88.6497 167.07 85.9097 169.9 85.9397 173.27C85.9697 176.65 88.7197 179.37 92.0897 179.34V179.33Z" fill="black" />
        <path d="M198.7 173.24C198.68 180.78 192.46 186.98 184.91 186.98C177.35 186.98 171.19 180.79 171.18 173.21C171.18 165.55 177.35 159.39 185 159.43C192.59 159.48 198.72 165.65 198.7 173.23V173.24ZM184.9 167.03C181.44 167.03 178.75 169.76 178.77 173.24C178.78 176.7 181.39 179.31 184.87 179.33C188.45 179.35 191.03 176.79 191.04 173.21C191.04 169.65 188.43 167.02 184.91 167.03H184.9Z" fill="black" />
        <path d="M78.2998 138.53V132.59H100.18V138.53H78.2998Z" fill="black" />
        <path d="M192.25 143.85H170.62V131.58C171.46 131.58 172.24 131.58 173.02 131.58C176.27 131.58 179.52 131.55 182.76 131.61C183.27 131.61 183.91 131.86 184.27 132.21C186.81 134.73 189.3 137.3 191.78 139.88C192.04 140.15 192.2 140.6 192.23 140.97C192.29 141.88 192.25 142.8 192.25 143.85Z" fill="black" />
        <path d="M137.18 150.4C137.28 149.47 137.73 148.89 138.69 148.92C139.61 148.95 140.3 149.39 140.39 150.38C140.46 151.16 139.48 152.08 138.68 152.05C137.63 152.01 137.33 151.26 137.18 150.4Z" fill="black" />
        <path d="M94.4105 173.26C94.3705 174.64 93.2705 175.79 91.9805 175.79C90.5405 175.79 89.4005 174.58 89.4405 173.12C89.4805 171.73 90.6505 170.56 91.9905 170.56C93.2405 170.56 94.4405 171.9 94.4005 173.25L94.4105 173.26Z" fill="black" />
        <path d="M187.53 173.21C187.53 174.69 186.39 175.79 184.9 175.78C183.44 175.77 182.27 174.59 182.29 173.16C182.31 171.75 183.54 170.55 184.95 170.56C186.39 170.57 187.54 171.75 187.54 173.21H187.53Z" fill="black" />
      </g>
      <path d="M134.021 242.817C192.563 242.817 240.021 195.359 240.021 136.817C240.021 78.275 192.563 30.8172 134.021 30.8172C75.4783 30.8172 28.0205 78.275 28.0205 136.817C28.0205 195.359 75.4783 242.817 134.021 242.817Z" stroke="#B4142E" stroke-width="20" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M59.0752 61.8752L208.959 211.759" stroke="#B4142E" stroke-width="20" stroke-linecap="round" stroke-linejoin="round" />
      <defs>
        <clipPath id="clip0_3064_35693">
          <rect width="150" height="150" fill="white" transform="translate(61 62)" />
        </clipPath>
      </defs>

    </svg>
  )
}
