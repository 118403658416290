// Fetch.js
// const _apiHost = `${process.env.REACT_APP_SERVER_URL}api/1/`
const _apiHost = `${process.env.REACT_APP_SERVER_URL}api/1/${process.env.REACT_APP_CLIENT_ID}/`

async function request(url, params, method, headers) {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  }

  if (params) {
    if (method === 'GET') {
      url += '?' + objectToQueryString(params)
    } else {
      options.body = JSON.stringify(params)
    }
  }

  const response = await fetch(_apiHost + url, options)

  //   if (response.status !== 200) {
  //     return generateErrorResponse('The server responded with an unexpected status.')
  //   }

  // const result = await response.json()

  return response
}

function objectToQueryString(obj) {
  return Object.keys(obj)
    .map((key) => key + '=' + obj[key])
    .join('&')
}

// function generateErrorResponse(message) {
//   return {
//     status: 'error',
//     message,
//   }
// }

function get(url, params, headers) {
  return request(url, params, 'GET', headers)
}

function create(url, params, headers) {
  return request(url, params, 'POST', headers)
}

function update(url, params, headers) {
  return request(url, params, 'PUT', headers)
}

function remove(url, params, headers) {
  return request(url, params, 'DELETE', headers)
}

export { get, create, update, remove }
