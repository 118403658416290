import React from 'react'
import { CheckIcon } from '../../assets/Icons'

/**
 * @description Render Button
 * @param {*} children children of the component
 * @param {string} type button type {button | submit | reset}
 * @param {string} className css className
 * @param {string} varient button varient {secondary | secondary-outline | secondary-outline-dashed | secondary-checked | primary | primary-outline | dark-outline | tertiary-outline | custom  }
 * @param {*} * other html attributes
 * @returns Button Component
 */
export default function Button({
  children,
  type: btnType = 'button',
  className: buttonClass,
  varient,
  id,
  name,
  onClick,
  ...buttonProps
}) {
  return (
    <>
      {varient === 'background-image-dark' ? (
        <button
          {...buttonProps}
          className={`MTS__font-secondary MTS__font-semibold MTS__px-2 MTS__py-2 sm:MTS__px-4 sm:MTS__py-2 lg:MTS__px-8 lg:MTS__py-3 MTS__text-sm sm:MTS__text-base lg:MTS__text-xl MTS__text-white MTS__bg-dark MTS__bg-cover ${buttonClass}`}
          type={btnType}
          id={id}
          name={name}
          onClick={onClick}
        >
          {children}
        </button>
      ) : varient === 'background-image-light' ? (
        <button
          {...buttonProps}
          className={`MTS__font-secondary MTS__font-semibold MTS__px-2 MTS__py-2 sm:MTS__px-4 sm:MTS__py-2 lg:MTS__px-8 lg:MTS__py-3 MTS__text-sm sm:MTS__text-base lg:MTS__text-xl MTS__text-white MTS__bg-light MTS__bg-cover ${buttonClass}`}
          type={btnType}
          id={id}
          name={name}
          onClick={onClick}
        >
          {children}
        </button>
      ) : varient === 'secondary' ? (
        <button
          {...buttonProps}
          className={`MTS__font-secondary MTS__font-semibold MTS__px-2 MTS__py-2 sm:MTS__px-4 sm:MTS__py-2 lg:MTS__px-8 lg:MTS__py-3 MTS__text-sm sm:MTS__text-base lg:MTS__text-xl MTS__text-white MTS__bg-secondary MTS__outline-secondary ${buttonClass}`}
          type={btnType}
          id={id}
          name={name}
          onClick={onClick}
        >
          {children}
        </button>
      ) : varient === 'secondary-outline' ? (
        <button
          {...buttonProps}
          className={`MTS__font-secondary MTS__font-semibold MTS__px-2 MTS__py-2 sm:MTS__px-4 sm:MTS__py-2 lg:MTS__px-8 lg:MTS__py-3 MTS__text-sm sm:MTS__text-base lg:MTS__text-xl MTS__text-secondary MTS__border MTS__border-secondary MTS__outline-secondary ${buttonClass}`}
          type={btnType}
          id={id}
          name={name}
          onClick={onClick}
        >
          {children}
        </button>
      ) : varient === 'secondary-outline-dashed' ? (
        <button
          {...buttonProps}
          className={`MTS__font-secondary MTS__w-full MTS__px-2 MTS__py-2 sm:MTS__px-4 sm:MTS__py-2 lg:MTS__px-8 lg:MTS__py-3 MTS__text-sm sm:MTS__text-base lg:MTS__text-xl MTS__border-secondary MTS__border-dashed ${buttonClass}`}
          type={btnType}
          id={id}
          name={name}
          onClick={onClick}
        >
          {children}
        </button>
      ) : varient === 'secondary-checked' ? (
        <button
          {...buttonProps}
          className={`MTS__font-secondary MTS__font-semibold MTS__px-2 MTS__py-2 sm:MTS__px-4 sm:MTS__py-2 lg:MTS__px-8 lg:MTS__py-3 MTS__text-sm sm:MTS__text-base lg:MTS__text-xl MTS__text-secondary MTS__border MTS__border-secondary MTS__relative MTS__outline-secondary ${buttonClass}`}
          type={btnType}
          id={id}
          name={name}
          onClick={onClick}
        >
          <span className="MTS__flex MTS__w-5 MTS__h-5 lg:MTS__w-7 lg:MTS__h-7 MTS__place-items-center MTS__place-content-center MTS__absolute MTS__rounded-full MTS__-top-[11px] MTS__-right-[8px] lg:MTS__-top-[14px] lg:MTS__-right-[11px] MTS__bg-secondary">
            <span className="MTS__w-3 MTS__h-2">
              <CheckIcon className={`MTS__fill-white`}></CheckIcon>
            </span>
          </span>
          {children}
        </button>
      ) : varient === 'primary' ? (
        <button
          {...buttonProps}
          className={`MTS__font-secondary MTS__font-semibold MTS__px-2 MTS__py-2 sm:MTS__px-4 sm:MTS__py-2 lg:MTS__px-8 lg:MTS__py-3 MTS__text-sm sm:MTS__text-base lg:MTS__text-xl MTS__text-white MTS__bg-primary-200 MTS__outline-primary-200 ${buttonClass}`}
          type={btnType}
          id={id}
          name={name}
          onClick={onClick}
        >
          {children}
        </button>
      ) : varient === 'primary-outline' ? (
        <button
          {...buttonProps}
          className={`MTS__font-secondary MTS__font-semibold MTS__px-2 MTS__py-2 sm:MTS__px-4 sm:MTS__py-2 lg:MTS__px-8 lg:MTS__py-3 MTS__text-sm sm:MTS__text-base lg:MTS__text-xl MTS__text-primary-200 MTS__border MTS__border-primary-200 MTS__outline-primary-200 ${buttonClass}`}
          type={btnType}
          id={id}
          name={name}
          onClick={onClick}
        >
          {children}
        </button>
      ) : varient === 'primary-checked' ? (
        <button
          {...buttonProps}
          className={`MTS__font-secondary MTS__font-semibold MTS__px-2 MTS__py-2 sm:MTS__px-4 sm:MTS__py-2 lg:MTS__px-8 lg:MTS__py-3 MTS__text-sm sm:MTS__text-base lg:MTS__text-xl MTS__text-primary-200 MTS__border MTS__border-primary-200 MTS__relative MTS__outline-primary-200 ${buttonClass}`}
          type={btnType}
          id={id}
          name={name}
          onClick={onClick}
        >
          <span className="MTS__flex MTS__w-5 MTS__h-5 lg:MTS__w-7 lg:MTS__h-7 MTS__place-items-center MTS__place-content-center MTS__absolute MTS__rounded-full MTS__-top-[11px] MTS__-right-[8px] lg:MTS__-top-[14px] lg:MTS__-right-[11px] MTS__bg-primary-200">
            <span className="MTS__w-3 MTS__h-2">
              <CheckIcon className={`MTS__fill-white`}></CheckIcon>
            </span>
          </span>
          {children}
        </button>
      ) : varient === 'dark-outline' ? (
        <button
          {...buttonProps}
          className={`MTS__font-secondary MTS__px-2 MTS__py-2 sm:MTS__px-4 sm:MTS__py-2 lg:MTS__px-8 lg:MTS__py-3 MTS__text-sm sm:MTS__text-base lg:MTS__text-xl MTS__text-primary-100 MTS__border MTS__border-black MTS__outline-black ${buttonClass}`}
          onClick={onClick}
          type={btnType}
          id={id}
          name={name}
        >
          {children}
        </button>
      ) : varient === 'tertiary-outline' ? (
        <button
          {...buttonProps}
          className={`MTS__font-secondary MTS__px-2 MTS__py-2 sm:MTS__px-4 sm:MTS__py-2 lg:MTS__px-8 lg:MTS__py-3 MTS__text-sm sm:MTS__text-base lg:MTS__text-xl MTS__text-tertiary MTS__border MTS__border-tertiary MTS__outline-tertiary ${buttonClass}`}
          type={btnType}
          id={id}
          name={name}
          onClick={onClick}
        >
          {children}
        </button>
      ) : varient === 'disabled' ? (
        <button
          {...buttonProps}
          className={`MTS__font-secondary MTS__font-semibold MTS__px-2 MTS__py-2 sm:MTS__px-4 sm:MTS__py-2 lg:MTS__px-8 lg:MTS__py-3 MTS__text-sm sm:MTS__text-base lg:MTS__text-xl MTS__text-primary-100/[.65] MTS__bg-sandstone-40 MTS__outline-sandstone ${buttonClass}`}
          type={btnType}
          id={id}
          name={name}
          onClick={onClick}
        >
          {children}
        </button>
      ) : varient === 'custom' ? (
        <button
          {...buttonProps}
          className={`MTS__font-secondary MTS__font-semibold MTS__px-2 MTS__py-2 sm:MTS__px-4 sm:MTS__py-2 lg:MTS__px-8 lg:MTS__py-3 MTS__text-sm sm:MTS__text-base lg:MTS__text-xl MTS__text-white ${buttonClass}`}
          type={btnType}
          id={id}
          name={name}
          onClick={onClick}
        >
          {children}
        </button>
      ) : (
        <button {...buttonProps} className={buttonClass} type={btnType}>
          {children}
        </button>
      )}
    </>
  )
}
