import React from 'react'

/**
 * @description Render Loader
 * @returns Loader Component
 */
export default function Loader() {
  let circleCommonClasses = 'MTS__h-3 MTS__w-3 lg:MTS__w-5 lg:MTS__h-5 MTS__bg-primary-200 MTS__rounded-full'

  return (
    <div className="MTS__fixed MTS__inset-0 MTS__z-[9999999] MTS__flex MTS__bg-opacity-30 MTS__bg-primary-200 MTS__place-content-center MTS__place-items-center">
      <div className={`${circleCommonClasses} MTS__mr-1 MTS__animate-bounce`}></div>
      <div className={`${circleCommonClasses} MTS__mr-1 MTS__animate-bounce200`}></div>
      <div className={`${circleCommonClasses} MTS__animate-bounce400`}></div>
    </div>
  )
}
