import React from 'react'

/**
 *
 * @returns Check Icon
 */
export default function Check({ className }) {
  return (
    <svg viewBox="0 0 12 9" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.784 2.273L6.178 7.75875L5.126 8.79051C4.986 8.92756 4.792 9 4.6 9C4.408 9 4.21 8.92952 4.072 8.79051L3.02 7.75875L0.216 5.01588C0.0779996 4.87883 0 4.69088 0 4.50098C0 4.31107 0.0779996 4.12312 0.216 3.98608L1.27 2.95432C1.41 2.81727 1.604 2.74288 1.796 2.74288C1.988 2.74288 2.182 2.81923 2.322 2.95432L4.598 5.19012L9.678 0.211442C9.818 0.0763538 10.012 0 10.204 0C10.396 0 10.59 0.0763538 10.73 0.211442L11.782 1.24124C11.922 1.37829 12 1.56624 12 1.7581C12 1.94605 11.922 2.13596 11.784 2.273Z"
        // fill="white"
        fill="inherit"
      />
    </svg>
  )
}
