import React, { useEffect, useState, useRef } from 'react'
import Button from '../../../Buttons/Button'
import { Info2Icon } from '../../../../assets/Icons'
import { create } from '../../../../api/fetch'

/**
 * @description Render OTP inputs on popup
 * @param {Function} setPopup handler for popup state
 * @param {object} pageData language based values for content
 * @param {string} language current language
 * @param {number} numInputs number of inputs for otp
 * @param {Array} otp array to store otp
 * @param {number} setOtp handler for otp
 * @param {Function} setError handler to set error
 * @param {Function} setLoader handler to set loader
 * @param {Function} setManageTicket handler to set ticket id for cancellation
 * @param {Function} setTempManageTicket handler to set temporary ticket id for cancellation
 * @param {object} formBookingValues fields for manage booking {email, ticketId}
 * @returns
 */
export default function Otp({
  setPopup,
  pageData,
  language,
  numInputs = 6,
  otp,
  setOtp,
  setError,
  setLoader,
  formBookingValues,
  setManageTicket,
  setTempManageTicket,
}) {
  // * Translations
  const { buttons, notes, manageBooking } = pageData
  const { manageBookingErrors } = manageBooking

  // * For otp form
  const otpRef = useRef([])
  const otpArray = new Array(numInputs).fill('')
  const [formErrors, setFormErrors] = useState('')
  // const [isSubmit, setIsSubmit] = useState(false)

  /**
   * @description Call API to confirm OTP.
   * @param {{ otp, email, reservationId, language }}
   */
  const otpVerification = async (bodyParams) => {
    try {
      setLoader(true)
      const res = await create('reservation/otpVerification', bodyParams, {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      })

      const data = await res.json()
      setLoader(false)

      if (data.error) {
        if (res.status === 250) {
          const error = manageBookingErrors.otpVerification.validOtp[language]
          setError(error)
        } else if (res.status === 256) {
          const error = manageBookingErrors.otpVerification.otpUsed[language]
          setError(error)
        } else if (res.status === 257) {
          const error = manageBookingErrors.otpVerification.otpExpired[language]
          setError(error)
          setPopup(1)
        } else {
          const error = manageBookingErrors.otpVerification.otpVerification[language]
          setError(error)
        }
        return
      }

      if (data.data) {
        // const arrKeys = Object.keys(data?.data?.htmlEmails)
        // setManageEmailString(data?.data?.htmlEmails[arrKeys[0]])
        setManageTicket(data.data)
        setTempManageTicket(data.data)
        setPopup(3)
        return
      } else {
        const error = manageBookingErrors.otpVerification.noReservation[language]
        setError(error)
        setPopup(1)
      }
    } catch (e) {
      setLoader(false)
      const error = manageBookingErrors.otpVerification.otpVerification[language]
      setError(error) // setPopup(0)
    }
  }

  // * Form handling
  const handleChange = (event, index) => {
    // setIsSubmit(false)

    const { name, value } = event.target
    // setFormBookingValues({ ...formBookingValues, [name]: value })
    let fieldValue = /^\d{1,1}$/.test(value) ? (event.target.value = value) : (event.target.value = '')
    let otpFields = otp
    otpFields[index] = value
    setOtp(otpFields)
    // fnHandleChange(otp.join(''))
    if (fieldValue !== '') {
      event.preventDefault()
      let next = otpRef.current[name].nextSibling
      if (next && next.tagName === 'INPUT') {
        otpRef.current[name].nextSibling.focus()
      }
    }
  }

  const handleKeyDown = (event, index) => {
    /** 8 => BACKSPACE */
    if (event.keyCode === 8 && event.target.value.length === 0) {
      event.preventDefault()
      let previous = otpRef.current[event.target.name].previousSibling
      if (previous && previous.tagName === 'INPUT') {
        otpRef.current[event.target.name].previousSibling.focus()
      }
    }
    setFormErrors('')
    otpRef.current[event.target.name].style.borderColor = 'var(--color-tertiary)'
  }

  const handleSubmit = (event, index) => {
    event.preventDefault()
    // setFormErrors(validateFormValues(formBookingValues))
    if (otp.join('').trim().length < 6) {
      setFormErrors('Plese Enter OTP.')
      // setIsSubmit(false)
      if (otpRef.current) {
        otpRef.current.forEach((ref, index) => {
          if (ref.value.length === 0) {
            ref.style.borderColor = 'var(--color-danger)'
          } else {
            ref.style.borderColor = 'var(--color-tertiary)'
          }
        })
      }
    } else {
      // setIsSubmit(true)
      otpVerification({
        otp: otp.join(''),
        email: formBookingValues.email,
        reservationId: formBookingValues.reservationId,
        language,
      })
    }
  }

  // * Focus first otp textbox on render
  useEffect(() => {
    setOtp(otpArray)
    if (otpRef.current) {
      otpRef.current[0].focus()
    }
  }, [])

  // useEffect(() => {
  //   if (isSubmit && Object.keys(formErrors).length <= 0 && otp.length === 6) {
  //     // setPopup(3)
  //     otpVerification({
  //       otp: otp.join(''),
  //       email: formBookingValues.email,
  //       reservationId: formBookingValues.reservationId,
  //       language,
  //     })
  //   }
  // }, [isSubmit, formErrors, otp])

  return (
    <div className="MTS__flex MTS__flex-col MTS__gap-4 lg:MTS__gap-8">
      <Button
        className="MTS__text-base MTS__bg-opacity-50 MTS__border-2 MTS__bg-secondary lg:MTS__text-xl"
        varient="secondary-outline-dashed"
      >
        <div className="MTS__grid MTS__justify-start MTS__grid-flow-col MTS__gap-2">
          <div className="MTS__w-4 MTS__h-4 lg:MTS__w-6 lg:MTS__h-6">
            <Info2Icon circleClassName="MTS__fill-secondary" pathClassName="MTS__fill-white"></Info2Icon>
          </div>
          <div className="MTS__leading-4 MTS__text-left lg:MTS__leading-7 MTS__tracking-1">
            {notes?.spamInfo[language]}
          </div>
        </div>
      </Button>

      <form
        className="MTS__flex MTS__flex-col MTS__gap-4 MTS__mx-auto MTS__font-secondary lg:MTS__w-[90%]"
        onSubmit={handleSubmit}
      >
        <div className="MTS__flex MTS__flex-wrap MTS__justify-center MTS__gap-2">
          {otpArray.map((otp, index) => {
            return (
              <input
                className={`MTS__flex MTS__w-8 MTS__h-8 MTS__text-base MTS__text-center MTS__border MTS__outline-none sm:MTS__h-12 sm:MTS__w-12 lg:MTS__w-16 lg:MTS__h-16 sm:MTS__text-xl lg:MTS__text-2xl MTS__border-tertiary MTS__bg-whitesmoke`}
                type="text"
                key={index}
                name={index}
                maxLength="1"
                value={otp[index]}
                ref={(el) => (otpRef.current[index] = el)}
                onKeyDown={(event) => handleKeyDown(event, index)}
                onChange={(event) => handleChange(event, index)}
              />
            )
          })}
        </div>
        <div className={`MTS__formInputError MTS__text-danger MTS__text-center`}>{formErrors}</div>
        <Button varient="secondary-outline" type="submit" className="MTS__self-center">
          {buttons?.verify[language]}
        </Button>
      </form>
    </div>
  )
}
