// *********** VALIDATE FORM VALUES :: return error object ***********
const validateFormValues = (values, validations, language) => {
  // const plateRegex = /^[\w\d]+$/i
  // const plateRegex = /^[\p{L}\p{M}\d\s]+$/giu

  if (Array.isArray(values)) {
    const errors = []
    for (let i = 0; i < values.length; i++) {
      const plateRegex = /^[\p{L}\p{M}\d\s]+$/giu
      // errors.push({
      //   vehicleType: '',
      //   ticketType: '',
      //   arrival: '',
      //   plate: '',
      // })
      errors.push({})

      const { vehicleType, ticketType, arrival, plate, rentalCar } = values[i]
      if (!vehicleType) {
        errors[i].vehicleType = validations?.vehicleType[language] || ''
      }

      if (!ticketType) {
        errors[i].ticketType = validations?.ticketType[language] || ''
      }

      if (!rentalCar) {
        if (!plate.trim()) {
          errors[i].plate = validations?.plate[language] || ''
        } else if (plate && plate.trim().length < 4) {
          errors[i].plate = validations?.validPlate[language] || ''
        } else if (plate && plate.trim().length > 10) {
          errors[i].plate = validations?.validPlate[language] || ''
        } else if (plate && !plateRegex.test(plate)) {
          errors[i].plate = validations?.validPlate[language] || ''
        }
      } else {
        if (plate && plate.trim().length > 10) {
          errors[i].plate = validations?.validPlate[language] || ''
        } else if (plate && !plateRegex.test(plate)) {
          errors[i].plate = validations?.validPlate[language] || ''
        }
      }

      if (!arrival) {
        errors[i].arrival = validations?.arrival[language] || ''
      }
    }
    return errors
  } else {
    // form manage parking
    const plateRegex = /^[\p{L}\p{M}\d\s]+$/giu
    const errors = {}

    const { vehicleType, ticketType, arrival, plate } = values
    if (!vehicleType) {
      errors.vehicleType = validations?.vehicleType[language] || ''
    }

    if (!ticketType) {
      errors.ticketType = validations?.ticketType[language] || ''
    }

    if (!plate.trim()) {
      errors.plate = validations?.plate[language] || ''
    } else if (plate && plate.trim().length < 4) {
      errors.plate = validations?.validPlate[language] || ''
    } else if (plate && plate.trim().length > 10) {
      errors.plate = validations?.validPlate[language] || ''
    } else if (plate && !plateRegex.test(plate)) {
      errors.plate = validations?.validPlate[language] || ''
    }

    if (!arrival) {
      errors.arrival = validations?.arrival[language] || ''
    }
    return errors
  }
}

export { validateFormValues }
