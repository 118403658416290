// *********** VALIDATE FORM VALUES :: return error object ***********
const validateFormValues = (values, validations, language) => {
  const { name, surname, country, email, phone, tnc } = values
  const errors = {}
  const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/
  const phoneRegex = /^[0-9+ ]{7,18}$/

  if (!name.trim()) {
    errors.name = validations?.name[language]
  } else if (name.trim().length > 20) {
    errors.name = validations?.nameLength[language]
  }

  if (!surname.trim()) {
    errors.surname = validations?.surname[language]
  } else if (surname.trim().length > 20) {
    errors.surname = validations?.surnameLength[language]
  }

  if (!country) {
    errors.country = validations?.country[language]
  }

  if (!email.trim()) {
    errors.email = validations?.email[language]
  } else if (!emailRegex.test(email)) {
    errors.email = validations?.validEmail[language]
  }

  if (!phone.trim()) {
    errors.phone = validations?.phone[language]
  } else if (!phoneRegex.test(phone)) {
    errors.phone = validations?.validPhone[language]
  }

  if (tnc === false) {
    errors.tnc = validations?.tnc[language]
  }

  // if (commercial === false) {
  //   errors.commercial = `Please select whether it is commercial or not.`
  // }
  return errors
}

export { validateFormValues }
