import React, { useState } from 'react'
import FormInput from '../../../Fields/FormInput'
import FormCountry from '../../../Fields/FormCountry'
import FormSelect from '../../../Fields/FormSelect'
import Button from '../../../../Buttons/Button'
import { validateFormValues } from '../../../../../lib/parkingValidation'
import { create, get } from '../../../../../api/fetch'
import { customDate, formatDate, formatPrice } from '../../../../../lib/reusableFunctions'

/**
 * @description Render buyer information and form from multi step form
 * @param {*} setCheckoutId
 * @param {*} nextStep
 * @param {*} prevStep
 * @param {*} setError
 * @param {*} setLoader
 * @param {*} pageData
 * @param {*} language
 * @param {*} formValues
 * @param {*} setFormValues
 * @param {*} tickets
 * @returns
 */
export default function BuyerInformation({
  setCheckoutId,
  nextStep,
  prevStep,
  setError,
  setLoader,
  pageData,
  language,
  formValues,
  setFormValues,
  tickets,
  updateTemplateString,
  setSavedTickets,
  setActiveTabTickets
}) {
  // * Translations
  const {
    notes,
    buyerInformation,
    buttons,
    pdfTemplate: {
      lblBookingConfirmation,
      lblBookingConfirmationSpecialTicket,
      lblTicketCode,
      lblTicketMessage,
      lblTicketDetails,
      lblArrival,
      lblDeparture,
      lblVehiclePlate,
      lblPrice,
      lblVehicleType,
      lblTicketType,
      lblReservationID,
      lblParkingPrice,
      lblServicePrice,
      lblParkingRule,
    },
    ticketsInformation: {
      ticketErrors,
      form: {
        labels: { lblNoCalcellationText },
      },
    },
  } = pageData
  const { buyerErrors } = buyerInformation
  const { validations, labels, placeholders, options } = buyerInformation.form
  const { lblName, lblSurname, lblCountry, lblEmail, lblPhone, lblPurpose, lblCommercial, lblTnc } = labels
  const { plhName, plhSurname, plhEmail, plhPhone } = placeholders
  const { optCountry, optPurpose } = options

  // * For Buyer form
  const PURPOSE = [
    {
      value: {
        de: 'Solo-Trip',
        it: 'Viaggio da solista',
        en: 'Solo Trip',
      },
      label: {
        de: 'Solo-Trip',
        it: 'Viaggio da solista',
        en: 'Solo Trip',
      },
    },
    {
      value: {
        de: 'Familienausflug',
        it: 'Viaggio in famiglia',
        en: 'Family Trip',
      },
      label: {
        de: 'Familienausflug',
        it: 'Viaggio in famiglia',
        en: 'Family Trip',
      },
    },
    {
      value: {
        de: 'Geschäftsreise',
        it: 'Viaggio di lavoro',
        en: 'Business Trip',
      },
      label: {
        de: 'Geschäftsreise',
        it: 'Viaggio di lavoro',
        en: 'Business Trip',
      },
    },
    {
      value: {
        de: 'Geschäftstreffen',
        it: "Incontro d'affari",
        en: 'Business Meeting',
      },
      label: {
        de: 'Geschäftstreffen',
        it: "Incontro d'affari",
        en: 'Business Meeting',
      },
    },
  ]
  const inputs = [
    {
      id: 1,
      name: 'name',
      type: 'text',
      label: lblName[language],
      placeholder: plhName[language],
      required: true,
    },
    {
      id: 2,
      name: 'surname',
      type: 'text',
      label: lblSurname[language],
      placeholder: plhSurname[language],
      required: true,
    },
    {
      id: 3,
      name: 'country',
      type: 'select',
      label: lblCountry[language],
      defaultOption: optCountry[language],
      required: true,
    },
    {
      id: 4,
      name: 'purpose',
      type: 'select',
      language,
      label: lblPurpose[language],
      // required: true,
      optionsArray: PURPOSE.map((item, index) => {
        return {
          value: item.value[language],
          label: item.label,
        }
      }),
      defaultOption: optPurpose[language],
    },
    {
      id: 5,
      name: 'email',
      type: 'text',
      label: lblEmail[language],
      placeholder: plhEmail[language],
      // pattern: '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$',
      required: true,
    },
    {
      id: 6,
      name: 'phone',
      type: 'text',
      label: lblPhone[language],
      placeholder: plhPhone[language],
      required: true,
    },
  ]
  const [formErrors, setFormErrors] = useState({})
  // const [isSubmit, setIsSubmit] = useState(false)

  const handleChangeInput = (e) => {
    const { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      setFormValues({ ...formValues, [name]: checked })
    } else {
      setFormValues({ ...formValues, [name]: value })
    }
  }

  /**
   * @description Call API to add reservation and to get checkout ID.
   * @param {*} reservationDetails
   */
  const getCheckoutId = async (bodyParams) => {
    setLoader(true)
    try {
      const res = await create('reservation/add', bodyParams, {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      })
      const data = await res.json()
      setLoader(false)

      if (data?.error) {
        // const error = typeof data?.error === 'string' ? data?.error : `There may be issues with adding reservation.`
        // setError(error)
        if (res.status === 250) {
          const { unavailableVehicleSlot } = data.error
          let error = ``
          unavailableVehicleSlot.map((slot) => {
            error +=
              buyerErrors.getCheckoutId.noAvailability[language]
                .replace(`{{vehicleType}}`, slot.vehicleType)
                .replace(`{{arrival}}`, formatDate(new Date(slot.arrival)), language) + `\n`
          })
          setError(error)
        } else if (res.status === 251) {
          const error = buyerErrors.getCheckoutId.errHobexCheckout[language]
          setError(error)
        } else {
          const error = buyerErrors.getCheckoutId.errReservation[language]
          setError(error)
        }
        return
      }

      if (data?.data && data?.data?.ndc) {
        setCheckoutId(data.data.ndc)
        nextStep()
      } else {
        const error = buyerErrors.getCheckoutId.noCheckoutId[language]
        setError(error)
        return
      }
    } catch (e) {
      setLoader(false)
      setError(buyerErrors.getCheckoutId.errReservation[language])
    }
  }

  // * Form handling
  const handleChange = (name, value) => {
    setFormValues({ ...formValues, [name]: value })
  }

  function getStringDate(dateObj) {
    return dateObj.getFullYear() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getDate()
  }

  const handleBlur = (e) => {
    const { name } = e.target
    const errors = validateFormValues(formValues, validations, language)
    setFormErrors({ ...formErrors, [name]: errors[name] })
  }

  const checkSlotAvailability = async (newTickets) => {
    setLoader(true);
    try {
      let arrivalDates = [];
      let ticketLists = []
      newTickets.forEach((i, idx) => {
        // let arrival = i.arrival.split("T")[0]
        // if (!arrivalDates.includes(arrival)) {
        //   arrivalDates.push(arrival)
        ticketLists.push({ ...i, formId: `form-${idx + 1}`, idx })
        // }
      })

      let isAvailable = true;
      for (const ticket of ticketLists) {
        const { vehicleType, arrival, parkId } = ticket
        if (isAvailable) {
          const res = await get(
            `reservation/checkslotavailability`,
            {
              vehicleType,
              parkId,
              arrival: customDate(new Date(arrival), 'mm-dd-yyyy'),
            },
            {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
          )
          const data = await res.json()
          setLoader(false)
          if (data?.error) {
            const error = ticketErrors.checkSlotAvailability.noSlotsAtSecondStep[language].replace(
              '{{arrival}}',
              formatDate(new Date(arrival), language),
            )
            setError(error)
            setSavedTickets((prev) => ({ ...prev, [`form-${ticket.idx + 1}`]: false }))
            setActiveTabTickets(ticket.idx)
            isAvailable = false
          }
          if (data?.data?.available && data?.data?.available > 0) {
            setSavedTickets((prev) => ({ ...prev, [`form-${ticket.idx + 1}`]: true }))
          } else {
            setSavedTickets((prev) => ({ ...prev, [`form-${ticket.idx + 1}`]: false }))
            setActiveTabTickets(ticket.idx)
            isAvailable = false
            const error = ticketErrors.checkSlotAvailability.noSlotsAtSecondStep[language].replace(
              '{{arrival}}',
              formatDate(new Date(arrival), language),
            )
            setError(error)
          }
        }
      }
      return isAvailable

    } catch (e) {
      setLoader(false)
      console.log({ e })
      setError("Error while verifiying slots availability.")
      return false;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const errors = validateFormValues(formValues, validations, language)
    setFormErrors(errors)
    if (Object.keys(errors).length <= 0) {
      const newTickets = JSON.parse(JSON.stringify(tickets))
      let slotsAvailable = await checkSlotAvailability(newTickets)
      if (slotsAvailable) {
        getCheckoutId({
          tickets: newTickets.map((ticket, index) => {
            // formattedArrival = formatDate(new Date(Date.parse(ticket.arrival)), language)
            // formattedDeparture =  formatDate(new Date(Date.parse(ticket.departure)), language)
            const formattedArrival = formatDate(new Date(ticket.arrival), language)
            const formattedDeparture = formatDate(new Date(ticket.departure), language)

            //
            let isCancellation = true
            let UpdatedRuleTxt = lblParkingRule[language]
            let months = {
              de: [
                'Januar',
                'Februar',
                'März',
                'April',
                'Mai',
                'Juni',
                'Juli',
                'August',
                'September',
                'Oktober',
                'November',
                'Dezember',
              ],
              it: [
                'Gennaio',
                'Febbraio',
                'Marzo',
                'Aprile',
                'Maggio',
                'Giugno',
                'Luglio',
                'Agosto',
                'Settembre',
                'Ottobre',
                'Novembre',
                'Dicembre',
              ],
              en: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ],
            }
            let a_egDate = '10'
            let a_egmonth = months[language][7]
            let a_egYear = '2024'
            let c_egDate = '06'
            let c_egmonth = months[language][7]
            let c_egYear = '2024'

            if (ticket?.arrival) {
              let todayDate = getStringDate(new Date())
              let selectedArrival = typeof ticket?.arrival === 'string' ? new Date(ticket?.arrival) : ticket?.arrival

              let allowedDate = new Date(selectedArrival)
              allowedDate.setDate(selectedArrival.getDate() - Math.abs(4))

              if (
                (getStringDate(selectedArrival) === todayDate || allowedDate < new Date()) &&
                getStringDate(allowedDate) !== todayDate
              ) {
                // Cancellation not possible
                isCancellation = false
                let contentToReplace = {
                  en: '<p>Example of cancellation and full refund:</p><p>planned arrival on {{a_date}} {{a_month}} {{a_year}}</p><p>cancellation by midnight on {{c_date}} {{c_month}} {{c_year}}</p>',
                  it: '<p>Esempio per la cancellazione e il rimborso totale del ticket:</p><p>arrivo previsto giorno {{a_date}} del mese di {{a_month}} {{a_year}}</p><p>cancellazione entro le ore 24.00 del giorno {{c_date}} {{c_month}} {{c_year}}</p>',
                  de: '<p>Beispiel für Stornierung und volle Rückerstattung:</p><p>Geplante Ankunft am {{a_date}}. {{a_month}} {{a_year}}</p><p>Stornierung bis Mitternacht am {{c_date}}. {{c_month}} {{c_year}}</p>',
                }
                UpdatedRuleTxt = UpdatedRuleTxt.replace(
                  [contentToReplace[language]],
                  `<b>${lblNoCalcellationText[language]}</b>`,
                )
              } else {
                // Cancellation Possible
                let aDate = selectedArrival.getDate()
                a_egDate = (aDate.toString().length === 1 ? '0' : '') + aDate
                a_egmonth = months[language][selectedArrival.getMonth()]
                a_egYear = selectedArrival.getFullYear()

                let cDate = allowedDate.getDate()
                c_egDate = (cDate.toString().length === 1 ? '0' : '') + cDate
                c_egmonth = months[language][allowedDate.getMonth()]
                c_egYear = allowedDate.getFullYear()

                let exampleLine = {
                  de: '<p>Beispiel für Stornierung und volle Rückerstattung:</p>',
                  it: '<p>Esempio per la cancellazione e il rimborso totale del Ticket:</p>',
                  en: '<p>Example of cancellation and full refund:</p>',
                }
                UpdatedRuleTxt = UpdatedRuleTxt.replace(exampleLine[language], ' ')
              }
            }
            if (isCancellation) {
              UpdatedRuleTxt = updateTemplateString(UpdatedRuleTxt, {
                a_date: a_egDate,
                a_month: a_egmonth,
                a_year: a_egYear,
                c_date: c_egDate,
                c_month: c_egmonth,
                c_year: c_egYear,
              })
            }
            //

            ticket.template = updateTemplateString(ticket.template, {
              lblBookingConfirmation:
                ticket.ticketType === 'special'
                  ? lblBookingConfirmationSpecialTicket[language]
                  : lblBookingConfirmation[language],
              lblTicketCode: lblTicketCode[language],
              lblTicketMessage: lblTicketMessage[language],
              lblTicketDetails: lblTicketDetails[language],
              lblArrival: lblArrival[language],
              lblDeparture: lblDeparture[language],
              lblVehiclePlate: lblVehiclePlate[language],
              lblVehicleType: lblVehicleType[language],
              lblTicketType: lblTicketType[language],
              lblReservationId: lblReservationID[language],
              lblRefundNote: notes?.refundInfo[language],
              lblPrice: lblPrice[language],
              lblParkingPrice: lblParkingPrice[language],
              lblServicePrice: lblServicePrice[language],
              lblParkingRule: UpdatedRuleTxt,
              plate: ticket.plate?.replace(/\s/g, '') || 'N/A',
              price: formatPrice((ticket.price || 0), language, 2, 2),
              servicePrice: formatPrice(ticket.servicePrice || 0, language, 2, 2),
              arrival: formattedArrival,
              departure: formattedDeparture,
              vehicleType: ticket.vehicleType,
              ticketType: ticket.ticketType,
            })
            ticket.arrival = customDate(new Date(ticket.arrival), 'mm-dd-yyyy')
            ticket.departure = customDate(new Date(ticket.departure), 'mm-dd-yyyy')
            ticket.amount = ticket.price
            ticket.serviceAmount = ticket.servicePrice
            ticket.plate = ticket.plate?.replace(/\s/g, '')

            return ticket
          }),
          customer: {
            name: formValues.name.length > 0 ? formValues.name.trim() : formValues.name,
            surname: formValues.surname.length > 0 ? formValues.surname.trim() : formValues.surname,
            email: formValues.email.length > 0 ? formValues.email.trim() : formValues.email,
            phone: formValues.phone.length > 0 ? formValues.phone.trim() : formValues.phone,
          },
          paymentMode: process.env.REACT_APP_PAYMENT_MODE,
          totalAmount: newTickets.reduce((a, b) => a + b.price + b.servicePrice, 0),
          language,
        })
      } else {
        prevStep()
      }
    }
    // setIsSubmit(true)
  }

  // * Generate checkout ID for hobex payment form
  // useEffect(() => {
  //   if (isSubmit && Object.keys(formErrors).length <= 0) {
  //     const newTickets = JSON.parse(JSON.stringify(tickets))
  //     getCheckoutId({
  //       tickets: newTickets.map((ticket, index) => {
  //         // ticket.arrival = 1682620200000
  //         // ticket.departure = 1682620200000
  //         ticket.amount = ticket.price
  //         ticket.serviceAmount = ticket.servicePrice

  //         ticket.template = updateTemplateString(ticket.template, {
  //           lblBookingConfirmation: lblBookingConfirmation[language],
  //           lblTicketCode: lblTicketCode[language],
  //           lblTicketMessage: lblTicketMessage[language],
  //           lblTicketDetails: lblTicketDetails[language],
  //           lblArrival: lblArrival[language],
  //           lblDeparture: lblDeparture[language],
  //           lblVehiclePlate: lblVehiclePlate[language],
  //           lblVehicleType: lblVehicleType[language],
  //           lblTicketType: lblTicketType[language],
  //           lblReservationId: lblReservationID[language],
  //           lblPrice: lblPrice[language],
  //           plate: ticket.plate || 'N/A',
  //           price: ticket.amount.toLocaleString(`${language}-${language.toUpperCase()}`, {
  //             style: 'currency',
  //             currency: 'EUR',
  //           }),

  //           arrival: customDate(new Date(ticket.arrival)),
  //           departure: customDate(new Date(ticket.departure)),
  //           vehicleType: ticket.vehicleType,
  //           ticketType: ticket.ticketType,
  //         })
  //         return ticket
  //       }),
  //       customer: {
  //         name: formValues.name,
  //         surname: formValues.surname,
  //         email: formValues.email,
  //         phone: formValues.phone,
  //       },
  //       paymentMode: 'test',
  //       totalAmount: newTickets.reduce((a, b) => a + b.price, 0),
  //     })
  //   }
  // }, [isSubmit, formErrors])

  return (
    <div>
      <div className="MTS__flex MTS__flex-col">
        <form onSubmit={handleSubmit}>
          {/* <div className="grid mx-auto font-secondary bg-whitesmoke sm:w-[60%] lg:w-[90%] xl:w-[50%] p-8 sm:p-12 lg:p-16"> */}
          <div className="MTS__grid MTS__p-4 MTS__mx-auto MTS__font-secondary MTS__bg-whitesmoke sm:MTS__p-8 lg:MTS__p-12">
            <div className="MTS__grid MTS__grid-cols-1 MTS__gap-2 lg:MTS__gap-6 lg:MTS__grid-cols-2">
              {inputs.map((input) => (
                <div key={input.id}>
                  {input.name === 'country' ? (
                    <FormCountry
                      {...input}
                      value={formValues[input.name]}
                      onChange={(value) => handleChange(input.name, value)}
                      errorMessage={formErrors[input.name]}
                      onBlur={handleBlur}
                      onKeyUp={handleBlur}
                      formErrors={formErrors}
                      setFormErrors={setFormErrors}
                      formValues={formValues}
                      validations={validations}
                      language={language}
                      lblClassName="MTS__uppercase"
                    ></FormCountry>
                  ) : input.type === 'select' && input.name !== 'country' ? (
                    <FormSelect
                      {...input}
                      ticket={formValues}
                      value={formValues[input.name]}
                      onChange={(value) => handleChange(input.name, value)}
                      errorMessage={formErrors[input.name]}
                      onBlur={handleBlur}
                      onKeyUp={handleBlur}
                      optionsArray={input.optionsArray}
                      defaultOption={input.defaultOption}
                      lblClassName="MTS__uppercase"
                    ></FormSelect>
                  ) : (
                    <FormInput
                      {...input}
                      value={formValues[input.name]}
                      onChange={handleChangeInput}
                      errorMessage={formErrors[input.name]}
                      onBlur={handleBlur}
                      onKeyUp={handleBlur}
                      lblClassName="MTS__uppercase"
                    ></FormInput>
                  )}
                </div>
              ))}
            </div>
            <div className="MTS__flex MTS__flex-col MTS__gap-2 MTS__mt-6 MTS__text-sm">
              <div className="MTS__flex MTS__items-center MTS__gap-2">
                <input
                  className={`MTS__bg-whitesmoke MTS__inline-block MTS__border MTS__border-tertiary ${formErrors?.commercial === false && 'MTS__border-danger'
                    }`}
                  type="checkbox"
                  name="commercial"
                  id="commercial"
                  value={formValues.commercial}
                  // checked={formValues.commercial}
                  onChange={handleChangeInput}
                // onBlur={handleBlur}
                />
                <label
                  className="MTS__inline MTS__text-xs sm:text-sm MTS__font-light MTS__text-primary-100 MTS__leading-5"
                  htmlFor="commercial"
                >
                  {lblCommercial[language]}
                </label>
                <span className={`MTS__text-danger`}>{formErrors['commercial']}</span>
              </div>

              <div className="MTS__flex MTS__flex-col MTS__gap-2 MTS__text-sm">
                <div className="MTS__flex MTS__gap-2 MTS__place-items-center">
                  <input
                    className={`MTS__bg-whitesmoke MTS__text-primary-100 MTS__inline-block MTS__border MTS__border-tertiary ${formErrors?.tnc === false && 'MTS__border-danger'
                      }`}
                    type="checkbox"
                    name="tnc"
                    id="tnc"
                    value={formValues.tnc}
                    onChange={handleChangeInput}
                  // onBlur={handleBlur}
                  />
                  <label
                    className="MTS__inline MTS__mt-0 MTS__text-xs sm:text-sm MTS__font-light MTS__text-primary-100 MTS__leading-5"
                    htmlFor="tnc"
                  >
                    {/* <a
                      className="MTS__outline-none MTS__text-xs sm:text-sm MTS__text-primary-100"
                      href={
                        language === 'de'
                          ? 'https://pragsparking.mts-online.com/de/agb-p5.html'
                          : language === 'it'
                          ? 'https://pragsparking.mts-online.com/it/t-c-p65.html'
                          : language === 'en'
                          ? 'https://pragsparking.mts-online.com/en/t-c-p35.html'
                          : 'https://pragsparking.mts-online.com/en/t-c-p35.html'
                      }
                      target="_blank"
                      rel="noreferrer"
                    > */}
                    {lblTnc[language]}
                    {/* </a> */}
                  </label>
                </div>
                <div>
                  <span className={`MTS__text-danger`}>{formErrors['tnc']}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="MTS__grid MTS__w-full MTS__grid-cols-1 MTS__mt-4">
            <div className="MTS__flex MTS__flex-wrap">
              <Button varient="dark-outline" type="button" onClick={prevStep}>
                {buttons?.previous[language]}
              </Button>
              <Button className="MTS__ml-auto" varient="secondary-outline" type="submit">
                {buttons?.continue[language]}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
