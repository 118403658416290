import React from 'react'

/**
 *
 * @returns Trash Icon
 */
export default function Trash({className}) {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M12.5 17.4375H3.5C3.05245 17.4375 2.62322 17.2597 2.30676 16.9432C1.99029 16.6268 1.8125 16.1976 1.8125 15.75V5.0625C1.8125 4.91332 1.87176 4.77024 1.97725 4.66475C2.08274 4.55926 2.22582 4.5 2.375 4.5C2.52418 4.5 2.66726 4.55926 2.77275 4.66475C2.87824 4.77024 2.9375 4.91332 2.9375 5.0625V15.75C2.9375 15.8992 2.99676 16.0423 3.10225 16.1477C3.20774 16.2532 3.35082 16.3125 3.5 16.3125H12.5C12.6492 16.3125 12.7923 16.2532 12.8977 16.1477C13.0032 16.0423 13.0625 15.8992 13.0625 15.75V5.0625C13.0625 4.91332 13.1218 4.77024 13.2273 4.66475C13.3327 4.55926 13.4758 4.5 13.625 4.5C13.7742 4.5 13.9173 4.55926 14.0227 4.66475C14.1282 4.77024 14.1875 4.91332 14.1875 5.0625V15.75C14.1875 16.1976 14.0097 16.6268 13.6932 16.9432C13.3768 17.2597 12.9476 17.4375 12.5 17.4375Z"
        fill="inherit"
      />
      <path
        d="M14.75 3.9375H1.25C1.10082 3.9375 0.957742 3.87824 0.852252 3.77275C0.746763 3.66726 0.6875 3.52418 0.6875 3.375C0.6875 3.22582 0.746763 3.08274 0.852252 2.97725C0.957742 2.87176 1.10082 2.8125 1.25 2.8125H14.75C14.8992 2.8125 15.0423 2.87176 15.1477 2.97725C15.2532 3.08274 15.3125 3.22582 15.3125 3.375C15.3125 3.52418 15.2532 3.66726 15.1477 3.77275C15.0423 3.87824 14.8992 3.9375 14.75 3.9375Z"
        fill="inherit"
      />
      <path
        d="M10.25 3.9375C10.1008 3.9375 9.95774 3.87824 9.85225 3.77275C9.74676 3.66726 9.6875 3.52418 9.6875 3.375V1.6875H6.3125V3.375C6.3125 3.52418 6.25324 3.66726 6.14775 3.77275C6.04226 3.87824 5.89918 3.9375 5.75 3.9375C5.60082 3.9375 5.45774 3.87824 5.35225 3.77275C5.24676 3.66726 5.1875 3.52418 5.1875 3.375V1.125C5.1875 0.975816 5.24676 0.832742 5.35225 0.727252C5.45774 0.621763 5.60082 0.5625 5.75 0.5625H10.25C10.3992 0.5625 10.5423 0.621763 10.6477 0.727252C10.7532 0.832742 10.8125 0.975816 10.8125 1.125V3.375C10.8125 3.52418 10.7532 3.66726 10.6477 3.77275C10.5423 3.87824 10.3992 3.9375 10.25 3.9375Z"
        fill="inherit"
      />
      <path
        d="M8 14.625C7.85082 14.625 7.70774 14.5657 7.60225 14.4602C7.49676 14.3548 7.4375 14.2117 7.4375 14.0625V6.1875C7.4375 6.03832 7.49676 5.89524 7.60225 5.78975C7.70774 5.68426 7.85082 5.625 8 5.625C8.14918 5.625 8.29226 5.68426 8.39775 5.78975C8.50324 5.89524 8.5625 6.03832 8.5625 6.1875V14.0625C8.5625 14.2117 8.50324 14.3548 8.39775 14.4602C8.29226 14.5657 8.14918 14.625 8 14.625Z"
        fill="inherit"
      />
      <path
        d="M10.8125 13.5C10.6633 13.5 10.5202 13.4407 10.4148 13.3352C10.3093 13.2298 10.25 13.0867 10.25 12.9375V7.3125C10.25 7.16332 10.3093 7.02024 10.4148 6.91475C10.5202 6.80926 10.6633 6.75 10.8125 6.75C10.9617 6.75 11.1048 6.80926 11.2102 6.91475C11.3157 7.02024 11.375 7.16332 11.375 7.3125V12.9375C11.375 13.0867 11.3157 13.2298 11.2102 13.3352C11.1048 13.4407 10.9617 13.5 10.8125 13.5Z"
        fill="inherit"
      />
      <path
        d="M5.1875 13.5C5.03832 13.5 4.89524 13.4407 4.78975 13.3352C4.68426 13.2298 4.625 13.0867 4.625 12.9375V7.3125C4.625 7.16332 4.68426 7.02024 4.78975 6.91475C4.89524 6.80926 5.03832 6.75 5.1875 6.75C5.33668 6.75 5.47976 6.80926 5.58525 6.91475C5.69074 7.02024 5.75 7.16332 5.75 7.3125V12.9375C5.75 13.0867 5.69074 13.2298 5.58525 13.3352C5.47976 13.4407 5.33668 13.5 5.1875 13.5Z"
        fill="inherit"
      />
    </svg>
  )
}
