import React from 'react'

/**
 *
 * @returns DashedLine Icon
 */
export default function DashedLine({ className }) {
  return (
    <svg width="100%" height="1" viewBox="0 0 1024 1" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
      <line
        x1="4.37114e-08"
        y1="0.5"
        x2="100%"
        y2="0.500082"
        stroke="inherit"
        strokeOpacity="0.2"
        strokeDasharray="10 10"
      />
    </svg>
  )
}
