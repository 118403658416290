import React, { Fragment, useEffect, useState } from 'react'
import Title from '../../../Titles/Title'
import Button from '../../../Buttons/Button'
import FormInput from '../../../Forms/Fields/FormInput'
import { validateFormValues } from '../../../../lib/feedbackValidations'
import { Info2Icon } from '../../../../assets/Icons'
import { create } from '../../../../api/fetch'

export default function Feedback({
  pageData,
  language,
  setError,
  setLoader,
  setSuccessInfo,
  isFeedbackSuccess,
  setIsFeedbackSuccess,
}) {
  const { feedback, buttons, errors, notes } = pageData
  const { feedbackErrors, feedbackSuccess } = feedback
  const { validations, labels, placeholders } = feedback?.form
  const { lblName, lblMessage, lblEmail } = labels
  const { plhName, plhMessage, plhEmail } = placeholders

  const initialFormFeeback = {
    name: '',
    email: '',
    message: '',
  }
  const [formFeedbackValues, setFormFeedbackValues] = useState(initialFormFeeback)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)

  const inputs = [
    {
      id: 1,
      name: 'name',
      type: 'text',
      label: lblName[language],
      placeholder: plhName[language],
      required: false,
    },
    {
      id: 2,
      name: 'email',
      type: 'text',
      label: lblEmail[language],
      placeholder: plhEmail[language],
      required: false,
    },
    {
      id: 3,
      name: 'message',
      type: 'textarea',
      rows: 7,
      label: lblMessage[language],
      placeholder: plhMessage[language],
      required: true,
    },
  ]

  const addFeedback = async (bodyParams) => {
    setLoader(true)
    try {
      const res = await create('feedback/add', bodyParams, {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      })
      const data = await res.json()
      setLoader(false)

      if (data.error) {
        const error = feedbackErrors.addFeedback.errFeedback[language]
        setError(error)
        return
      }

      if (data.data) {
        setSuccessInfo(feedbackSuccess.addFeedback.feedbackAdded)
        setIsFeedbackSuccess(true)
      } else {
        setError(errors?.errWentWrong)
      }
    } catch (e) {
      setLoader(false)
      const error = feedbackErrors.addFeedback.errFeedback[language]
      setError(error)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormFeedbackValues({ ...formFeedbackValues, [name]: value })
  }

  const handleBlur = (e) => {
    const { name } = e.target
    const errors = validateFormValues(formFeedbackValues, validations, language)
    setFormErrors({ ...formErrors, [name]: errors[name] })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setFormErrors(validateFormValues(formFeedbackValues, validations, language))
    setIsSubmit(true)
  }

  useEffect(() => {
    if (isSubmit && Object.keys(formErrors).length <= 0) {
      addFeedback(formFeedbackValues)
    }
  }, [isSubmit, formErrors])

  return (
    <div className="MTS__flex MTS__justify-between MTS__items-center MTS__gap-2 MTS__flex-col">
      {isFeedbackSuccess ?
      //   <div
      //   className={`MTS__h-auto MTS__w-full MTS__overflow-y-auto MTS__p-0 MTS__m-0 MTS__text-base sm:MTS__text-xl MTS__text-primary-200 MTS__text-center`}
      // >
      //   {feedback?.successMessage[language]}
      // </div>
      null : (
        <>
          <Button
            className="MTS__border-2 MTS__mb-4 MTS__grid MTS__grid-flow-col MTS__gap-2 MTS__items-center MTS__cursor-default"
            varient="secondary-outline-dashed"
          >
            <div className="MTS__w-5 MTS__h-5 lg:MTS__w-8 lg:MTS__h-8">
              <Info2Icon circleClassName="MTS__fill-secondary" pathClassName="MTS__fill-white"></Info2Icon>
            </div>
            <div>
              <Title className="MTS__text-primary-200 MTS__font-semibold">{notes?.feedbackInfo[language]}</Title>
            </div>
          </Button>
          <div className="MTS__flex MTS__flex-col MTS__w-full MTS__gap-8">
            <form
              className="MTS__flex MTS__flex-col MTS__gap-4 MTS__mx-auto MTS__font-secondary MTS__w-full sm:MTS__w-[80%] lg:MTS__w-[60%]"
              onSubmit={handleSubmit}
            >
              {inputs.map((input) => (
                <FormInput
                  {...input}
                  inputClassName={`MTS__bg-whitesmoke MTS__border-tertiary`}
                  value={formFeedbackValues[input.name]}
                  onChange={handleChange}
                  errorMessage={formErrors[input.name]}
                  onBlur={handleBlur}
                  onKeyUp={handleBlur}
                  key={input.id}
                ></FormInput>
              ))}
              <Button varient="secondary-outline" type="submit" className="MTS__self-center">
                {buttons?.continue[language]}
              </Button>
            </form>
          </div>
        </>
      )}
    </div>
  )
}
