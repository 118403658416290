import React, { useState } from 'react'
import Calendar from 'react-calendar'
import { CalendarIcon } from '../../../assets/Icons'
import { formatDate } from '../../../lib/reusableFunctions'
import './Calendar.scss'

/**
 * @description Render form inputs
 * @param {string} lable label for input
 * @returns
 */
export default function FormDate({
  label,
  id,
  errorMessage,
  required,
  lblClassName,
  inputClassName,
  type,
  date,
  onChange,
  language,
  calenderLocale,
  placeholder,
  disableField,
  availableDates,
  getAvailability,
  calendarClass,
  minDate = new Date(),
  onBlur,
  onKeyUp,
  ...inputProps
}) {
  const [showCalendar, setShowCalendar] = useState()
  const arrvailableDates = []
  for (let d in availableDates) {
    if (availableDates[d] > 0) {
      const arrDates = d.split('/')
      const dd = arrDates[0]
      const mm = arrDates[1]
      const yyyy = arrDates[2]
      arrvailableDates.push(new Date(yyyy, parseInt(mm) - 1, dd).getDate())
    }
  }

  return (
    <div className={`${disableField && 'MTS__cursor-not-allowed'} MTS__flex MTS__flex-col`}>
      <div>
        <label
          className={`MTS__inline-block MTS__text-xs MTS__font-semibold MTS__leading-5 lg:MTS__leading-6 lg:MTS__mb-1 ${
            disableField ? 'MTS__text-sandstone-40' : 'MTS__text-primary-100'
          } ${lblClassName ? lblClassName : ''}`}
          htmlFor={id}
        >
          {label}
        </label>
        {required && <span className="MTS__inline-block MTS__text-danger">*</span>}
      </div>
      <div className="MTS__relative">
        {/* onBlur={() => setShowCalendar(false)} */}
        <div
          className={`MTS__flex MTS__items-center MTS__gap-2 MTS__bg-whitesmoke MTS__text-sm lg:MTS__text-base MTS__w-full MTS__px-2 MTS__py-3 lg:MTS__px-4 lg:MTS__py-5 MTS__border focus:MTS__outline-none ${
            errorMessage
              ? disableField
                ? 'MTS__text-danger/50'
                : 'MTS__text-danger'
              : disableField
              ? 'MTS__border-tertiary/50'
              : 'MTS__border-tertiary'
          } ${disableField ? 'MTS__cursor-not-allowed' : 'MTS__cursor-pointer'} ${inputClassName}`}
          onClick={() => {
            if (!disableField) {
              setShowCalendar((prev) => !prev)
              if (date) {
                getAvailability(new Date(date))
              } else {
                getAvailability(new Date())
              }
            }
          }}
        >
          <span
            className={`${
              date
                ? 'MTS__text-primary-400 MTS__font-semibold'
                : disableField
                ? 'MTS__text-primary-100/30'
                : 'MTS__text-primary-100/50'
            } MTS__text-sm lg:MTS__text-base`}
          >
            {date ? formatDate(new Date(date), language) : placeholder}
          </span>
          <span className="MTS__self-center MTS__ml-auto">
            <CalendarIcon
              className={`${disableField ? 'MTS__fill-primary-400/50' : 'MTS__fill-primary-400'}`}
            ></CalendarIcon>
          </span>
        </div>
        {showCalendar && (
          <>
            <div className="MTS__inset-0 MTS__fixed MTS__z-10" onClick={() => setShowCalendar(false)} />
            <Calendar
              className={`MTS__text-sm lg:MTS__text-base MTS__absolute MTS__top-full MTS__z-50 MTS__left-0 ${calendarClass}`}
              onChange={(value) => {
                onChange(value)
                setShowCalendar(false)
              }}
              onBlur={onBlur}
              onKeyUp={onKeyUp}
              value={date ? new Date(date) : null}
              locale={calenderLocale[language]}
              minDate={minDate}
              calendarType="ISO 8601"
              tileDisabled={({ date, view }) => view !== 'year' && view !== 'decade' && !arrvailableDates.includes(date.getDate())}
              onActiveStartDateChange={({ activeStartDate }) => {
                getAvailability(activeStartDate)
              }}
            ></Calendar>
          </>
        )}
      </div>
      <span className={`${disableField ? 'MTS__text-danger/50' : 'MTS__text-danger'} MTS__text-sm`}>
        {errorMessage}
      </span>
    </div>
  )
}
