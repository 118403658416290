import React, { useState, useEffect, useRef } from 'react'
import TicketsInformation from './MultiStepForm/TicketsInformation/TicketsInformation'
import BuyerInformation from './MultiStepForm/BuyerInformation/BuyerInformation'
import PaymentInformation from './MultiStepForm/PaymentInformation/PaymentInformation'
import AccordionItem from '../../Accordion/AccordionItem'
import { removeParam, formatDate, formatPrice, downloadPdf } from '../../../lib/reusableFunctions'
import Button from '../../Buttons/Button'
import Popup from '../../Popup/Popup'
import { create } from '../../../api/fetch'

export default function ParkingForm({
  pageData,
  language,
  setError,
  setLoader,
  calenderLocale,
  VEHICLE_TYPE,
  TICKET_TYPE,
  PARKING_TEMPLATE,
  updateTemplateString,
}) {
  // * All Translations
  const { errors, notes, buttons, ticketsInformation, buyerInformation, paymentInformation, pdfTemplate } = pageData
  const { paymentErrors } = paymentInformation
  const { lblArrival, lblVehicleType, lblTicketType, lblPlate, lblPrice, lblReservationID } = pdfTemplate

  // * Accordion
  const [activeTab, setActiveTab] = useState(1)
  const handleToggle = (index) => {
    // if (activeTab === index) {
    //   return setActiveTab(1)
    // }
    setActiveTab(index)
    setStep(index)
  }

  // * Multi step form
  const [step, setStep] = useState(1)
  const prevStep = (id) => {
    // setStep((prevState) => prevState - 1)
    // if (id <= step) {
    setStep((prevState) => prevState - 1)
    // }
    setActiveTab((prevState) => prevState - 1)
  }
  const nextStep = (id) => {
    // if (id >= step) {
    setStep((prevState) => prevState + 1)
    // }
    setActiveTab((prevState) => prevState + 1)
  }

  // * Tickets Information
  const initialTicket = {
    vehicleType: '',
    ticketType: '',
    price: 0,
    servicePrice: 0,
    arrival: null,
    departure: null,
    plate: '',
    rentalCar: false,
    template: PARKING_TEMPLATE,
    parkId: 'u1101',
  }
  const [tickets, setTickets] = useState([initialTicket])

  // * Buyer information
  const initialValues = {
    name: '',
    surname: '',
    country: '',
    email: '',
    plate: '',
    phone: '',
    purpose: '',
    tnc: false,
    commercial: false,
  }
  const [formValues, setFormValues] = useState(initialValues)

  // * TIMER FOR
  const TIMEOUT_SECONDS = 480 // 8 minutes in seconds
  const POPUP_AFTER_SECONDS = 180 // 3 minutes in seconds
  const START_TIMER_ON_STEP = 3 // start timer on payment step
  const [timeLeft, setTimeLeft] = useState(TIMEOUT_SECONDS)
  const [showTimerPopup, setShowTimerPopup] = useState(false)
  const intervalRef = useRef(null)

  // * START TIMER
  const startHandler = () => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1)
      }, 1000)
    }
  }

  // * END TIMER
  const stopHandler = () => {
    clearInterval(intervalRef.current)
    intervalRef.current = null
    setTimeLeft(TIMEOUT_SECONDS)
  }

  useEffect(() => {
    if (step === START_TIMER_ON_STEP) {
      startHandler()
    } else {
      stopHandler()
    }
  }, [step])

  useEffect(() => {
    if (timeLeft === 0) {
      stopHandler()
      setShowTimerPopup(false)
      window.location.reload()
    }
    // * Show popup before POPUP_AFTER_SECONDS time left
    if (timeLeft === POPUP_AFTER_SECONDS) {
      setShowTimerPopup(true)
    }
  }, [timeLeft])

  // * Checkout ID
  const [checkoutId, setCheckoutId] = useState('')
  const [savedTickets, setSavedTickets] = useState({})
  const [activeTabTickets, setActiveTabTickets] = useState(0)

  // * Payment information
  const [currentTicket, setCurrentTicket] = useState(-1)
  const [paymentDetails, setPaymentDetails] = useState({})

  // * Get url search params to redirect
  const url = new URL(window.location)
  const searchParams = url.searchParams

  const downloadQRCodeImage = (ticketObj) => {
    fetch(ticketObj.qrCodeImage, {
      method: 'GET',
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]))
          const link = document.createElement('a')
          link.href = url

          link.setAttribute('download', `QR ${ticketObj.plate?.replace(/ /g, '_')}-${ticketObj.reservationId}.png`)
          document.body.appendChild(link)
          link.click()
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }
  

  /**
   * @description Call API to confirm the parking and set payment details.
   * @param {{checkoutId}} checkoutId Reservation checkoutId
   */
  const getPaymentDetails = async (bodyParams) => {
    try {
      console.log(process.env.REACT_APP_SERVER_URL)
      setLoader(true)
      const res = await create('reservation/checkstatus', bodyParams, {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      })
      const data = await res.json()
      setLoader(false)

      if (data?.error) {
        if (res.status === 250) {
          const error = paymentErrors.getPaymentDetails.errHobexStatus[language]
          setError(error)
        } else if (res.status === 254) {
          const error = paymentErrors.getPaymentDetails.errPaymentStatus[language]
          setError(error)
        } else {
          const error = paymentErrors.getPaymentDetails.errReservation[language]
          setError(error)
        }
      }

      if (data?.data) {
        if (data?.data?.paymentStatus !== 'failed') {
          // Auto download ticket pdf
          data?.data?.tickets.forEach((ticket) => {
            const pdf = `${ticket?.plate?.replace(/ /g, '_')}-${ticket?.reservationId}.pdf`
            const url = `${process.env.REACT_APP_SERVER_URL}${pdf}`
            console.log("After response.", new Date())
            setTimeout(() => {
              console.log("Downloading now: ",url, new Date())
              downloadPdf(url, pdf)
            }, 3000);

          })
        }
        setPaymentDetails(data?.data)
      } else {
        const error = paymentErrors.getPaymentDetails.errReservation[language]
        setError(error)
      }

      const location1 = removeParam('id', window.location.href)
      const location2 = removeParam('resourcePath', location1)
      window.history.pushState(null, null, location2)
      searchParams.delete('id')
      searchParams.delete('resourcePath')
    } catch (e) {
      setLoader(false)
      setError(paymentErrors.getPaymentDetails.errReservation[language])
      setPaymentDetails({})
      const location1 = removeParam('id', window.location.href)
      const location2 = removeParam('resourcePath', location1)
      window.history.pushState(null, null, location2)
      searchParams.delete('id')
      searchParams.delete('resourcePath')
    }
  }

  // * Execute when get id and resourcePath in url(/)
  useEffect(() => {
    if (searchParams.get('id') && searchParams.get('resourcePath')) {
      getPaymentDetails({
        checkoutId: searchParams.get('id'),
      })

      return () => {
        searchParams.delete('id')
        searchParams.delete('resourcePath')
      }
    }
  }, [])

  // * Forms for multi step form
  // ? Step 1.Tickets Information
  // ? Step 2. Buyer Information
  // ? Step 3. Payment Information
  const accordionItems = [
    {
      id: 1,
      title: ticketsInformation?.title[language] + `${1 < step ? ': ' : ''} `,
      userSelection: `${tickets.map((ticket) => formatDate(new Date(ticket.arrival), language)).join(', ')}`,
      children: (
        <TicketsInformation
          setCheckoutId={setCheckoutId}
          nextStep={() => nextStep(2)}
          prevStep={() => prevStep(1)}
          setError={setError}
          setLoader={setLoader}
          pageData={{ ticketsInformation, buttons, errors }}
          language={language}
          formValues={formValues}
          setFormValues={setFormValues}
          calenderLocale={calenderLocale}
          initialTicket={initialTicket}
          tickets={tickets}
          setTickets={setTickets}
          VEHICLE_TYPE={VEHICLE_TYPE}
          TICKET_TYPE={TICKET_TYPE}
          savedTickets={savedTickets}
          setSavedTickets={setSavedTickets}
          activeTabTickets={activeTabTickets}
          setActiveTabTickets={setActiveTabTickets}
        ></TicketsInformation>
      ),
    },
    {
      id: 2,
      title: buyerInformation?.title[language] + `${2 < step ? ': ' : ''}`,
      userSelection: `${formValues.email}`,
      children: (
        <BuyerInformation
          setCheckoutId={setCheckoutId}
          nextStep={() => nextStep(3)}
          prevStep={() => prevStep(1)}
          setError={setError}
          setLoader={setLoader}
          pageData={{ buyerInformation, buttons, errors, pdfTemplate, notes, ticketsInformation }}
          language={language}
          formValues={formValues}
          setFormValues={setFormValues}
          savedTickets={savedTickets}
          setSavedTickets={setSavedTickets}
          activeTabTickets={activeTabTickets}
          setActiveTabTickets={setActiveTabTickets}
          tickets={tickets}
          updateTemplateString={updateTemplateString}
        ></BuyerInformation>
      ),
    },
    {
      id: 3,
      title: paymentInformation?.title[language],
      children: (
        <PaymentInformation
          checkout={checkoutId}
          prevStep={() => prevStep(2)}
          setError={setError}
          pageData={{ notes, buttons, errors }}
          language={language}
          formValues={formValues}
        ></PaymentInformation>
      ),
    },
  ]

  return (
    <>
      {/* * POPUP AFTER CONFIRM PAYMENT */}
      {paymentDetails && Object.keys(paymentDetails).length > 0 && paymentDetails?.paymentStatus === 'success' ? (
        <Popup
          handleClose={() => {
            setPaymentDetails({})
          }}
          title={paymentInformation?.paymentSuccess?.title[language]}
          disableCloseBtn={currentTicket >= 0 ? true : false}
          className={`${currentTicket < 0 ? 'MTS__w-[90%] lg:MTS__w-[90%] xl:MTS__w-[90%] 2xl:MTS__w-[70%]' : '!MTS__w-[885px]'
            } MTS__flex`}
        >
          <div className="MTS__flex MTS__flex-col MTS__w-full MTS__gap-4 MTS__font-secondary">
            {currentTicket < 0 ? (
              <>
                <div className="MTS__flex MTS__flex-wrap MTS__justify-center MTS__gap-4 lg:MTS__hidden">
                  {paymentDetails?.tickets.map((ticket, index) => (
                    <div
                      className="MTS__flex MTS__flex-col MTS__gap-4 MTS__border MTS__border-tertiary MTS__p-4 MTS__overflow-y-auto MTS__text-primary-400"
                      key={index}
                    >
                      <div className="MTS__text-heading">
                        <span className="MTS__font-semibold">{lblReservationID[language]}: </span>
                        <span className="">{ticket.reservationId}</span>
                      </div>
                      <div className="MTS__text-heading">
                        <span className="MTS__font-semibold">{lblArrival[language]}: </span>
                        <span className="">{formatDate(new Date(ticket.arrival), language)}</span>
                      </div>
                      <div className="MTS__text-heading">
                        <span className="MTS__font-semibold">{lblVehicleType[language]}: </span>
                        <span className="">{ticket.vehicleType}</span>
                      </div>
                      <div className="MTS__text-heading">
                        <span className="MTS__font-semibold">{lblTicketType[language]}: </span>
                        <span className="">{ticket.ticketType}</span>
                      </div>
                      <div className="MTS__text-heading">
                        <span className="MTS__font-semibold">{lblPlate[language]}: </span>
                        <span className="">{ticket.plate || '-'}</span>
                      </div>
                      <div className="MTS__text-heading">
                        <span className="MTS__font-semibold">{lblPrice[language]}: </span>{' '}
                        <span className="">
                          {formatPrice((ticket.amount || 0) + (ticket.serviceAmount || 0), language, 2, 2)}
                        </span>
                      </div>
                      <div className="MTS__flex MTS__grid-cols-2 MTS__gap-2 MTS__justify-center MTS__mt-2">
                        <Button
                          className="!MTS__px-2 !MTS__py-1 MTS__hidden lg:MTS__flex"
                          varient="background-image-light"
                          onClick={() => {
                            setCurrentTicket(index)
                          }}
                        >
                          {buttons?.preview[language]}
                        </Button>
                        <Button
                          className="MTS__self-end !MTS__px-2 !MTS__py-1"
                          varient="background-image-dark"
                          onClick={() => {
                            const pdf = `${ticket.plate?.replace(/ /g, '_')}-${ticket.reservationId}.pdf`
                            const url = `${process.env.REACT_APP_SERVER_URL}${pdf}`
                            downloadPdf(url, pdf, setLoader)
                          }}
                        >
                          {buttons?.download[language]}
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="MTS__gap-4 MTS__hidden lg:MTS__flex lg:MTS__flex-col MTS__border MTS__border-tertiary">
                  <div className="MTS__flex MTS__gap-2 MTS__border-b MTS__border-tertiary MTS__p-4">
                    <div className="MTS__text-heading MTS__font-semibold MTS__text-primary-400 MTS__text-center MTS__basis-[15%] xl:MTS__basis-[15%]">
                      {lblReservationID[language]}
                    </div>
                    <div className="MTS__text-heading MTS__font-semibold MTS__text-primary-400 MTS__text-center MTS__basis-[10%] xl:MTS__basis-[10%]">
                      {lblArrival[language]}
                    </div>
                    <div className="MTS__text-heading MTS__font-semibold MTS__text-primary-400 MTS__text-center MTS__basis-[10%] xl:MTS__basis-[10%]">
                      {lblVehicleType[language]}
                    </div>
                    <div className="MTS__text-heading MTS__font-semibold MTS__text-primary-400 MTS__text-center MTS__basis-[10%] xl:MTS__basis-[15%]">
                      {lblTicketType[language]}
                    </div>
                    <div className="MTS__text-heading MTS__font-semibold MTS__text-primary-400 MTS__text-center MTS__basis-[15%] xl:MTS__basis-[15%]">
                      {lblPlate[language]}
                    </div>
                    <div className="MTS__text-heading MTS__font-semibold MTS__text-primary-400 MTS__text-center MTS__basis-[10%] xl:MTS__basis-[10%]">
                      {lblPrice[language]}
                    </div>
                    <div className="MTS__basis-[30%] xl:MTS__basis-[25%]"></div>
                  </div>
                  {paymentDetails?.tickets.map((ticket, index) => (
                    <div className="MTS__flex MTS__gap-2 MTS__px-4 MTS__items-center last:MTS__pb-4" key={index}>
                      <div className="MTS__text-heading MTS__text-primary-400 MTS__text-center MTS__basis-[15%] xl:MTS__basis-[15%]">
                        {ticket.reservationId}
                      </div>
                      <div className="MTS__text-heading MTS__text-primary-400 MTS__text-center MTS__basis-[10%] xl:MTS__basis-[10%]">
                        {formatDate(new Date(ticket.arrival), language)}
                      </div>
                      <div className="MTS__text-heading MTS__text-primary-400 MTS__text-center MTS__basis-[10%] xl:MTS__basis-[10%]">
                        {ticket.vehicleType}
                      </div>
                      <div className="MTS__text-heading MTS__text-primary-400 MTS__text-center MTS__basis-[10%] xl:MTS__basis-[15%]">
                        {ticket.ticketType}
                      </div>
                      <div className="MTS__text-heading MTS__text-primary-400 MTS__text-center MTS__basis-[15%] xl:MTS__basis-[15%]">
                        {ticket.plate || '-'}
                      </div>
                      <div className="MTS__text-heading MTS__text-primary-400 MTS__text-center MTS__basis-[10%] xl:MTS__basis-[10%]">
                        {formatPrice((ticket.amount || 0) + (ticket.serviceAmount || 0), language, 2, 2)}
                      </div>
                      <div className="MTS__flex MTS__grid-cols-2 MTS__gap-2 MTS__basis-[30%] xl:MTS__basis-[25%] MTS__justify-end">
                        <Button
                          className="!MTS__px-2 !MTS__py-1 MTS__hidden lg:MTS__flex"
                          varient="background-image-light"
                          onClick={() => {
                            setCurrentTicket(index)
                          }}
                        >
                          {buttons?.preview[language]}
                        </Button>
                        <Button
                          className="MTS__self-end !MTS__px-2 !MTS__py-1"
                          varient="background-image-dark"
                          onClick={() => {
                            const pdf = `${ticket.plate?.replace(/ /g, '_')}-${ticket.reservationId}.pdf`
                            const url = `${process.env.REACT_APP_SERVER_URL}${pdf}`
                            downloadPdf(url, pdf, setLoader)
                          }}
                        >
                          {buttons?.download[language]}
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div
                  className={`MTS__h-[65vh] MTS__w-full MTS__flex MTS__justify-center MTS__overflow-y-auto MTS__border-3 MTS__border-tertiary MTS__p-0 MTS__m-0`}
                // className={`MTS__flex MTS__justify-center MTS__w-fill MTS__object-cover MTS__object-center`}
                // className={`MTS__h-[65vh] MTS__w-full MTS__overflow-y-auto MTS__border-3 MTS__border-tertiary MTS__p-0 MTS__m-0`}
                >
                  {/* <img src={previewImg} alt="Ticket" /> */}
                  <iframe
                    title={`ticket-${currentTicket + 1}`}
                    // className="MTS__w-full MTS__h-[65vh]"
                    className="MTS__w-full MTS__h-full"
                    srcDoc={paymentDetails?.tickets?.[currentTicket]?.template}
                    frameBorder="0"
                    height="1123px"
                    width="794px"
                  ></iframe>
                </div>
                <div className="MTS__flex MTS__justify-between">
                  <Button
                    className="MTS__self-end"
                    varient="background-image-light"
                    onClick={() => {
                      setCurrentTicket(-1)
                    }}
                  >
                    {buttons?.back[language]}
                  </Button>
                  <Button
                    className="MTS__self-end"
                    varient="background-image-dark"
                    onClick={() => {
                      const pdf = `${paymentDetails?.tickets?.[currentTicket]?.plate?.replace(/ /g, '_')}-${paymentDetails?.tickets?.[currentTicket]?.reservationId
                        }.pdf`
                      const url = `${process.env.REACT_APP_SERVER_URL}${pdf}`
                      downloadPdf(url, pdf, setLoader)
                    }}
                  >
                    {buttons?.download[language]}
                  </Button>
                </div>
              </>
            )}
          </div>
        </Popup>
      ) : paymentDetails && Object.keys(paymentDetails).length > 0 && paymentDetails?.paymentStatus === 'failed' ? (
        <Popup
          handleClose={() => {
            setPaymentDetails({})
          }}
          title={paymentInformation?.paymentDecline?.title[language]}
        >
          <div className="MTS__flex MTS__flex-col MTS__w-full MTS__gap-4 MTS__font-secondary">
            <div
              className={`MTS__h-auto MTS__w-full MTS__p-0 MTS__m-0 MTS__text-base sm:MTS__text-xl MTS__text-primary-200 MTS__text-center`}
            >
              {paymentDetails?.transactions?.[0]?.description || paymentInformation?.paymentDecline?.message[language]}
            </div>
          </div>
        </Popup>
      ) : paymentDetails && Object.keys(paymentDetails).length > 0 && paymentDetails?.paymentStatus === 'pending' ? (
        <Popup
          handleClose={() => {
            setPaymentDetails({})
          }}
          title={paymentInformation?.paymentPending?.title[language]}
        >
          <div className="MTS__flex MTS__flex-col MTS__w-full MTS__gap-4 MTS__font-secondary">
            <div
              className={`MTS__h-auto MTS__w-full MTS__p-0 MTS__m-0 MTS__text-base sm:MTS__text-xl MTS__text-primary-100 MTS__text-center`}
            >
              {paymentInformation?.paymentPending?.message[language]}
            </div>
          </div>
        </Popup>
      ) : null}

      {/* {previewImg ? (
        <Popup
          // handleClose={() => {
          //   setPaymentDetails({})
          // }}
          title={paymentInformation?.paymentSuccess?.title[language]}
          disableCloseBtn={true}
          // className={`MTS__w-[90%] lg:MTS__w-[90%] xl:MTS__w-[70%]`}
        >
          <div
            // className={`MTS__h-[65vh] MTS__w-full MTS__flex MTS__justify-center MTS__overflow-y-auto MTS__border-3 MTS__border-tertiary MTS__p-0 MTS__m-0`}
            className={`MTS__flex MTS__justify-center MTS__h-auto MTS__w-fill MTS__object-cover MTS__object-center MTS__overflow-y-auto`}
          >
            <div>
              <img src={previewImg} alt="Ticket" />
            </div>
            <iframe
              title={`ticket-${currentTicket + 1}`}
              ref={downloadCurrentPdfRef}
              className="MTS__w-[794px] MTS__h-full MTS__invisible MTS__fixed MTS__left-[-200%]"
              srcDoc={paymentDetails?.tickets?.[currentTicket]?.template}
              frameBorder="0"
              height="1123px"
              width="794px"
            ></iframe>
          </div>
          <div className="MTS__flex MTS__justify-between MTS__mt-4">
            <Button
              className="MTS__self-end"
              varient="background-image-light"
              onClick={() => {
                setCurrentTicket(-1)
                setPreviewImg('')
              }}
            >
              Back
            </Button>
            <Button
              className="MTS__self-end"
              varient="background-image-dark"
              onClick={() => {
                printDocument(downloadCurrentPdfRef.current, setLoader)
              }}
            >
              Download
            </Button>
          </div>
        </Popup>
      ) : null} */}

      {/* * TIMER POPUP */}
      {showTimerPopup ? (
        <Popup
          handleClose={() => {
            setShowTimerPopup(false)
          }}
          // title={'3 mins remaining'}
          className={`MTS__flex MTS__pr-6 !MTS__py-8 sm:!MTS__pr-12 MTS__text-lg`}
        >
          {notes?.timeoutInfo[language]}
        </Popup>
      ) : null}

      {/* * FORM */}
      <article className="MTS__flex MTS__flex-col MTS__w-full MTS__gap-8 lg:MTS__gap-12">
        {/* * PARKNG FORMS */}
        {accordionItems.map((data, index) => (
          <AccordionItem
            key={data.id}
            id={data.id}
            title={data.title}
            isActive={activeTab === data.id}
            step={step}
            onToggle={() => handleToggle(data.id)}
            userSelection={data.userSelection}
            activeTab={activeTab}
            line={data.id !== accordionItems.length}
          >
            {data.children}
          </AccordionItem>
        ))}
      </article>
    </>
  )
}
