import React from 'react'
import Select from 'react-select'
import DropdownIndicator from './DropdownIndicator'
import './ReactSelect.scss'

export default function FormSelect({
  label,
  onChange,
  id,
  errorMessage,
  optionsArray,
  defaultOption,
  required,
  language,
  name,
  value,
  lblClassName,
  ...inputProps
}) {
  // const sortedArray = optionsArray.sort((a, b) => a[language].localeCompare(b[language]))
  const options = optionsArray.map((item) => {
    return {
      value: item.value,
      label: item.label[language],
    }
  })
  const customStyles = {
    // control: (provided, state) => ({
    //   display: 'flex',
    //   backgroundColor: '#F5F5F5',
    //   width: '100%',
    //   padding: '6px',
    //   border: '1px solid #CECECE',
    // }),
    control: (defaultStyles) => ({
      ...defaultStyles,
      border: errorMessage && '1px solid var(--color-danger)',
      color: 'var(--color-primary-400)',
    }),
    option: (defaultStyles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...defaultStyles,
        // borderBottom: '1px dotted pink',
        // padding: 20,
        backgroundColor: isFocused
          ? 'var(--color-primary-50)'
          : isSelected
          ? 'var(--color-white)'
          : 'var(--color-white)',
        color: 'var(--color-primary-400)',
        ':active': {
          ...defaultStyles[':active'],
          backgroundColor: 'var(--color-primary-50)',
          color: 'var(--color-primary-400)',
        },
      }
    },
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: 'var(--color-primary-100)',
      opacity: .5,
      textTransform: 'uppercase',
    }),
  }

  return (
    <div className="MTS__flex MTS__flex-col">
      <div>
        <label
          className={`MTS__inline-block MTS__text-xs MTS__font-semibold MTS__leading-5 lg:MTS__leading-6 lg:MTS__mb-1 MTS__text-primary-100 ${
            lblClassName ? lblClassName : ''
          }`}
          htmlFor={id}
        >
          {label}
        </label>
        {required && <span className="MTS__inline-block MTS__text-danger">*</span>}
      </div>

      <div className="MTS__relative MTS__w-full MTS__text-sm lg:MTS__text-base">
        <Select
          id={id}
          name={name}
          options={options}
          styles={customStyles}
          className={``}
          classNamePrefix={`${errorMessage ? 'react-select-error' : 'react-select'}`}
          placeholder={defaultOption}
          value={options.filter((option) => option.value === value)}
          onChange={(value) => {
            onChange(value.value)
          }}
          components={{ DropdownIndicator }}
        />
      </div>
      <span className={`MTS__text-danger MTS__text-sm`}>{errorMessage}</span>
    </div>
  )
}
