import React, { useState } from 'react'
import FormInput from '../../../Forms/Fields/FormInput'
import { validateFormValues } from '../../../../lib/validateBookingValues'
import Button from '../../../Buttons/Button'
import { get } from '../../../../api/fetch'

/**
 * @description Render email and plate inputs on popup
 * @param {Function} setPopup handler for popup state
 * @param {object} pageData language based values for content
 * @param {string} language current language
 * @param {Function} setError handler to set error
 * @param {Function} setLoader handler to set loader
 * @param {object} formBookingValues fields for manage booking {email, ticketId}
 * @param {Function} setFormBookingValues handler for booking values

 * @returns
 */
export default function Booking({
  setPopup,
  pageData,
  language,
  setError,
  setLoader,
  formBookingValues,
  setFormBookingValues,
  setSuccessInfo,
}) {
  // * Translations
  const { buttons, manageBooking } = pageData
  const { manageBookingErrors, manageBookingSuccess } = manageBooking
  const { validations, labels, placeholders } = manageBooking?.booking?.form
  const { lblEmail, lblReservationId } = labels
  const { plhEmail, plhReservationId } = placeholders

  // * For manage parking form
  const inputs = [
    {
      id: 1,
      name: 'email',
      type: 'text',
      label: lblEmail[language],
      placeholder: plhEmail[language],
      required: true,
    },
    {
      id: 2,
      name: 'reservationId',
      type: 'text',
      label: lblReservationId[language],
      placeholder: plhReservationId[language],
      required: true,
    },
  ]
  const [formErrors, setFormErrors] = useState({})
  // const [isSubmit, setIsSubmit] = useState(false)

  /**
   * @description Call API to confirm ticket deatils for manage parking.
   * @param {{ email, reservationId, language }}
   */
  const getReservation = async ({ email, reservationId, language }) => {
    try {
      setLoader(true)
      const res = await get(
        `reservation/getReservation`,
        {
          email,
          reservationId,
          language,
        },
        {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      )
      const data = await res.json()
      setLoader(false)

      if (data?.error) {
        if (res.status === 250) {
          const error = manageBookingErrors.getReservation.errGetTicket[language]
          setError(error)
        } else if (res.status === 255) {
          const error = manageBookingErrors.getReservation.oldTicket[language]
          setError(error)
        } else if (res.status === 256) {
          const error = manageBookingErrors.getReservation.canceledTicket[language]
          setError(error)
        } else {
          const error = manageBookingErrors.getReservation.errReservation[language]
          setError(error)
        }
        return
      }

      if (data.data) {
        setSuccessInfo(manageBookingSuccess.getReservation.otpSent)
        setPopup(2)
      } else {
        const error = manageBookingErrors.getReservation.errGetTicket[language]
        setError(error)
        // setError({
        //   de: 'Daten nicht gefunden. Bitte geben Sie die richtigen Details ein.',
        //   it: 'Dati non trovati. Si prega di inserire i dettagli corretti.',
        //   en: 'Data Not Found. Please enter proper details.',
        // })
        // setError({
        //   de: 'Ihr Ticket wurde bereits storniert.',
        //   it: 'Il tuo biglietto è già stato cancellato.',
        //   en: 'Your ticket is already been canceled.',
        // })
      }
    } catch (e) {
      setLoader(false)
      const error = manageBookingErrors.getReservation.errReservation[language]
      setError(error)
    }
  }

  // * Form handling
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormBookingValues({ ...formBookingValues, [name]: value })
  }

  const handleBlur = (e) => {
    const { name } = e.target
    const errors = validateFormValues(formBookingValues, validations, language)
    setFormErrors({ ...formErrors, [name]: errors[name] })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const errors = validateFormValues(formBookingValues, validations, language)
    setFormErrors(errors)
    if (Object.keys(errors).length <= 0) {
      // setIsSubmit(true)
      getReservation({
        email: formBookingValues.email,
        reservationId: formBookingValues.reservationId,
        language,
      })
    }
  }

  // useEffect(() => {
  //   if (isSubmit && Object.keys(formErrors).length <= 0) {
  //     // setPopup(2)
  //     getReservation({
  //       email: formBookingValues.email,
  //       reservationId: formBookingValues.reservationId,
  //       language,
  //     })
  //   }
  // }, [isSubmit, formErrors])

  return (
    <>
      {/* {error && (
        <Toast
          varient="danger"
          handleClick={() => setError('')}
          message={error[language]}
          autoDelete={true}
          autoDeleteTime={5000}
        ></Toast>
      )} */}
      <div className="MTS__flex MTS__flex-col MTS__w-full MTS__gap-8">
        <form
          className="MTS__flex MTS__flex-col MTS__gap-8 MTS__mx-auto MTS__font-secondary MTS__w-full sm:MTS__w-[80%] lg:MTS__w-[60%]"
          onSubmit={handleSubmit}
        >
          {/* <div className="grid mx-auto font-secondary bg-whitesmoke sm:w-[60%] lg:w-[90%] xl:w-[50%] p-8 sm:p-12 lg:p-16"> */}
          {inputs.map((input) => (
            // <div key={input.id}>
            <FormInput
              {...input}
              inputClassName={`MTS__bg-whitesmoke MTS__border-tertiary`}
              value={formBookingValues[input.name]}
              onChange={handleChange}
              errorMessage={formErrors[input.name]}
              onBlur={handleBlur}
              onKeyUp={handleBlur}
              key={input.id}
            ></FormInput>
            // </div>
          ))}
          <Button varient="secondary-outline" type="submit" className="MTS__self-center">
            {buttons?.continue[language]}
          </Button>
        </form>
      </div>
    </>
  )
}
