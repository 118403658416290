import React from 'react'
import { components } from 'react-select'
import { DropdownIcon } from '../../../assets/Icons'

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIcon className="MTS__stroke-primary-400" />
    </components.DropdownIndicator>
  )
}

export default DropdownIndicator
