// *********** VALIDATE FORM VALUES :: return error object ***********
const validateFormValues = (values, validations, language) => {
  const { email, reservationId } = values
  const errors = {}
  const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/

  if (!email.trim()) {
    errors.email = validations?.email[language]
  } else if (!emailRegex.test(email)) {
    errors.email = validations?.validEmail[language]
  }

  if (!reservationId.trim()) {
    errors.reservationId = validations?.reservationId[language]
  }

  return errors
}

export { validateFormValues }
