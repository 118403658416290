import React from 'react'

/**
 *
 * @returns Info Icon
 */
export default function Info2({ className, circleClassName, pathClassName }) {
  return (
    <svg viewBox="0 0 48 48" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" className={circleClassName ? circleClassName : 'MTS__fill-secondary'} />
      <path
        d="M28.8933 35.8319C28.8813 35.8359 27.7753 36.2039 26.8153 36.2039C26.2853 36.2039 26.0713 36.0939 26.0033 36.0459C25.6673 35.8119 25.0433 35.3739 26.1113 33.2459L28.1113 29.2579C29.2973 26.8899 29.4733 24.5999 28.6013 22.8079C27.8893 21.3419 26.5233 20.3359 24.7613 19.9759C24.1273 19.8459 23.4833 19.7819 22.8453 19.7819C19.1473 19.7819 16.6573 21.9419 16.5533 22.0339C16.1953 22.3499 16.1113 22.8739 16.3493 23.2859C16.5893 23.6979 17.0833 23.8859 17.5393 23.7299C17.5493 23.7259 18.6573 23.3559 19.6173 23.3559C20.1433 23.3559 20.3553 23.4659 20.4213 23.5119C20.7593 23.7479 21.3853 24.1919 20.3193 26.3159L18.3193 30.3059C17.1313 32.6759 16.9573 34.9659 17.8293 36.7559C18.5413 38.2219 19.9053 39.2279 21.6713 39.5879C22.2993 39.7139 22.9433 39.7819 23.5793 39.7819C27.2793 39.7819 29.7713 37.6219 29.8753 37.5299C30.2333 37.2159 30.3173 36.6899 30.0793 36.2779C29.8393 35.8679 29.3413 35.6839 28.8933 35.8319Z"
        className={pathClassName ? pathClassName : 'MTS__fill-white'}
      />
      <path
        d="M27.2148 17.7838C29.9763 17.7838 32.2148 15.5452 32.2148 12.7838C32.2148 10.0224 29.9763 7.78381 27.2148 7.78381C24.4534 7.78381 22.2148 10.0224 22.2148 12.7838C22.2148 15.5452 24.4534 17.7838 27.2148 17.7838Z"
        className={pathClassName ? pathClassName : 'MTS__fill-white'}
      />
    </svg>
  )
}
