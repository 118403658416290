import React from 'react'
import ParkingPage from './components/ParkingPage'
import { ParkingDataProvider } from './context/ParkingContext'

export default function App({ language }) {
  return (
    <ParkingDataProvider>
      <ParkingPage language={language}></ParkingPage>
    </ParkingDataProvider>
  )
}
