import React, { useState, Fragment } from 'react'
import Popup from '../../Popup'
import Booking from './Booking'
import Otp from './Otp'
import Button from '../../../Buttons/Button'
import Title from '../../../Titles/Title'
import { formatDate, downloadPdf, formatPrice, customDate } from '../../../../lib/reusableFunctions'
import { create, update } from '../../../../api/fetch'
import Feedback from '../Feedback/Feedback'
import ChangeTicket from './ChangeTicket'
import '../../../Forms/Fields/Calendar.scss'

export default function ManageBooking({
  pageData,
  language,
  successInfo,
  setSuccessInfo,
  error,
  setError,
  loader,
  setLoader,
  calenderLocale,
  VEHICLE_TYPE,
  TICKET_TYPE,
  PARKING_TEMPLATE,
  updateTemplateString,
}) {
  // * Translations
  const {
    title,
    notes,
    buttons,
    manageBooking,
    errors,
    feedback,
    ticketsInformation,
    pdfTemplate: {
      lblBookingConfirmation,
      lblBookingConfirmationSpecialTicket,
      lblTicketCode,
      lblTicketMessage,
      lblTicketDetails,
      lblArrival,
      lblDeparture,
      lblVehiclePlate,
      lblPrice,
      lblParkingPrice,
      lblServicePrice,
      lblVehicleType,
      lblTicketType,
      lblReservationID,
      lblParkingRule
    },
    ticketsInformation: {
      form: {
        labels: { lblNoCalcellationText },
      },
    },
  } = pageData
  const { manageBookingErrors, manageBookingSuccess, ticketPreview } = manageBooking

  // * Popup
  const [popup, setPopup] = useState(0)

  // * Ticket booking values
  const initialFormBookingValues = {
    email: '',
    reservationId: '',
  }
  const [formBookingValues, setFormBookingValues] = useState(initialFormBookingValues)

  // * OTP
  const [otp, setOtp] = useState([])

  // * Change ticket booking
  const [tempManageTicket, setTempManageTicket] = useState({})
  const [manageTicket, setManageTicket] = useState({})

  // * Feedback
  const [isFeedbackSuccess, setIsFeedbackSuccess] = useState(false)

  function getStringDate(dateObj) {
    return dateObj.getFullYear() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getDate()
  }
  /**
   * @description Call API to update a parking ticket.
   */
  const updateTicket = async (tempManageTicket, manageTicket) => {
    setLoader(true)
    try {
      const {
        reservationId,
        arrival,
        departure,
        plate,
        template,
        amount,
        serviceAmount,
        vehicleType,
        ticketType,
        qrCodeImage,
      } = manageTicket?.tickets[0]
      const { email } = manageTicket?.customer

      // template change
      const ticket = manageTicket?.tickets[0]
      const formattedArrival = formatDate(new Date(ticket.arrival), language)
      const formattedDeparture = formatDate(new Date(ticket.departure), language)

      // 
      let isCancellation = true
      let UpdatedRuleTxt = lblParkingRule[language]
      let months = {
        de: [
          'Januar',
          'Februar',
          'März',
          'April',
          'Mai',
          'Juni',
          'Juli',
          'August',
          'September',
          'Oktober',
          'November',
          'Dezember',
        ],
        it: [
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
        ],
        en: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
      }
      let a_egDate = '10'
      let a_egmonth = months[language][7]
      let a_egYear = '2024'
      let c_egDate = '06'
      let c_egmonth = months[language][7]
      let c_egYear = '2024'

      if (ticket?.arrival) {
        let todayDate = getStringDate(new Date())
        let selectedArrival = typeof ticket?.arrival === 'string' ? new Date(ticket?.arrival) : ticket?.arrival

        let allowedDate = new Date(selectedArrival)
        allowedDate.setDate(selectedArrival.getDate() - Math.abs(4))

        if (
          (getStringDate(selectedArrival) === todayDate || allowedDate < new Date()) &&
          getStringDate(allowedDate) !== todayDate
        ) {
          // Cancellation not possible
          isCancellation = false
          let contentToReplace = {
            en: '<p>Example of cancellation and full refund:</p><p>planned arrival on {{a_date}} {{a_month}} {{a_year}}</p><p>cancellation by midnight on {{c_date}} {{c_month}} {{c_year}}</p>',
            it: '<p>Esempio per la cancellazione e il rimborso totale del ticket:</p><p>arrivo previsto giorno {{a_date}} del mese di {{a_month}} {{a_year}}</p><p>cancellazione entro le ore 24.00 del giorno {{c_date}} {{c_month}} {{c_year}}</p>',
            de: '<p>Beispiel für Stornierung und volle Rückerstattung:</p><p>Geplante Ankunft am {{a_date}}. {{a_month}} {{a_year}}</p><p>Stornierung bis Mitternacht am {{c_date}}. {{c_month}} {{c_year}}</p>',
          }
          UpdatedRuleTxt = UpdatedRuleTxt.replace(
            [contentToReplace[language]],
            `<b>${lblNoCalcellationText[language]}</b>`,
          )
        } else {
          // Cancellation Possible
          let aDate = selectedArrival.getDate()
          a_egDate = (aDate.toString().length === 1 ? '0' : '') + aDate
          a_egmonth = months[language][selectedArrival.getMonth()]
          a_egYear = selectedArrival.getFullYear()

          let cDate = allowedDate.getDate()
          c_egDate = (cDate.toString().length === 1 ? '0' : '') + cDate
          c_egmonth = months[language][allowedDate.getMonth()]
          c_egYear = allowedDate.getFullYear()

          let exampleLine = {
            de: '<p>Beispiel für Stornierung und volle Rückerstattung:</p>',
            it: '<p>Esempio per la cancellazione e il rimborso totale del Ticket:</p>',
            en: '<p>Example of cancellation and full refund:</p>',
          }
          UpdatedRuleTxt = UpdatedRuleTxt.replace(exampleLine[language], ' ')
        }
      }
      if (isCancellation) {
        UpdatedRuleTxt = updateTemplateString(UpdatedRuleTxt, {
          a_date: a_egDate,
          a_month: a_egmonth,
          a_year: a_egYear,
          c_date: c_egDate,
          c_month: c_egmonth,
          c_year: c_egYear,
        })
      }
      // 
      const bodyPrams = {
        reservationId,
        arrival: customDate(new Date(arrival), 'mm-dd-yyyy'),
        departure: customDate(new Date(departure), 'mm-dd-yyyy'),
        plate: plate.replace(/\s/g, ''),
        email,
        // template: template
        //   .replace(noPlate, plate)
        //   .replace(
        //     formatDate(new Date(tempManageTicket?.tickets[0]?.arrival), language),
        //     formatDate(new Date(arrival), language),
        //   )
        //   .replace(
        //     formatDate(new Date(tempManageTicket?.tickets[0]?.departure), language),
        //     formatDate(new Date(departure), language),
        //   ),
        // template: updateTemplateString(PARKING_TEMPLATE, {
        //   [noPlate]: plate,
        //   [formatDate(new Date(tempManageTicket?.tickets[0]?.arrival), language)]: formatDate(new Date(arrival), language),
        //   [formatDate(new Date(tempManageTicket?.tickets[0]?.departure), language)]: formatDate(new Date(departure), language),
        // }),
        template: updateTemplateString(PARKING_TEMPLATE, {
          lblBookingConfirmation:
            ticketType === 'special' ? lblBookingConfirmationSpecialTicket[language] : lblBookingConfirmation[language],
          lblTicketCode: lblTicketCode[language],
          lblTicketMessage: lblTicketMessage[language],
          lblTicketDetails: lblTicketDetails[language],
          lblArrival: lblArrival[language],
          lblDeparture: lblDeparture[language],
          lblVehiclePlate: lblVehiclePlate[language],
          lblVehicleType: lblVehicleType[language],
          lblTicketType: lblTicketType[language],
          lblReservationId: lblReservationID[language],
          lblRefundNote: notes?.refundInfo[language],
          lblParkingRule: UpdatedRuleTxt,
          lblPrice: lblPrice[language],
          lblParkingPrice: lblParkingPrice[language],
          lblServicePrice: lblServicePrice[language],
          plate: plate.replace(/\s/g, '') || 'N/A',
          price: formatPrice((amount || 0), language, 2, 2),
          servicePrice: formatPrice(serviceAmount || 0, language, 2, 2),
          arrival: formatDate(new Date(arrival), language),
          departure: formatDate(new Date(departure), language),
          vehicleType: vehicleType,
          ticketType: ticketType,
          reservationId: reservationId,
          qrCodeImage: qrCodeImage,
        }),
      }

      const res = await update('reservation/updateticket', bodyPrams, {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      })
      const data = await res.json()
      setLoader(false)
      if (data?.error) {
        if (res.status === 252) {
          const error = manageBookingErrors.updateTicket.errUpdate[language]
          setError(error)
        } else if (res.status === 255) {
          const error = manageBookingErrors.updateTicket.updateArrivalDate[language]
          setError(error)
        } else {
          const error = manageBookingErrors.updateTicket.errUpdate[language]
          setError(error)
        }
        setPopup(6)
        setManageTicket(tempManageTicket)
        return
      }
      if (data?.data) {
        setManageTicket(data?.data)
        setTempManageTicket(data?.data)
        setPopup(3)
        setSuccessInfo(manageBookingSuccess.updateTicket.ticketUpdated)
        setFormBookingValues(initialFormBookingValues)
        setOtp([])
      } else {
        setManageTicket(tempManageTicket)
        const error = manageBookingErrors.updateTicket.errUpdate[language]
        setError(error)
        setPopup(6)
      }
    } catch (e) {
      setLoader(false)
      const error = manageBookingErrors.updateTicket.errUpdate[language]
      setError(error)
    }
  }

  /**
   * @description Call API to cancel a parking ticket.
   */
  const cancelTicket = async (bodyParams) => {
    try {
      setLoader(true)
      const res = await create('reservation/cancelTicket', bodyParams, {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      })

      const data = await res.json()
      if (data.error) {
        setLoader(false)
        if (res.status === 255) {
          const error = manageBookingErrors.cancelTicket.notCanceled[language]
          setError(error)
        } else if (res.status === 250) {
          const error = manageBookingErrors.cancelTicket.statusPending[language]
          setError(error)
        } else if (res.status === 253) {
          const error = data.error.description || manageBookingErrors.cancelTicket.errCancel[language]
          setError(error)
        } else if (res.status === 256) {
          const error = manageBookingErrors.cancelTicket.canceledTicket[language]
          setError(error)
        } else {
          const error = manageBookingErrors.cancelTicket.errCancel[language]
          setError(error)
        }
        setPopup(3)
        return
      }

      const cancelTicketTest = /^(000\.000\.|000\.100\.1|000\.[36])/.test(data?.data?.code)
      if (cancelTicketTest) {
        setPopup(0)
        setSuccessInfo(manageBookingSuccess.cancelTicket.ticketCanceled)
      } else {
        const error = manageBookingErrors.cancelTicket.errCancel[language]
        setError(error)
        setPopup(3)
      }
      // const cancelTicketTest = /^(000\.000\.|000\.100\.1|000\.[36])/.test(data?.data?.cancelTicket?.result?.code)
      // if (cancelTicketTest) {
      //   setPopup(5)
      // } else {
      //   if (data?.code === 400 || data?.cancelTicket?.code === 400) {
      //     setError(data?.message?.result?.description || data?.result?.description || data?.message || data?.errorMsg)
      //     setPopup(0)
      //     setFormBookingValues(initialFormBookingValues)
      //     setOtp([])
      //   } else {
      //     setError(errors?.errWentWrong)
      //     setPopup(0)
      //     setFormBookingValues(initialFormBookingValues)
      //     setOtp([])
      //   }
      // }
      setLoader(false)
    } catch (e) {
      setLoader(false)
      const error = manageBookingErrors.cancelTicket.errCancel[language]
      setError(error)
      setPopup(3)
    }
  }

  // * Popups for manage booking
  // ? 1.Manage ticket credentials - email, reservationId
  // ? 2. OTP form
  // ? 3. Manage ticket information
  // ? 4. Cancel ticket - Confirmation
  // ? 5. Cancel ticket - Success Info
  // ? 6. Update ticket form
  // ? 7. Update ticket - update changes - Confirmation
  // ? 8. Update ticket - Discard changes - Confirmation
  // ? 9. Feedback
  const popupItems = [
    {
      id: 1,
      title: manageBooking?.booking?.title[language],
      handleClose: () => {
        setPopup(0)
        setFormBookingValues(initialFormBookingValues)
      },
      children: (
        <Booking
          setPopup={setPopup}
          pageData={{ buttons, manageBooking, errors }}
          language={language}
          error={error}
          setError={setError}
          loader={loader}
          setLoader={setLoader}
          formBookingValues={formBookingValues}
          setFormBookingValues={setFormBookingValues}
          setSuccessInfo={setSuccessInfo}
        ></Booking>
      ),
    },
    {
      id: 2,
      title: manageBooking?.otp?.title[language],
      handleClose: () => {
        setPopup(0)
        setFormBookingValues(initialFormBookingValues)
        setOtp([])
      },
      children: (
        <Otp
          setPopup={setPopup}
          pageData={{ buttons, notes, errors, manageBooking }}
          language={language}
          numInputs={6}
          otp={otp}
          setOtp={setOtp}
          error={error}
          setError={setError}
          loader={loader}
          setLoader={setLoader}
          formBookingValues={formBookingValues}
          setManageTicket={setManageTicket}
          setTempManageTicket={setTempManageTicket}
        ></Otp>
      ),
    },
    {
      id: 3,
      title: (
        <div className="MTS__flex MTS__justify-between MTS__mt-4">
          <Button varient="background-image-dark" onClick={() => setPopup(6)}>
            {buttons?.chnageBooking[language]}
          </Button>
          <Button varient="secondary-outline" onClick={() => setPopup(4)}>
            {buttons?.cancelBooking[language]}
          </Button>
          {/* </div> */}
        </div>
      ),
      handleClose: () => {
        setPopup(0)
        setFormBookingValues(initialFormBookingValues)
        setOtp([])
      },
      children: (
        <div className="MTS__flex MTS__flex-col MTS__w-full MTS__gap-4">
          <div
            // className={`MTS__h-[65vh] MTS__w-full MTS__overflow-y-auto MTS__border-3 MTS__border-tertiary MTS__p-0 MTS__m-0`}
            className={`lg:MTS__h-[65vh] MTS__w-full MTS__flex MTS__justify-center MTS__overflow-y-auto MTS__p-0 MTS__m-0`}
          >
            {/* <div ref={downloadPdfRef} dangerouslySetInnerHTML={{ __html: manageEmailString }}></div> */}
            <iframe
              title={`ticket0`}
              className="MTS__w-full MTS__h-full MTS__hidden lg:MTS__flex"
              // className="MTS__w-[794px] MTS__h-full MTS__mx-auto"
              srcDoc={manageTicket?.tickets?.[0]?.template}
              frameBorder="0"
            ></iframe>
            <div className="MTS__w-full MTS__h-full lg:MTS__hidden MTS__flex">{ticketPreview?.download[language]}</div>
          </div>
          <div className="MTS__gap-4 MTS__flex MTS__self-end">
            <Button
              className="self-end"
              varient="background-image-dark"
              onClick={() => {
                // printDocument(downloadPdfRef.current, setLoader)
                // printDocument(downloadPdfRef.current, () => {
                //   // setPopup(0)
                //   // setFormBookingValues(initialFormBookingValues)
                //   // setOtp([])
                // })
                const pdf = `${manageTicket?.tickets?.[0]?.plate?.replace(/ /g, '_')}-${manageTicket?.tickets?.[0]?.reservationId
                  }.pdf`
                const url = `${process.env.REACT_APP_SERVER_URL}${pdf}`
                downloadPdf(url, pdf, setLoader)
              }}
            >
              {buttons?.download[language]}
            </Button>
          </div>
        </div>
      ),
    },
    {
      id: 4,
      title: manageBooking?.cancel?.titleConfirm[language],
      handleClose: () => {
        setPopup(0)
        setFormBookingValues(initialFormBookingValues)
        setOtp([])
      },
      children: (
        <div className="MTS__flex MTS__justify-between">
          <Button varient="secondary-outline" onClick={() => setPopup(3)}>
            {buttons?.no[language]}
          </Button>
          <Button
            varient="background-image-dark"
            onClick={() => {
              // setPopup(4)
              cancelTicket({
                reservationId: manageTicket?.tickets[0]?.reservationId,
                email: manageTicket?.customer?.email,
                language,
              })
            }}
          >
            {buttons?.yes[language]}
          </Button>
        </div>
      ),
    },
    {
      id: 5,
      title: manageBooking?.cancel?.successTitle[language],
      handleClose: () => {
        setPopup(0)
        setFormBookingValues(initialFormBookingValues)
        setOtp([])
      },
      children: (
        <div className="MTS__flex MTS__justify-between">
          <span className="MTS__text-primary-200 MTS__text-base lg:MTS__text-xl MTS__text-center MTS__mx-auto MTS__font-semibold">
            {manageBooking?.cancel?.successMessage[language]}
          </span>
        </div>
      ),
    },
    {
      id: 6,
      title: manageBooking?.change?.title[language],
      handleClose: () => {
        setPopup(0)
        setFormBookingValues(initialFormBookingValues)
        setOtp([])
      },
      children: (
        <div className="MTS__flex MTS__flex-col MTS__gap-4 lg:MTS__gap-6 MTS__self-center MTS__mx-auto MTS__w-full MTS__items-center sm:MTS__p-4 lg:MTS__p-8">
          <ChangeTicket
            pageData={{ buttons, errors, ticketsInformation }}
            language={language}
            setLoader={setLoader}
            setError={setError}
            calenderLocale={calenderLocale}
            manageTicket={manageTicket}
            tempManageTicket={tempManageTicket}
            setManageTicket={setManageTicket}
            setPopup={() => {
              if (JSON.stringify(manageTicket) === JSON.stringify(tempManageTicket)) {
                setPopup(3)
              } else {
                setPopup(7)
              }
            }}
            setPopupBack={() => {
              if (JSON.stringify(manageTicket) === JSON.stringify(tempManageTicket)) {
                setPopup(3)
              } else {
                setPopup(8)
              }
            }}
            VEHICLE_TYPE={VEHICLE_TYPE}
            TICKET_TYPE={TICKET_TYPE}
          ></ChangeTicket>
        </div>
      ),
    },
    {
      id: 7,
      title: manageBooking?.change?.changeConfrim[language],
      handleClose: () => {
        setPopup(0)
        setFormBookingValues(initialFormBookingValues)
        setOtp([])
      },
      children: (
        <div className="MTS__flex MTS__justify-between">
          <Button varient="secondary-outline" onClick={() => setPopup(6)}>
            {buttons?.no[language]}
          </Button>
          <Button
            varient="background-image-dark"
            onClick={() => {
              updateTicket(tempManageTicket, manageTicket)
            }}
          >
            {buttons?.yes[language]}
          </Button>
        </div>
      ),
    },
    {
      id: 8,
      title: manageBooking?.change?.discardConfirm[language],
      handleClose: () => {
        setPopup(0)
        setFormBookingValues(initialFormBookingValues)
        setOtp([])
      },
      children: (
        <div className="MTS__flex MTS__justify-between">
          <Button varient="secondary-outline" onClick={() => setPopup(6)}>
            {buttons?.no[language]}
          </Button>
          <Button
            varient="background-image-dark"
            onClick={() => {
              setPopup(3)
              setManageTicket(tempManageTicket)
            }}
          >
            {buttons?.yes[language]}
          </Button>
        </div>
      ),
    },
    {
      id: 9,
      title: isFeedbackSuccess ? feedback?.successTitle[language] : feedback?.title[language],
      customHeaderClass: "!MTS__border-b-0",
      handleClose: () => {
        setPopup(0)
        setIsFeedbackSuccess(false)
      },
      children: (
        <Feedback
          pageData={{ feedback, buttons, errors, notes }}
          language={language}
          setError={setError}
          setLoader={setLoader}
          isFeedbackSuccess={isFeedbackSuccess}
          setIsFeedbackSuccess={setIsFeedbackSuccess}
          setSuccessInfo={setSuccessInfo}
        ></Feedback>
      ),
    },
  ]

  return (
    <>
      <article className="MTS__flex MTS__justify-between MTS__gap-4 sm:MTS__gap-2 MTS__pb-2 sm:MTS__pb-4 MTS__border-b-2 MTS__border-secondary MTS__items-center MTS__flex-col sm:MTS__flex-row">
        {/* * TITLE */}
        <div className="MTS__flex sm:MTS__place-self-end">
          <Title varient="heading" className="MTS__uppercase MTS__text-secondary">
            {title[language]}
          </Title>
        </div>
        <div className="MTS__w-full sm:MTS__w-auto MTS__flex MTS__gap-2 MTS__justify-between sm:MTS__justify-end MTS__-order-1 sm:MTS__order-1">
          {/* * FEEDBACK BUTTON */}
          <Button
            varient="secondary-outline"
            onClick={() => {
              setPopup(9)
            }}
          >
            Feedback
          </Button>
          {/* * MANAGE BOOKING BUTTON */}
          <Button
            varient="background-image-dark"
            onClick={() => {
              setPopup(1)
            }}
          >
            {buttons?.manageBooking[language]}
          </Button>
        </div>
      </article>

      {/* MANAGE BOOKING POPUPS */}
      {popupItems.map((item, index) => (
        <Fragment key={index}>
          {popup === item?.id ? ( 
            <Popup
              title={item?.title}
              handleClose={item?.handleClose}
              className={item?.id === 3 ? 'lg:!MTS__w-[885px]' : item?.id === 6 ? 'lg:!MTS__w-[900px]' : ''}
              customHeaderClass={item?.customHeaderClass || ""}
            >
              {item?.children}
            </Popup>
          ) : null}
        </Fragment>
      ))}
    </>
  )
}
