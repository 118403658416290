import React, { useEffect, useState } from 'react'
// import CountryDetails from '../../lib/newCountryList.json'
import Select from 'react-select'
import countries from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'
import itLocale from 'i18n-iso-countries/langs/it.json'
import deLocale from 'i18n-iso-countries/langs/de.json'
import DropdownIndicator from './DropdownIndicator'
import { validateFormValues } from '../../../lib/parkingValidation'
import './ReactSelect.scss'

export default function FormCountry({
  label,
  onChange,
  id,
  errorMessage,
  defaultOption,
  required,
  onBlur,
  name,
  formErrors,
  setFormErrors,
  formValues,
  validations,
  language,
  value,
  lblClassName,
  ...inputProps
}) {
  const [flag, setFlag] = useState(false)
  useEffect(() => {
    if (flag) {
      const errors = validateFormValues(formValues, validations, language)
      setFormErrors({ ...formErrors, [name]: errors[name] })
    }
  }, [flag])

  countries.registerLocale(enLocale)
  countries.registerLocale(itLocale)
  countries.registerLocale(deLocale)

  const countryObj = countries.getNames(language, { select: 'all' })

  let sortedCountries = []
  const isoCodes = ['IT', 'DE', 'AT', 'CH', 'FR', 'ES', 'GB']

  for (let code of isoCodes) {
    for (let country in countryObj) {
      if (code === country) {
        if (code === 'GB') {
          sortedCountries.push({
            label: `${countryObj[country].join(', ')}, Gran Bretagna`,
            value: country,
          })
        } else
          sortedCountries.push({
            label: countryObj[country].join(', '),
            value: country,
          })
        delete countryObj[country]
      }
    }
  }
  for (let country in countryObj) {
    sortedCountries.push({
      label: countryObj[country].join(', '),
      value: country,
    })
  }

  const customStyles = {
    // control: (provided, state) => ({
    //   display: 'flex',
    //   backgroundColor: '#F5F5F5',
    //   width: '100%',
    //   padding: '6px',
    //   border: '1px solid #CECECE',
    // }),
    control: (styles) => ({ ...styles, border: errorMessage && '1px solid var(--color-danger)' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        // borderBottom: '1px dotted pink',
        // padding: 20,
        backgroundColor: isFocused
          ? 'var(--color-primary-50)'
          : isSelected
          ? 'var(--color-white)'
          : 'var(--color-white)',
        color: 'var(--color-black)',
        ':active': {
          ...styles[':active'],
          backgroundColor: 'var(--color-primary-50)',
        },
      }
    },
    placeholder: (styles) => ({
      ...styles,
      color: 'var(--color-primary-100)',
      opacity: .5,
      textTransform: 'uppercase',
    }),
  }

  return (
    <div className="MTS__flex MTS__flex-col">
      <div>
        <label
          className={`MTS__inline-block MTS__text-xs MTS__font-semibold MTS__text-primary-100 MTS__leading-5 lg:MTS__leading-6 lg:MTS__mb-1 ${
            lblClassName ? lblClassName : ''
          }`}
          htmlFor={id}
        >
          {label}
        </label>
        {required && <span className="MTS__inline-block MTS__text-danger">*</span>}
      </div>

      <div className="MTS__relative MTS__w-full MTS__text-sm lg:MTS__text-base">
        <Select
          id={id}
          name={name}
          options={sortedCountries}
          styles={customStyles}
          className={``}
          classNamePrefix={`${errorMessage ? 'react-select-error' : 'react-select'}`}
          placeholder={defaultOption}
          value={sortedCountries.filter((option) => option.value === value)}
          onChange={(value) => {
            onChange(value.value)
            const errors = validateFormValues(formValues, validations, language)
            setFormErrors({ ...formErrors, [name]: errors[name] })
            setFlag(true)
          }}
          components={{ DropdownIndicator }}
        />
        <span className={`MTS__text-danger MTS__text-sm`}>{errorMessage}</span>
      </div>
    </div>
  )
}
