import React from 'react'

/**
 *
 * @returns Close Icon
 */
export default function Close({className}) {
  return (
    <svg viewBox="0 0 32 32" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 29C13.4288 29 10.9154 28.2376 8.77759 26.8091C6.63975 25.3807 4.97351 23.3503 3.98957 20.9749C3.00563 18.5995 2.74819 15.9856 3.2498 13.4638C3.75141 10.9421 4.98953 8.6257 6.80762 6.80762C8.6257 4.98953 10.9421 3.75141 13.4638 3.2498C15.9856 2.74819 18.5995 3.00563 20.9749 3.98957C23.3503 4.97351 25.3807 6.63975 26.8091 8.77759C28.2376 10.9154 29 13.4288 29 16C29 19.4478 27.6304 22.7544 25.1924 25.1924C22.7544 27.6304 19.4478 29 16 29ZM16 5.00001C13.8244 5.00001 11.6977 5.64514 9.88873 6.85384C8.07979 8.06254 6.66989 9.7805 5.83733 11.7905C5.00477 13.8005 4.78693 16.0122 5.21137 18.146C5.63581 20.2798 6.68345 22.2398 8.22183 23.7782C9.76021 25.3166 11.7202 26.3642 13.854 26.7886C15.9878 27.2131 18.1995 26.9952 20.2095 26.1627C22.2195 25.3301 23.9375 23.9202 25.1462 22.1113C26.3549 20.3023 27 18.1756 27 16C27 13.0826 25.8411 10.2847 23.7782 8.22183C21.7153 6.15893 18.9174 5.00001 16 5.00001Z"
        fill="inherit"
      />
      <path
        d="M11.7606 21.2401C11.629 21.2408 11.4985 21.2156 11.3767 21.1659C11.2548 21.1161 11.144 21.0428 11.0506 20.9501C10.8643 20.7627 10.7598 20.5093 10.7598 20.2451C10.7598 19.9809 10.8643 19.7274 11.0506 19.5401L19.5406 11.0501C19.6331 10.9542 19.7437 10.8775 19.8659 10.8244C19.9881 10.7713 20.1196 10.7427 20.2529 10.7404C20.3861 10.7381 20.5185 10.762 20.6425 10.8109C20.7665 10.8597 20.8797 10.9325 20.9756 11.0251C21.0714 11.1177 21.1481 11.2282 21.2013 11.3504C21.2544 11.4727 21.2829 11.6042 21.2853 11.7374C21.2876 11.8707 21.2636 12.0031 21.2148 12.1271C21.1659 12.2511 21.0931 12.3642 21.0006 12.4601L12.4606 21.0001C12.2665 21.1667 12.016 21.2525 11.7606 21.2401Z"
        fill="inherit"
      />
      <path
        d="M20.2391 21.24C19.9767 21.2389 19.7253 21.1348 19.5391 20.95L11.0491 12.46C10.8852 12.2687 10.7996 12.0227 10.8093 11.771C10.8191 11.5193 10.9234 11.2806 11.1015 11.1025C11.2796 10.9244 11.5183 10.82 11.77 10.8103C12.0217 10.8006 12.2677 10.8862 12.4591 11.05L20.9991 19.54C21.099 19.6336 21.1786 19.7466 21.2331 19.8722C21.2875 19.9978 21.3156 20.1332 21.3156 20.27C21.3156 20.4069 21.2875 20.5423 21.2331 20.6679C21.1786 20.7934 21.099 20.9065 20.9991 21C20.8956 21.09 20.7747 21.1577 20.644 21.199C20.5133 21.2403 20.3754 21.2543 20.2391 21.24Z"
        fill="inherit"
      />
    </svg>
  )
}
