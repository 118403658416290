import React from 'react'

/**
 *
 * @returns Info Icon
 */
export default function Info1({ className, circleClassName, pathClassName }) {
  return (
    <svg viewBox="0 0 48 48" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" className={circleClassName ? circleClassName : 'MTS__fill-whitesmoke-400'} />
      <path
        d="M28.0554 13.6663C27.6301 13.1464 27.0904 12.7267 26.4764 12.4382C25.8624 12.1498 25.1898 12 24.5084 12C23.827 12 23.1544 12.1498 22.5404 12.4382C21.9264 12.7267 21.3867 13.1464 20.9614 13.6663C20.5331 14.1932 20.2363 14.8104 20.0947 15.4688C19.9531 16.1273 19.9705 16.8089 20.1456 17.4596L22.7349 27.939C22.831 28.3135 23.0525 28.6459 23.3642 28.8835C23.6759 29.121 24.0599 29.25 24.4552 29.25C24.8505 29.25 25.2345 29.121 25.5462 28.8835C25.8579 28.6459 26.0794 28.3135 26.1755 27.939L28.8535 17.4423C29.0281 16.7955 29.0469 16.1179 28.9085 15.4628C28.77 14.8076 28.478 14.1927 28.0554 13.6663ZM25.4661 16.6109L24.5084 20.3869L23.5507 16.6109C23.508 16.4688 23.5018 16.3185 23.5328 16.1735C23.5637 16.0285 23.6308 15.8931 23.7281 15.7795C23.8189 15.6612 23.9367 15.5651 24.0722 15.499C24.2076 15.4329 24.357 15.3984 24.5084 15.3984C24.6598 15.3984 24.8092 15.4329 24.9446 15.499C25.0801 15.5651 25.1979 15.6612 25.2887 15.7795C25.386 15.8931 25.4531 16.0285 25.484 16.1735C25.515 16.3185 25.5088 16.4688 25.4661 16.6109Z"
        className={pathClassName ? pathClassName : 'MTS__fill-primary-200'}
      />
      <path
        d="M24.875 36C26.3247 36 27.5 34.8247 27.5 33.375C27.5 31.9253 26.3247 30.75 24.875 30.75C23.4253 30.75 22.25 31.9253 22.25 33.375C22.25 34.8247 23.4253 36 24.875 36Z"
        className={pathClassName ? pathClassName : 'MTS__fill-primary-200'}
      />
    </svg>
  )
}
