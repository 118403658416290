import currency_symbols from 'currency-symbol-map/map'
// import html2canvas from 'html2canvas'
// import { jsPDF } from 'jspdf'
// import { toBlob, toPng } from 'html-to-image'

/**
 * Add days to a date
 * @param {Date | number} date date
 * @param {number} days days to add
 * @returns
 */
const addDays = (date, days) => {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

/**
 * @description Format date to string --- [29-02-2022]
 * @param {Date} date date to format
 * @param {String} format date format
 * @returns formatted date
 */
const customDate = (date, format = 'dd-mm-yyyy') => {
  if (format === 'dd-mm-yyyy') {
    return `${('0' + date.getDate()).slice(-2)}-${('0' + (date.getMonth() + 1)).slice(-2)}-${date.getFullYear()}`
  } else if (format === 'mm-dd-yyyy') {
    return `${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}-${date.getFullYear()}`
  } else if (format === 'yyyy-mm-dd') {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
  }
}

const formatPrice = (price, language = 'en', priceMinFraction = 0, priceMaxFraction = 0) => {
  // return language == 'de'
  //   ? `${price.toLocaleString(`de-DE`, {
  //       minimumFractionDigits: priceMinFraction,
  //       maximumFractionDigits: priceMaxFraction,
  //     })} ${currency_symbols['EUR'] || currency_symbols['EUR']}`
  //   : `${currency_symbols['EUR'] || currency_symbols['EUR']} ${price.toLocaleString(`de-DE`, {
  //       minimumFractionDigits: priceMinFraction,
  //       maximumFractionDigits: priceMaxFraction,
  //     })}`
  return `${price.toLocaleString(`${language}-${language.toUpperCase()}`, {
    minimumFractionDigits: priceMinFraction,
    maximumFractionDigits: priceMaxFraction,
  })} ${currency_symbols['EUR'] || currency_symbols['EUR']}`
}

// Return date with "." separator
const formatDate = (date, language) => {
  // const dateLocales = {
  //   en: 'en-EN',
  //   de: 'de-DE',
  //   it: 'it-IT',
  // }
  // return `${date.toLocaleDateString(dateLocales[language == 'de' ? 'de' : 'it'])}`
  // if (language === 'de') {
  //   return `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`
  // } else {
  //   return `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`
  // }
  return `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`
}

/**
 * @description Remove params from URL
 * @param {string} key key to remove
 * @param {string} sourceURL source url
 */
function removeParam(key, sourceURL) {
  var rtn = sourceURL.split('?')[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : ''
  if (queryString !== '') {
    params_arr = queryString.split('&')
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split('=')[0]
      if (param === key) {
        params_arr.splice(i, 1)
      }
    }
    if (params_arr.length) rtn = rtn + '?' + params_arr.join('&')
  }
  return rtn
}

/**
 * @description print document from DOM nodes
 * @param {htmlElement} ref DOM node which we want to print
 * @param {Function} setLoader handler which calls after print
 */

// const printDocument = async (ref, setLoader) => {
//   // standard a4 size: 794px , 1123px
//   /** Get parent div from emailString */
//   const el = ref.contentWindow.document.body.querySelector('div')
//   setLoader && setLoader(true)
//   // console.log(el)
//   // el.style.display = 'flex'
//   // el.style.alignItems = 'center'
//   // el.style.justifyContent = 'center'
//   // /** Set pdf height width */
//   // el.style.width = '794px'
//   // el.style.height = '1123px'
//   // /** Style image and p tags for pdf */
//   // console.log(el.style.width, el.style.height)
//   // const pTags = el.getElementsByTagName('p')
//   const imgTags = el.getElementsByTagName('img')
//   // imgTags[0].style.maxHeight = '250px'
//   for (let i = 0; i < imgTags.length; i++) {
//     // imgTags[i].style.paddingTop = '16px'
//     // if (i === 1) {
//     imgTags[i].setAttribute('crossOrigin', 'anonymous')
//     // imgTags[i].src = imgTags[i].src + '?not-from-cache-please'
//     // }
//   }
//   // for (let i = 0; i < pTags.length; i++) {
//   //   pTags[i].style.margin = '4px'
//   //   pTags[i].style.padding = '0px'
//   //   pTags[i].style.paddingBottom = '16px'
//   // }

//   /** Take a snapshot of div and create a pdf :: packages(html2canvas, jspdf) */
//   await html2canvas(el, { scale: '1', useCORS: true }).then((canvas) => {
//     const componentWidth = el.offsetWidth
//     const componentHeight = el.offsetHeight
//     const orientation = componentWidth >= componentHeight ? 'l' : 'p'
//     const imgData = canvas.toDataURL('image/png')
//     const pdf = new jsPDF({
//       orientation,
//       unit: 'px',
//     })
//     pdf.internal.pageSize.width = componentWidth
//     pdf.internal.pageSize.height = componentHeight
//     pdf.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight)
//     pdf.save('pragser_parking_ticket.pdf')
//     setLoader && setLoader(false)
//     // handler && handler()
//   })
//   /** */
//   // el.style.width = 'auto'
//   // el.style.height = 'auto'
//   // for (let i = 0; i < imgTags.length; i++) {
//   //   imgTags[i].style.paddingTop = 'auto'
//   // }
//   // for (let i = 0; i < pTags.length; i++) {
//   //   // pTags[i].style.paddingBottom = 'auto'
//   //   pTags[i].style.margin = 'auto'
//   //   pTags[i].style.padding = 'auto'
//   //   pTags[i].style.paddingBottom = 'auto'
//   // }
// }

/**
 * @description print document from DOM nodes
 * @param {htmlElement} ref DOM node which we want to print
 * @param {Function} handler handler which calls after print
 */
// const getTemplateImage = async (ref, setPreviewImg, setLoader) => {
//   const el = ref.contentWindow.document.body.querySelector('div')
//   const imgTags = el.getElementsByTagName('img')
//   for (let i = 0; i < imgTags.length; i++) {
//     // imgTags[i].style.paddingTop = '16px'
//     // if (i !== 1) {
//     imgTags[i].setAttribute('crossOrigin', 'anonymous')
//     imgTags[i].src = imgTags[i].src + '?not-from-cache-please'
//     // }
//   }
//   /** Take a snapshot of div and create a pdf :: packages(html-to-image, jspdf) */
//   if (el) {
//     setLoader && setLoader(true)
//     await html2canvas(el, { scale: '1', useCORS: true }).then((canvas) => {
//       const imgData = canvas.toDataURL('image/png')
//       setPreviewImg && setPreviewImg(imgData)
//       setLoader && setLoader(false)
//     })
//   }
// }

/**
 * @description download pdf
 * @param {URL} url pdf url
 * @param {string} name name of pdf
 * @param {Function} setLoader Loader
 */
const downloadPdf = (url, filename = 'parking-ticket.pdf', setLoader) => {
  setLoader && setLoader(true)
  // window.open(url, '_blank')
  // const link = document.createElement('a')
  // link.setAttribute('download', name)
  // link.href = url
  // document.body.appendChild(link)
  // link.click()
  // link.remove()

  // const link = document.createElement('a')
  // link.href = url
  // link.setAttribute('download', name)
  // document.body.appendChild(link)
  // link.click()

  fetch(url, {
    Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
  }).then(function (t) {
    return t.blob().then((b) => {
      setLoader && setLoader(true)
      var a = document.createElement('a')
      a.href = URL.createObjectURL(b)
      a.setAttribute('download', filename)
      a.click()
      setLoader && setLoader(false)
    })
  })
}

export { customDate, removeParam, addDays, formatPrice, formatDate, downloadPdf }
