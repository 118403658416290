import React from 'react'

/**
 * @description Component to render a Heading or Sub Heading
 * @param {*} children children of the component
 * @param {string} varient heading | subHeading
 * @param {string} className css className
 * @returns Title Component
 */
export default function Title({ children, varient, className, ...headingProps }) {
  return (
    <>
      {varient === 'heading' ? (
        <div
          {...headingProps}
          className={`MTS__text-sm MTS__font-bold sm:MTS__text-base lg:MTS__text-2xl MTS__tracking-2 ${className}`}
        >
          {children}
        </div>
      ) : varient === 'subHeading' ? (
        <div
          {...headingProps}
          className={`MTS__text-sm MTS__font-semibold sm:MTS__text-base lg:MTS__text-xl MTS__tracking-1 ${className}`}
        >
          {children}
        </div>
      ) : (
        <div className={className} {...headingProps}>
          {children}
        </div>
      )}
    </>
  )
}
