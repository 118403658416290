import React, { useState } from 'react'
import FormInput from '../../../Fields/FormInput'
import FormDate from '../../../Fields/FormDate'
import FormSelectTickets from '../../../Fields/FormSelectTickets'
import Button from '../../../../Buttons/Button'
import { validateFormValues } from '../../../../../lib/ticketsValidation'
import { get } from '../../../../../api/fetch'
import AccordionItem from '../../../../Accordion/AccordionItem'
import {NoCamperIcon} from '../../../../../assets/Icons'

import { customDate, formatPrice, formatDate } from '../../../../../lib/reusableFunctions'

/**
 * @description Render ticket information form
 * @param {*} nextStep
 * @param {*} setError
 * @param {*} setLoader
 * @param {*} pageData
 * @param {*} language
 * @param {*} calenderLocale
 * @param {*} initialTicket
 * @param {*} tickets
 * @param {*} setTickets
 * @param {*} VEHICLE_TYPE
 * @param {*} TICKET_TYPE
 * @returns
 */
export default function TicketsInformation({
  nextStep,
  setError,
  setLoader,
  pageData,
  language,
  calenderLocale,
  initialTicket,
  tickets,
  setTickets,
  VEHICLE_TYPE,
  TICKET_TYPE,
  savedTickets,
  setSavedTickets,
  activeTabTickets,
  setActiveTabTickets
}) {
  // * Translations
  const { ticketsInformation, buttons } = pageData
  const { ticketInfo, ticketErrors } = ticketsInformation
  const { validations, labels, placeholders, options } = ticketsInformation.form
  const {
    lblArrival,
    lblVehicleType,
    lblTicketType,
    lblPlateNumber,
    lblRentalCar,
    lblTicketUse,
    lblNoCamper,
    lblPlateWarning,
    lblNoCalcellationText,
    lblCanExampleText,
  } = labels
  const { plhArrival, plhPlate } = placeholders
  const { optVehicleType, optTicketType } = options

  // * Accordion
  // const [activeTabTickets, setActiveTabTickets] = useState(0)
  const handleToggle = (index) => {
    setActiveTabTickets(index)
  }

  // * For ticket form
  const desktopInputs = [
    {
      id: 1,
      name: 'vehicleType',
      type: 'select',
      language,
      label: lblVehicleType[language],
      optionsArray: VEHICLE_TYPE,
      defaultOption: optVehicleType[language],
      required: true,
    },
    {
      id: 2,
      name: 'arrival',
      type: 'date',
      language,
      label: lblArrival[language],
      placeholder: plhArrival[language],
      required: true,
    },
    {
      id: 4,
      name: 'plate',
      type: 'text',
      label: lblPlateNumber[language],
      placeholder: plhPlate[language],
    },
    {
      id: 3,
      name: 'ticketType',
      type: 'select',
      language,
      label: lblTicketType[language],
      optionsArray: TICKET_TYPE,
      defaultOption: optTicketType[language],
      required: true,
    },

  ]
  const mobileInputs = [
    {
      id: 1,
      name: 'vehicleType',
      type: 'select',
      language,
      label: lblVehicleType[language],
      optionsArray: VEHICLE_TYPE,
      defaultOption: optVehicleType[language],
      required: true,
    },
    {
      id: 2,
      name: 'arrival',
      type: 'date',
      language,
      label: lblArrival[language],
      placeholder: plhArrival[language],
      required: true,
    },
    {
      id: 3,
      name: 'ticketType',
      type: 'select',
      language,
      label: lblTicketType[language],
      optionsArray: TICKET_TYPE,
      defaultOption: optTicketType[language],
      required: true,
    },
    {
      id: 4,
      name: 'plate',
      type: 'text',
      label: lblPlateNumber[language],
      placeholder: plhPlate[language],
    },
  ]

  const inputs = window.innerWidth < 1400 ? mobileInputs : desktopInputs
  const [formErrors, setFormErrors] = useState([])
  const [isSubmit, setIsSubmit] = useState(false)
  // * Slot availability for vehicle
  const [datesAvailability, setDatesAvailability] = useState({})
  // * Slots details
  const [ticketSlots, setTickteSlots] = useState({})
  // * Flag for valid ticket
  // const [savedTickets, setSavedTickets] = useState({})

  /**
   * @description Call API to get slot availability for a vehicle.
   * @param {index, { vehicleType, parkId, startDate, endDate }}
   */
  const getAvailability = async (index, { vehicleType, parkId, startDate, endDate }) => {
    setLoader(true)
    try {
      const res = await get(
        `reservation/getcalendar`,
        {
          vehicleType,
          parkId,
          startDate: customDate(new Date(startDate), 'mm-dd-yyyy'),
          endDate: customDate(new Date(endDate), 'mm-dd-yyyy'),
        },
        {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      )
      const data = await res.json()
      setLoader(false)
      if (data.error) {
        const error = ticketErrors.getAvailability.errAvailability[language].replace('{{vehicleType}}', vehicleType)
        setError(error)
        return
      }
      const newAvailability = JSON.parse(JSON.stringify(datesAvailability))
      if (data.data) {
        newAvailability[`form-${index + 1}`] = data.data
        setDatesAvailability(newAvailability)
      } else {
        newAvailability[`form-${index + 1}`] = {}
        setDatesAvailability(newAvailability)
        const error = ticketErrors.getAvailability.selectDiffType[language].replace('{{vehicleType}}', vehicleType)
        setError(error)
      }
    } catch (e) {
      setLoader(false)
      const error = ticketErrors.getAvailability.errAvailability[language].replace('{{vehicleType}}', vehicleType)
      setError(error)
    }
  }

  /**
   * @description Call API to get slot details for particular date.
   * @param {index, { startDate, endDate, parkId, vehicleType }}
   */
  const getTodaysRate = async (index, { startDate, endDate, parkId, vehicleType }) => {
    setLoader(true)
    try {
      const res = await get(
        `rates/gettodaysrate`,
        {
          startDate: customDate(new Date(startDate), 'mm-dd-yyyy'),
          endDate: customDate(new Date(endDate), 'mm-dd-yyyy'),
          parkId,
          vehicleType,
        },
        {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      )
      const data = await res.json()
      setLoader(false)

      if (data.error) {
        const error = ticketErrors.getTodaysRate.errRates[language].replace(
          '{{arrival}}',
          formatDate(new Date(startDate), language),
        )
        setError(error)
        return
      }
      if (data?.data && data?.data.length > 0) {
        const isRatesAvailable = data.data.some((slot) => slot.vehicleType === tickets[index].vehicleType)
        if (isRatesAvailable) {
          const newTicketSlots = JSON.parse(JSON.stringify(ticketSlots))
          newTicketSlots[`form-${index + 1}`] = data.data
          setTickteSlots(newTicketSlots)

          const newTickets = JSON.parse(JSON.stringify(tickets))
          newTickets[index] = {
            ...initialTicket,
            arrival: new Date(startDate),
            departure: new Date(endDate),
            plate: tickets[index]?.plate,
            rentalCar: tickets[index]?.rentalCar,
            vehicleType: tickets[index]?.vehicleType,
          }
          setTickets(newTickets)
        } else {
          // const newTickets = JSON.parse(JSON.stringify(tickets))
          // newTickets[index] = {
          //   ...initialTicket,
          //   plate: tickets[index]?.plate,
          //   rentalCar: tickets[index]?.rentalCar,
          //   vehicleType: tickets[index]?.vehicleType,
          // }
          // setTickets(newTickets)
          const error = ticketErrors.getTodaysRate.noRates[language].replace(
            '{{arrival}}',
            formatDate(new Date(startDate), language),
          )
          setError(error)
        }
      }
    } catch (e) {
      setLoader(false)
      const newTickets = JSON.parse(JSON.stringify(tickets))
      newTickets[index] = {
        ...initialTicket,
        plate: tickets[index]?.plate,
        rentalCar: tickets[index]?.rentalCar,
        vehicleType: tickets[index]?.vehicleType,
      }
      setTickets(newTickets)
      const error = ticketErrors.getTodaysRate.errRates[language].replace(
        '{{arrival}}',
        formatDate(new Date(startDate), language),
      )
      setError(error)
    }
  }

  /**
   * @description Call API to check ticket slot is available or not.
   * @param {index, { startDate, endDate, parkId, vehicleType }}
   */
  const checkSlotAvailability = async ({ arrival, vehicleType, parkId }, id) => {
    setLoader(true)
    try {
      const res = await get(
        `reservation/checkslotavailability`,
        {
          vehicleType,
          parkId,
          arrival: customDate(new Date(arrival), 'mm-dd-yyyy'),
        },
        {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      )
      const data = await res.json()
      setLoader(false)
      if (data?.error) {
        const error = ticketErrors.checkSlotAvailability.errAvailability[language].replace(
          '{{arrival}}',
          formatDate(new Date(arrival), language),
        )
        setError(error)
        setSavedTickets((prev) => ({ ...prev, [id]: false }))
        return
      }
      if (data?.data?.available && data?.data?.available > 0) {
        setSavedTickets((prev) => ({ ...prev, [id]: true }))
      } else {
        setSavedTickets((prev) => ({ ...prev, [id]: false }))
        const error = ticketErrors.checkSlotAvailability.noAvailability[language].replace(
          '{{arrival}}',
          formatDate(new Date(arrival), language),
        )
        setError(error)
      }
    } catch (e) {
      setLoader(false)
      const error = ticketErrors.checkSlotAvailability.errAvailability[language].replace(
        '{{arrival}}',
        formatDate(new Date(arrival), language),
      )
      setError(error)
    }
  }

  // * Form handling
  const handleChangeInputs = (index) => (e) => {
    const { name, value, type, checked } = e.target
    const updatedTickets = tickets.map((item, i) => {
      if (index === i) {
        if (name === 'plate') {
          return { ...item, [name]: value.toUpperCase() }
        } else if (type === 'checkbox') {
          // return { ...item, [name]: checked }
          let newObj = { ...item, [name]: checked }
          if (name === 'rentalCar') {
            if (checked) {
              if (document.getElementsByName('plate')) { document.getElementsByName('plate')[0].value = "" }
              if (document.getElementById('plate-warning-div')) { document.getElementById('plate-warning-div').style.display = "" }
              newObj['plate'] = '';
            }
            else {
              if (document.getElementById('plate-warning-div')) { document.getElementById('plate-warning-div').style.display = "none" }
            }
          }
          return { ...newObj }
        } else {
          return { ...item, [name]: value }
        }
      } else {
        return item
      }
    })
    setTickets(updatedTickets)
    if (`form-${index + 1}` in savedTickets) {
      setSavedTickets((prev) => ({ ...prev, [`form-${index + 1}`]: false }))
    }
  }

  const handleChange = (index, name, value) => {
    if (name === 'arrival') {
      getTodaysRate(index, {
        // startDate: customDate(new Date(value), 'mm-dd-yyyy'),
        // endDate: customDate(new Date(value), 'mm-dd-yyyy'),
        startDate: new Date(value),
        endDate: new Date(value),
        parkId: tickets[index].parkId,
        vehicleType: tickets[index].vehicleType,
      })
    } else {
      const updatedTickets = tickets.map((item, i) => {
        if (index === i) {
          // if (name === 'arrival') {
          //   getTodaysRate(index, {
          //     startDate: customDate(new Date(value), 'mm-dd-yyyy'),
          //     endDate: customDate(new Date(value), 'mm-dd-yyyy'),
          //   })
          //   // return { ...item, [name]: value.getTime(), departure: value.getTime() }
          //   return { ...item }
          // } else
          if (name === 'vehicleType') {
            const date = new Date(),
              y = date.getFullYear(),
              m = date.getMonth()
            getAvailability(index, {
              vehicleType: value,
              parkId: tickets?.[index].parkId,
              startDate: new Date(y, m, 1),
              endDate: new Date(y, m + 1, 0),
            })
            return {
              ...initialTicket,
              [name]: value,
              rentalCar: item.rentalCar,
              plate: item.plate,
            }
          } else if (name === 'ticketType') {
            const selectedVehicleSlot = ticketSlots?.[`form-${index + 1}`].find(
              (slot) => slot.vehicleType === item.vehicleType && slot.ticketType === value,
            )
            return {
              ...item,
              [name]: value,
              price: selectedVehicleSlot?.prices?.[0]?.amount || 0,
              servicePrice: selectedVehicleSlot?.prices?.[0]?.serviceAmount || 0,
            }
          } else {
            return { ...item, [name]: value }
          }
        } else {
          return item
        }
      })
      setTickets(updatedTickets)
    }
    if (`form-${index + 1}` in savedTickets) {
      setSavedTickets((prev) => ({ ...prev, [`form-${index + 1}`]: false }))
    }
  }

  const handleBlur = (e, index) => {
    // const { name } = e.target
    // const errors = validateFormValues(tickets, validations, language)
    // const newErrors = formErrors.map((error, errIndex) => {
    //   if(errIndex === index){
    //     error[name] = errors[index][name]
    //   }
    // })
    // // setFormErrors({ ...formErrors, [name]: errors[name] })
    // setFormErrors(validateFormValues(newErrors))
  }

  const handleSubmit = (e, index) => {
    e.preventDefault()
    const errors = validateFormValues(tickets, validations, language)
    setFormErrors(errors)
    if (errors.every((error) => Object.keys(error).length <= 0)) {
      const { arrival, vehicleType, parkId } = tickets[index]
      checkSlotAvailability({ arrival, vehicleType, parkId }, e.target.id)
      // setSavedTickets((prev) => ({ ...prev, [e.target.id]: true }))
      setIsSubmit(true)
    } else {
      // setSavedTickets((prev) => ({ ...prev, [e.target.id]: false }))
      setIsSubmit(false)
    }
  }

  function getStringDate(dateObj) {
    return dateObj.getFullYear() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getDate()
  }

  const updateTemplateString = (template, valuesToReplace) => {
    for (let data in valuesToReplace) {
      if (data && valuesToReplace[data]) {
        template = template.replace(`{{${data}}}`, valuesToReplace[data])
      }
    }
    return template
  }
  let months = {
    de: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    it: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ],
    en: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  }

  return (
    <div>
      <div className="MTS__flex MTS__flex-col MTS__gap-6">
        {tickets.map((ticket, index) => {
          let isCancellation = true
          let cancellationEgTxt = lblCanExampleText[language]

          let a_egDate = '10'
          let a_egmonth = months[language][7]
          let a_egYear = '2024'
          let c_egDate = '06'
          let c_egmonth = months[language][7]
          let c_egYear = '2024'

          if (ticket?.arrival) {
            let todayDate = getStringDate(new Date())
            let selectedArrival = typeof ticket?.arrival === 'string' ? new Date(ticket?.arrival) : ticket?.arrival

            let allowedDate = new Date(selectedArrival)
            allowedDate.setDate(selectedArrival.getDate() - Math.abs(4))

            if (
              (getStringDate(selectedArrival) === todayDate || allowedDate < new Date()) &&
              getStringDate(allowedDate) !== todayDate
            ) {
              // Cancellation not possible
              isCancellation = false
              cancellationEgTxt = lblNoCalcellationText[language]
            } else {
              // Cancellation Possible
              let aDate = selectedArrival.getDate()
              a_egDate = (aDate.toString().length === 1 ? '0' : '') + aDate
              a_egmonth = months[language][selectedArrival.getMonth()]
              a_egYear = selectedArrival.getFullYear()

              let cDate = allowedDate.getDate()
              c_egDate = (cDate.toString().length === 1 ? '0' : '') + cDate
              c_egmonth = months[language][allowedDate.getMonth()]
              c_egYear = allowedDate.getFullYear()

              let exampleLine = {
                de: '<p>Beispiel für Stornierung und vollständige Rückerstattung:</p>',
                it: '<p>Esempio di cancellazione e rimborso completo:</p>',
                en: '<p>Example of cancellation and full refund:</p>',
              }
              cancellationEgTxt = cancellationEgTxt.replace(exampleLine[language], ' ')
            }
          }
          if (isCancellation) {
            cancellationEgTxt = updateTemplateString(cancellationEgTxt, {
              a_date: a_egDate,
              a_month: a_egmonth,
              a_year: a_egYear,
              c_date: c_egDate,
              c_month: c_egmonth,
              c_year: c_egYear,
            })
          }
          return (
            <div
              className={`${activeTabTickets !== index ? 'MTS__p-2 MTS__px-4 md:MTS__p-4 md:MTS__px-8 MTS__bg-whitesmoke' : ''
                } ${`form-${index + 1}` in savedTickets
                  ? savedTickets[`form-${index + 1}`]
                    ? 'MTS__border MTS__border-dashed MTS__border-primary-400/50'
                    : 'MTS__border MTS__border-dashed MTS__border-danger/50'
                  : ''
                }`}
              key={index}
            >
              <AccordionItem
                id={index}
                title={
                  <div className="MTS__flex MTS__gap-2">
                    <span className="MTS__font-normal">{`TICKET #0${index + 1}:`}</span>
                    <span className="MTS__font-semibold">{ticket?.plate || ''}</span>
                  </div>
                }
                isActive={activeTabTickets === index}
                onToggle={() => handleToggle(index)}
                userSelection={`us`}
                activeTab={activeTabTickets}
                hideBottomBorder={true}
                hideBtnForActiveTab={true}
                deleteIcon={true}
                onDelete={(e) => {
                  e.stopPropagation()
                  // const newTickets = tickets.slice()
                  const newTickets = JSON.parse(JSON.stringify(tickets))
                  // console.log({ newTickets })
                  newTickets.splice(index, 1)
                  setTickets(newTickets)
                  if (Object.keys(datesAvailability).length > 0 && datesAvailability?.[`form-${index + 1}`]) {
                    const newDatesAvailability = JSON.parse(JSON.stringify(datesAvailability))
                    delete newDatesAvailability?.[`form-${index + 1}`]
                    for (let i = index + 1; i < Object.keys(datesAvailability).length; i++) {
                      newDatesAvailability[`form-${i}`] = newDatesAvailability[`form-${i + 1}`]
                      delete newDatesAvailability?.[`form-${i + 1}`]
                    }
                    setDatesAvailability(newDatesAvailability)
                  }
                  if (Object.keys(ticketSlots).length > 0 && ticketSlots?.[`form-${index + 1}`]) {
                    const newTicketSlots = JSON.parse(JSON.stringify(ticketSlots))
                    delete newTicketSlots?.[`form-${index + 1}`]
                    for (let j = index + 1; j < Object.keys(ticketSlots).length; j++) {
                      newTicketSlots[`form-${j}`] = newTicketSlots[`form-${j + 1}`]
                      delete newTicketSlots?.[`form-${j + 1}`]
                    }
                    setTickteSlots(newTicketSlots)
                  }
                  if (Object.keys(savedTickets).length > 0 && savedTickets?.[`form-${index + 1}`]) {
                    const newSavedTickets = JSON.parse(JSON.stringify(savedTickets))
                    delete newSavedTickets?.[`form-${index + 1}`]
                    for (let k = index + 1; k < Object.keys(savedTickets).length; k++) {
                      newSavedTickets[`form-${k}`] = newSavedTickets[`form-${k + 1}`]
                      delete newSavedTickets?.[`form-${k + 1}`]
                    }
                    setSavedTickets(newSavedTickets)
                  }
                  if (index - 1 <= 0) {
                    setActiveTabTickets(0)
                  } else {
                    setActiveTabTickets(index - 1)
                  }
                }}
              >
                <form id={`form-${index + 1}`} onSubmit={(e) => handleSubmit(e, index)} key={index}>
                  {/* <div className="grid mx-auto font-secondary bg-whitesmoke sm:w-[60%] lg:w-[90%] xl:w-[50%] p-8 sm:p-12 lg:p-16"> */}
                  <div className="MTS__grid MTS__p-4 MTS__mx-auto MTS__font-secondary MTS__bg-whitesmoke sm:MTS__p-8">
                    <div className="MTS__grid MTS__grid-cols-1 lg:MTS__grid-cols-2 MTS__gap-2 xl:MTS__gap-6">
                      {inputs.map((input, inputIndex) => (
                        <div className="MTS__flex MTS__flex-col" key={inputIndex + index}>
                          {input.type === 'date' ? (
                            <FormDate
                              {...input}
                              value={ticket[input.name]}
                              errorMessage={formErrors?.[index] && formErrors?.[index][input.name]}
                              // onBlur={(e) => handleBlur(e, index)}
                              // onKeyUp={(e) => handleBlur(e, index)}
                              date={ticket?.arrival}
                              onChange={(value) => handleChange(index, input.name, value)}
                              calenderLocale={calenderLocale}
                              disableField={ticket.vehicleType === ''}
                              availableDates={datesAvailability[`form-${index + 1}`] || []}
                              getAvailability={(satrtDate) => {
                                const date = new Date(satrtDate),
                                  y = satrtDate.getFullYear(),
                                  m = satrtDate.getMonth()
                                getAvailability(index, {
                                  vehicleType: ticket.vehicleType,
                                  parkId: ticket.parkId,
                                  // startDate: satrtDate,
                                  startDate: new Date(y, m, 1),
                                  endDate: new Date(y, m + 1, 0),
                                })
                              }}
                            ></FormDate>
                          ) : input.type === 'select' ? (
                            <FormSelectTickets
                              {...input}
                              ticket={ticket}
                              value={ticket[input.name]}
                              onChange={(value) => handleChange(index, input.name, value)}
                              errorMessage={formErrors?.[index] && formErrors?.[index][input.name]}
                              // onBlur={(e) => handleBlur(e, index)}
                              // onKeyUp={(e) => handleBlur(e, index)}
                              optionsArray={
                                input.name === 'ticketType'
                                  ? ticketSlots && ticketSlots?.[`form-${index + 1}`]
                                    ? ticketSlots?.[`form-${index + 1}`]
                                      .map((slot) => {
                                        if (slot?.vehicleType === ticket.vehicleType) {
                                          const res = JSON.parse(JSON.stringify(input.optionsArray)).find(
                                            (opt) => opt.value === slot.ticketType,
                                          )
                                          if (res) {
                                            for (let i in res.label) {
                                              if (slot?.['prices']?.[0]?.['serviceAmount']) {
                                                res.label[i] =
                                                  res.label[i] +
                                                  ' | ' +
                                                  formatPrice(slot?.['prices']?.[0]?.['amount'], language, 2, 2) +
                                                  ' + ' +
                                                  formatPrice(
                                                    slot?.['prices']?.[0]?.['serviceAmount'],
                                                    language,
                                                    2,
                                                    2,
                                                  )
                                              } else {
                                                res.label[i] =
                                                  res.label[i] +
                                                  ' | ' +
                                                  formatPrice(slot?.['prices']?.[0]?.['amount'], language, 2, 2)
                                              }
                                            }
                                          }
                                          return res
                                        }
                                      })
                                      .filter((item) => item !== undefined)
                                    : input.optionsArray
                                  : input.optionsArray
                              }
                              defaultOption={input.defaultOption}
                              disableField={input.name === 'ticketType' && ticket.arrival === null}
                              infoMessage={
                                input.name === 'ticketType' &&
                                ticket.servicePrice > 0 &&
                                ticketInfo[language].replace(
                                  '{{serviceAmount}}',
                                  formatPrice(ticket.servicePrice, language, 2, 2),
                                )
                              }
                            ></FormSelectTickets>
                          ) : (
                            <FormInput
                              {...input}
                              value={ticket[input.name]}
                              onChange={handleChangeInputs(index)}
                              errorMessage={formErrors?.[index] && formErrors?.[index][input.name]}
                              disableField={input.name === 'plate' && ticket?.rentalCar}
                              // onBlur={(e) => handleBlur(e, index)}
                              // onKeyUp={(e) => handleBlur(e, index)}
                              required={input.name === 'plate' ? (ticket?.rentalCar ? false : true) : input?.required}
                            ></FormInput>
                          )}
                        </div>
                      ))}
                      <div className="MTS__grid MTS__gap-4 lg:MTS__col-span-2">
                        <div className="MTS__flex MTS__items-center MTS__gap-2">
                          <input
                            className={`MTS__bg-whitesmoke MTS__inline-block MTS__border MTS__border-tertiary`}
                            type="checkbox"
                            name="rentalCar"
                            id={`rentalCar${index}`}
                            key={`rentalCar${index}`}
                            // value={ticket?.rentalCar || false}
                            checked={ticket?.rentalCar || false}
                            onChange={handleChangeInputs(index)}
                          // onBlur={handleBlur}
                          />
                          <label
                            className="MTS__inline MTS__text-sm MTS__font-semibold MTS__text-primary-100 MTS__leading-5 lg:MTS__leading-6"
                            htmlFor={`rentalCar${index}`}
                          >
                            {lblRentalCar[language]}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="sm:MTS__flex MTS__gap-5 MTS__items-center MTS__justify-between">
                      <div className="MTS__text-sm MTS__py-4 MTS__font-semibold MTS__text-primary-100 MTS__leading-5 lg:MTS__leading-6 MTS__max-w-[750px]">
                        <div dangerouslySetInnerHTML={{ __html: lblPlateWarning[language] }}
                          id="plate-warning-div"
                          className='MTS__text-yellow-600'
                          style={{ display: 'none' }}>
                        </div>
                        {/* <div dangerouslySetInnerHTML={{ __html: lblTicketUse[language] }}></div> */}

                        <div
                          dangerouslySetInnerHTML={{ __html: cancellationEgTxt }}
                          className={`${!isCancellation ? 'MTS__text-danger' : ''}`}
                        ></div>
                        <div className='MTS__flex MTS__items-center MTS__gap-2'>
                          <NoCamperIcon className={"MTS__fill-inherit"}></NoCamperIcon>
                          <div className='MTS__flex MTS__items-center' dangerouslySetInnerHTML={{ __html: lblNoCamper[language] }}></div>
                        </div>
                      </div>
                      <div className="MTS__grid MTS__place-items-center MTS__gap-4 MTS__justify-self-end lg:MTS__col-span-2">
                        <Button
                          className="MTS__ml-auto MTS__min-w-[80px] lg:MTS__min-w-[148px] MTS__px-2 MTS__py-2 lg:MTS__px-4 lg:MTS__py-4"
                          varient="secondary-outline"
                          type="submit"
                        >
                          {buttons?.save[language]}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </AccordionItem>
            </div>
          )
        })}
        {/* Check any object is empty or not */}
        {/* {tickets.every(
          ({ arrival, vehicleType, ticketType, plate }) =>
            !Object.values({ arrival, vehicleType, ticketType, plate }).some((value) => value === ''),
        )} */}
        {/* <div className={`MTS__grid MTS__w-full ${tickets.length < 5 ? 'MTS__grid-cols-2' : 'MTS__grid-cols-1'}`}> */}
        <div className="MTS__w-full MTS__flex MTS__gap-2">
          {tickets.length < 5 ? (
            <Button
              className="MTS__mr-auto"
              varient={
                isSubmit &&
                  !Object.values(savedTickets).includes(false) &&
                  tickets.length === Object.keys(savedTickets).length &&
                  formErrors.every((error) => Object.keys(error).length <= 0)
                  ? 'secondary-outline'
                  : 'disabled'
              }
              // varient="background-image-light"
              type="button"
              onClick={() => {
                if (
                  isSubmit &&
                  !Object.values(savedTickets).includes(false) &&
                  tickets.length === Object.keys(savedTickets).length &&
                  formErrors.every((error) => Object.keys(error).length <= 0)
                ) {
                  setTickets((prev) => [...prev, initialTicket])
                  setActiveTabTickets((prev) => prev + 1)
                }
              }}
            >
              {buttons?.addAnotherVehicle[language]}
            </Button>
          ) : (
            <></>
          )}
          <Button
            className="MTS__ml-auto"
            // varient="secondary-outline"
            varient={
              isSubmit &&
                !Object.values(savedTickets).includes(false) &&
                tickets.length === Object.keys(savedTickets).length &&
                formErrors.every((error) => Object.keys(error).length <= 0)
                ? 'secondary-outline'
                : 'disabled'
            }
            type="button"
            onClick={() => {
              if (
                isSubmit &&
                !Object.values(savedTickets).includes(false) &&
                tickets.length === Object.keys(savedTickets).length &&
                formErrors.every((error) => Object.keys(error).length <= 0)
              ) {
                nextStep(2)
              }
            }}
          >
            {buttons?.continue[language]}
          </Button>
        </div>
      </div>
    </div>
  )
}
