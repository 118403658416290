import React from 'react'
import StepIndicator from './StepIndicator'
import Title from '../Titles/Title'
import { DropdownIcon, DashedLineIcon, TrashIcon } from '../../assets/Icons'

/**
 * @description Render accodion tab and body
 * @param {*} title accordion title --- accordion tab
 * @param {*} children children of the component --- accordion body
 * @param {Function} onToggle hide/show accordion
 * @param {boolean} isActive active accordion tab
 * @param {number} step multistep form current step
 * @param {number} id unique id for accordion
 * @param {*} userSelection show sub title on tab --- show user's selected details
 * @param {number} activeTab active accordion tab
 * @param {boolean} line steps vertical line --- not showing on last step
 * @param {boolean} hideBottomBorder hide accordion bottom border
 * @param {boolean} hideBtnForActiveTab hide accordion button on active tab
 * @param {boolean} deleteIcon show delete icon on accordion tab
 * @param {Function} onDelete Function called on click of delete button
 * @returns AccordionItem Component
 */
export default function AccordionItem({
  title,
  children,
  onToggle,
  isActive,
  step,
  id,
  userSelection,
  activeTab,
  line,
  hideBottomBorder = false,
  hideBtnForActiveTab = false,
  deleteIcon,
  onDelete,
}) {
  // TO TOGGLE AN INDIVIDUAL ITEM
  // const [activeTab, setActiveTab] = useState(false)
  // const handleToggle = () => {
  //   setActiveTab((prev) => !prev)
  // }

  return (
    <div className="MTS__gap-2 MTS__mx-auto MTS__flex MTS__w-full sm:MTS__gap-4 md:MTS__gap-8 lg:MTS__gap-12">
      {/* * STEPS STARTS */}
      {step && (
        <section>
          <StepIndicator id={id} step={step} line={line} onToggle={onToggle} activeTab={activeTab}>
            {('0' + id).toString().split().splice(-2)}
          </StepIndicator>
        </section>
      )}
      {/* * STEPS ENDS */}

      {/* * ACCORDION BUTTONS */}
      <section
        className={`MTS__flex MTS__flex-col MTS__w-[inherit] ${
          step
            ? id <= step
              ? step === 3 && id < step
                ? 'MTS__opacity-50 MTS__pointer-events-none'
                : 'MTS__opacity-100'
              : 'MTS__opacity-50 MTS__pointer-events-none'
            : ''
        } MTS__gap-4 lg:MTS__gap-8`}
        onKeyDown={(e) => {
          if (id > step) {
            e.preventDefault()
          }
        }}
        onClick={(e) => {
          if (id > step) {
            e.preventDefault()
          }
        }}
      >
        {!(hideBtnForActiveTab && isActive) && (
          <div
            className={`MTS__relative MTS__flex MTS__justify-between MTS__w-full MTS__py-1 lg:MTS__py-2 MTS__place-items-center MTS__text-primary-200 MTS__font-secondary MTS__outline-none MTS__cursor-pointer`}
            onClick={onToggle}
          >
            <div className="MTS__text-left">
              {!hideBottomBorder && (
                <span className={`MTS__absolute MTS__-bottom-1 MTS__w-full`}>
                  {/* <div style={{ borderBottom: '2px dashed #00000020' }}></div> */}
                  <DashedLineIcon className="MTS__stroke-black"></DashedLineIcon>
                </span>
              )}
              <Title
                varient="subHeading"
                className="MTS__uppercase MTS__text-primary-200 MTS__flex MTS__flex-wrap MTS__gap-1 MTS__items-center MTS__justify-start"
              >
                <span className="MTS__text-base lg:MTS__text-[22px] MTS__break-all">{title}</span>
                <span className="MTS__font-bold MTS__normal-case">{id < step && userSelection}</span>
                {deleteIcon && (
                  <a className="MTS__cursor-pointer" onClick={(e) => onDelete(e)}>
                    <TrashIcon className="MTS__fill-danger"></TrashIcon>
                  </a>
                )}
              </Title>
            </div>
            <span
              className={`${
                isActive && 'MTS__rotate-180'
              } MTS__w-3 MTS__h-3 MTS__flex MTS__items-center lg:MTS__w-4 2xl:MTS__w-5`}
            >
              <DropdownIcon className="MTS__stroke-primary-400"></DropdownIcon>
            </span>
          </div>
        )}
        <div className={`${isActive ? 'MTS__h-auto MTS__block' : 'MTS__hidden'}`}>{children}</div>
      </section>
    </div>
  )
}
