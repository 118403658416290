import React, { useEffect } from 'react'
import { CloseIcon, Info1Icon } from '../../assets/Icons'

/**
 * @description Render tost notifications
 * @param {string} varient toast varient {success | danger }
 * @param {string} title toast title
 * @param {string} message toast message
 * @param {Function} handleClick handler to close toast notification
 * @param {boolean} autoDelete automatically delete the toast notification if true
 * @param {number} autoDeleteTime automatically delete the toast notification after given time(milliseconds)
 * @returns Toast Component
 */
export default function Toast({ varient, title, message, handleClick, autoDelete, autoDeleteTime }) {
  useEffect(() => {
    if (autoDelete) {
      const interval = setTimeout(() => {
        handleClick()
      }, autoDeleteTime)

      return () => {
        clearInterval(interval)
      }
    }
  }, [autoDelete])
  return (
    <>
      {varient === 'success' ? (
        <div className="MTS__box-border MTS__fixed MTS__z-[999999] MTS__flex MTS__gap-4 MTS__p-4 MTS__transition-transform MTS__duration-75 MTS__ease-in-out MTS__font-base MTS__top-5 MTS__right-5 MTS__bg-primary-400 MTS__w-[90%] sm:MTS__w-[450px] MTS__font-secondary MTS__text-base">
          <button
            className="MTS__self-start MTS__order-3 MTS__w-10 MTS__h-10 MTS__text-whitesmoke lg:MTS__w-7 lg:MTS__h-7"
            onClick={handleClick}
          >
            <CloseIcon className="MTS__fill-whitesmoke"></CloseIcon>
          </button>
          <div className="MTS__self-start MTS__order-1 MTS__w-10 MTS__h-10">
            <Info1Icon circleClassName="MTS__fill-whitesmoke-400" pathClassName="MTS__fill-primary-400"></Info1Icon>
          </div>
          <div className="MTS__order-2 MTS__w-full MTS__flex MTS__flex-col">
            <div className="MTS__font-bold MTS__text-white">{title}</div>
            <div className="MTS__font-semibold MTS__text-white MTS__whitespace-pre-line">{message}</div>
          </div>
        </div>
      ) : varient === 'danger' ? (
        <div className="MTS__box-border MTS__fixed MTS__z-[999999] MTS__flex MTS__gap-4 MTS__p-4 MTS__transition-transform MTS__duration-75 MTS__ease-in-out MTS__font-base MTS__top-5 MTS__right-5 MTS__bg-red-500 MTS__w-[90%] sm:MTS__w-[450px] MTS__font-secondary MTS__text-base">
          <button
            className="MTS__self-start MTS__order-3 MTS__w-10 MTS__h-10 MTS__text-white lg:MTS__w-7 lg:MTS__h-7"
            onClick={handleClick}
          >
            <CloseIcon className="MTS__fill-primary-400"></CloseIcon>
          </button>
          <div className="MTS__self-start MTS__order-1 MTS__w-10 MTS__h-10">
            <Info1Icon circleClassName="MTS__fill-whitesmoke-400" pathClassName="MTS__fill-primary-400"></Info1Icon>
          </div>
          <div className="MTS__order-2 MTS__w-full MTS__flex MTS__flex-col">
            <div className="MTS__font-bold MTS__text-white">{title}</div>
            <div className="MTS__font-semibold MTS__text-white MTS__whitespace-pre-line">{message}</div>
          </div>
        </div>
      ) : null}
    </>
  )
}
