import React, { createContext, useContext, useState, useEffect } from 'react'
import jsonPageData from '../lib/pageData.json'

const ParkingDataContext = createContext()

/** Return context values.
 * @returns {pageData,language, error, setError,loader, setLoader}
 */
const useParkingContext = () => useContext(ParkingDataContext)

/**
 * Context Provider for parking page.
 */
const ParkingDataProvider = ({ children }) => {
  const [pageData, setPageData] = useState(jsonPageData?.parkingPage)
  // const [language, setLanguage] = useState(jsonPageData.language)

  const [error, setError] = useState('')
  const [successInfo, setSuccessInfo] = useState('')
  const [loader, setLoader] = useState(false)

  /** Set page data and language. */
  const fnPageData = async () => {
    setPageData(jsonPageData?.parkingPage)
    // setLanguage(jsonPageData?.language || 'en')
  }

  useEffect(() => {
    fnPageData()
  }, [])

  const value = {
    pageData,
    // language,
    error,
    setError,
    successInfo,
    setSuccessInfo,
    loader,
    setLoader,
  }

  return <ParkingDataContext.Provider value={value}>{children}</ParkingDataContext.Provider>
}

export { ParkingDataProvider, useParkingContext }
