import React from 'react'

/**
 * @description Render form inputs
 * @param {string} lable label for input
 * @returns
 */
export default function FormInput({
  label,
  onChange,
  id,
  errorMessage,
  required,
  lblClassName,
  inputClassName,
  type,
  ...inputProps
}) {
  return (
    <div className={`${inputProps.disableField && 'MTS__cursor-not-allowed'} MTS__flex MTS__flex-col`}>
      <div>
        <label
          className={`MTS__inline-block MTS__text-xs MTS__font-semibold ${inputProps.disableField ? 'MTS__text-sandstone-40' :'MTS__text-primary-100'} MTS__leading-5 lg:MTS__leading-6 lg:MTS__mb-1 ${
            lblClassName ? lblClassName : ''
          }`}
          htmlFor={id}
        >
          {label}
        </label>
        {required && <span className="MTS__inline-block MTS__text-danger">*</span>}
      </div>
      {type === 'textarea' ? (
        <textarea
          {...inputProps}
          onChange={onChange}
          className={`MTS__block MTS__bg-whitesmoke MTS__text-primary-400 MTS__text-sm lg:MTS__text-base MTS__font-semibold MTS__w-full MTS__px-2 MTS__py-3 lg:MTS__px-4 lg:MTS__py-5 MTS__border focus:MTS__outline-none ${
            errorMessage ? '!MTS__border-danger' : 'MTS__border-tertiary'
          } placeholder:MTS__text-primary-100/50 placeholder:MTS__uppercase placeholder:MTS__text-sm lg:placeholder:MTS__text-base placeholder:MTS__font-normal MTS__resize-none ${inputClassName}`}
        />
      ) : (
        <input
          {...inputProps}
          type={type}
          onChange={onChange}
          disabled={inputProps.disableField}
          className={`MTS__block MTS__bg-whitesmoke MTS__text-primary-400 MTS__border-tertiary MTS__text-sm lg:MTS__text-base MTS__font-semibold MTS__w-full MTS__px-2 MTS__py-3 lg:MTS__px-4 lg:MTS__py-5 MTS__border focus:MTS__outline-none ${
            errorMessage ? '!MTS__border-danger' : 'MTS__border-tertiary'
          } placeholder:MTS__text-primary-100/50 placeholder:MTS__uppercase placeholder:MTS__text-sm lg:placeholder:MTS__text-base placeholder:MTS__font-normal ${inputClassName}`}
        />
      )}
      <span className={`MTS__text-danger MTS__text-sm`}>{errorMessage}</span>
    </div>
  )
}
