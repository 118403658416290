import React, { useEffect, useState } from 'react'
import './PaymentInformation.scss'
import Button from '../../../../Buttons/Button'
import Toast from '../../../../Toast/Toast'
import { Info2Icon } from '../../../../../assets/Icons'

/**
 * @description Render payment information and payment form from multi step form
 * @param {*} checkout
 * @param {*} prevStep
 * @param {*} setError
 * @param {*} pageData
 * @param {*} language
 * @returns
 */
export default function PaymentInformation({ checkout, prevStep, setError, pageData, language }) {
  // * Translations
  const { notes, buttons, errors } = pageData
  const arrCustomNotes = notes?.ticketsInfo[language].split(' ')
  const notesFirstWord = arrCustomNotes.shift()
  const notesString = arrCustomNotes.join(' ')

  // * Hobex checkout ID
  const [checkoutId, setCheckoutId] = useState('')

  useEffect(() => {
    if (checkout) {
      // setCheckoutId(checkout.data.checkoutId)
      setCheckoutId(checkout)
    }
  }, [checkout])

  // * Render hobex form
  useEffect(() => {
    if (checkoutId) {
      // * Script to append customization variable :: style: 'card' | 'plain'
      // * locale: languagecode
      const style = {
        style: 'card',
        locale: language || 'en',
      }

      const scriptAppendVar = document.createElement('script')
      // * Script to append payment form
      const scriptAppendPayment = document.createElement('script')

      try {
        const variableNode = document.createTextNode(`var wpwlOptions = ${JSON.stringify(style)}`)
        scriptAppendVar.appendChild(variableNode)
        document.body.appendChild(scriptAppendVar)

        scriptAppendPayment.src = `${process.env.REACT_APP_HOBEX_FORM_LIVE_URL}paymentWidgets.js?checkoutId=${checkoutId}`
        document.body.appendChild(scriptAppendPayment)
      } catch (e) {
        setError(errors?.errWentWrong)
      }
      return () => {
        document.body.removeChild(scriptAppendVar)
        document.body.removeChild(scriptAppendPayment)
      }
    }
  }, [checkoutId])

  return (
    <div>
      <div className={`MTS__flex MTS__flex-col MTS__gap-4 MTS__place-items-center lg:MTS__gap-8`}>
        <Button
          className="MTS__text-base MTS__bg-opacity-50 MTS__border-2 MTS__bg-secondary lg:MTS__text-xl"
          varient="secondary-outline-dashed"
        >
          <div className="MTS__grid MTS__justify-start MTS__grid-flow-col MTS__gap-2 lg:MTS__gap-4 MTS__place-items-center">
            <div className="MTS__w-4 MTS__h-4 lg:MTS__w-6 lg:MTS__h-6">
              <Info2Icon circleClassName="MTS__fill-secondary" pathClassName="MTS__fill-white"></Info2Icon>
            </div>
            <div className="MTS__leading-4 MTS__text-left lg:MTS__leading-7 MTS__tracking-1">
              <span className="MTS__font-semibold">{notesFirstWord} </span>
              {notesString}
            </div>
          </div>
        </Button>
        {/* data-brands :: brand options */}
        <form
          id="payment-form"
          action={window.location.href}
          className={`paymentWidgets`}
          data-brands="VISA MASTER"
          method="POST"
          target="cnpIframe"
          lang={language || 'en'}
        ></form>
        <Button
          className="MTS__text-base MTS__bg-opacity-50 MTS__border-2 MTS__bg-secondary lg:MTS__text-xl"
          varient="secondary-outline-dashed"
        >
          <div className="MTS__grid MTS__justify-start MTS__grid-flow-col MTS__gap-2 lg:MTS__gap-4 MTS__place-items-center">
            <div className="MTS__w-4 MTS__h-4 lg:MTS__w-6 lg:MTS__h-6">
              <Info2Icon circleClassName="MTS__fill-secondary" pathClassName="MTS__fill-white"></Info2Icon>
            </div>
            <div className="MTS__leading-4 MTS__text-left lg:MTS__leading-7 MTS__tracking-1">
              {notes?.spamInfo[language]}
            </div>
          </div>
        </Button>

        <Button
          className="MTS__text-base MTS__bg-opacity-50 MTS__border-2 MTS__bg-secondary lg:MTS__text-xl"
          varient="secondary-outline-dashed"
        >
          <div className="MTS__grid MTS__justify-start MTS__grid-flow-col MTS__gap-2 lg:MTS__gap-4 MTS__place-items-center">
            <div className="MTS__w-4 MTS__h-4 lg:MTS__w-6 lg:MTS__h-6">
              <Info2Icon circleClassName="MTS__fill-secondary" pathClassName="MTS__fill-white"></Info2Icon>
            </div>
            <div className="MTS__leading-4 MTS__text-left lg:MTS__leading-7 MTS__tracking-1">
              {notes?.refundInfo[language]}
            </div>
          </div>
        </Button>


        <Button className="MTS__self-start" varient="dark-outline" type="button" onClick={prevStep}>
          {buttons?.previous[language]}
        </Button>
      </div>
    </div>
  )
}
