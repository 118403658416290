import React from 'react'
import Title from '../Titles/Title'
import { CloseIcon } from '../../assets/Icons'

/**
 * @description Render popup
 * @param {*} children children of the component
 * @param {string} title popup title
 * @param {Function} handleClose handler to close popup
 * @param {boolean} disableCloseBtn disable the popup close button if true
 * @param {string} className disable the popup close button if true
 * @returns Popup Component
 */
export default function Popup({ children, title, handleClose, disableCloseBtn, className,customHeaderClass = "" }) {
  return (
    <div className="MTS__fixed MTS__inset-0 MTS__z-[333333] MTS__bg-black MTS__bg-opacity-50">
      <div
        className={`MTS__relative MTS__flex MTS__flex-col MTS__gap-4 sm:MTS__gap-8 MTS__p-4 MTS__pt-4 sm:MTS__p-8 MTS__-translate-x-1/2 MTS__-translate-y-1/2 MTS__bg-whitesmoke MTS__top-1/2 MTS__left-1/2 MTS__w-[90vw] lg:MTS__w-[70vw] xl:MTS__w-[50vw] MTS__h-auto MTS__max-h-[95vh] ${className}`}
      >
        {!disableCloseBtn && (
          <button
            className="MTS__absolute MTS__self-end MTS__w-5 MTS__h-5 MTS__text-white MTS__right-2 MTS__top-2 sm:MTS__right-4 sm:MTS__top-4 sm:MTS__w-6 sm:MTS__h-6 lg:MTS__w-7 lg:MTS__h-7"
            onClick={handleClose}
          >
            <CloseIcon className="MTS__fill-primary-200"></CloseIcon>
          </button>
        )}
        {title ? (
          <Title
            varient="heading"
            className={`MTS__w-full MTS__py-4 MTS__text-center MTS__uppercase MTS__border-b-2 MTS__text-secondary MTS__border-secondary ${customHeaderClass}`}
          >
            {title}
          </Title>
        ) : null}
        <div className="MTS__flex MTS__flex-col MTS__self-center MTS__order-2 MTS__w-full MTS__min-h-[40px] MTS__justify-center MTS__overflow-y-auto MTS__text-primary-100">
          {/* <div className="MTS__text-primary-100">{children}</div> */}
          {children}
        </div>
      </div>
    </div>
  )
}
