import React, { useState, useEffect } from 'react'
import { get } from '../../../../api/fetch'
import { customDate, formatPrice, formatDate } from '../../../../lib/reusableFunctions'
import { validateFormValues } from '../../../../lib/ticketsValidation'
import FormDate from '../../../Forms/Fields/FormDate'
import FormInput from '../../../Forms/Fields/FormInput'
import FormSelectTickets from '../../../Forms/Fields/FormSelectTickets'
import Button from '../../../Buttons/Button'

const ChangeTicket = ({
  pageData,
  language,
  setLoader,
  setError,
  calenderLocale,
  manageTicket,
  setManageTicket,
  tempManageTicket,
  setPopup,
  setPopupBack,
  VEHICLE_TYPE,
  TICKET_TYPE,
}) => {
  // * Translations
  const { ticketsInformation, buttons } = pageData
  const { ticketInfo, ticketErrors } = ticketsInformation
  const { validations, labels, placeholders, options } = ticketsInformation.form
  const { lblArrival, lblVehicleType, lblTicketType, lblPlate, lblPlateExample } = labels
  const { plhArrival, plhPlate } = placeholders
  const { optVehicleType, optTicketType } = options

  // * For ticket form
  const inputs = [
    {
      id: 1,
      name: 'vehicleType',
      type: 'select',
      language,
      label: lblVehicleType[language],
      optionsArray: VEHICLE_TYPE,
      defaultOption: optVehicleType[language],
      required: false,
    },
    {
      id: 2,
      name: 'arrival',
      type: 'date',
      language,
      label: lblArrival[language],
      placeholder: plhArrival[language],
      required: true,
    },
    {
      id: 3,
      name: 'ticketType',
      type: 'select',
      language,
      label: lblTicketType[language],
      optionsArray: TICKET_TYPE,
      defaultOption: optTicketType[language],
      required: false,
    },
    {
      id: 4,
      name: 'plate',
      type: 'text',
      label: lblPlate[language] + ' ' + lblPlateExample[language],
      placeholder: plhPlate[language],
      required: true,
    },
  ]
  const [formErrors, setFormErrors] = useState({})
  // const [isSubmit, setIsSubmit] = useState(false)
  // * Slot availability for vehicle
  const [datesAvailability, setDatesAvailability] = useState({})
  // * Slots details
  const [ticketSlots, setTickteSlots] = useState({})

  /**
   * @description Call API to get slot availability for a vehicle.
   * @param {index, { vehicleType, parkId, startDate, endDate }}
   */
  const getAvailability = async (index, { vehicleType, parkId, startDate, endDate }) => {
    setLoader(true)
    try {
      const res = await get(
        `reservation/getcalendar`,
        {
          vehicleType,
          parkId,
          startDate: customDate(new Date(startDate), 'mm-dd-yyyy'),
          endDate: customDate(new Date(endDate), 'mm-dd-yyyy'),
        },
        {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      )
      const data = await res.json()
      setLoader(false)
      if (data.error) {
        const error = ticketErrors.getAvailability.errAvailability[language].replace('{{vehicleType}}', vehicleType)
        setError(error)
        return
      }
      const newAvailability = JSON.parse(JSON.stringify(datesAvailability))
      if (data.data) {
        newAvailability[`form-${index + 1}`] = data.data
        setDatesAvailability(newAvailability)
      } else {
        newAvailability[`form-${index + 1}`] = {}
        setDatesAvailability(newAvailability)
        const error = ticketErrors.getAvailability.noAvailability[language].replace('{{vehicleType}}', vehicleType)
        setError(error)
      }
    } catch (e) {
      setLoader(false)
      const error = ticketErrors.getAvailability.errAvailability[language].replace('{{vehicleType}}', vehicleType)
      setError(error)
    }
  }

  /**
   * @description Call API to get slot details for particular date.
   * @param {index, { startDate, endDate, parkId, vehicleType }, flagHideError}
   */
  const getTodaysRate = async (index, { startDate, endDate, parkId, vehicleType }, flagHideError) => {
    setLoader(true)
    try {
      const res = await get(
        `rates/gettodaysrate`,
        {
          startDate: customDate(new Date(startDate), 'mm-dd-yyyy'),
          endDate: customDate(new Date(endDate), 'mm-dd-yyyy'),
          parkId,
          vehicleType,
        },
        {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      )
      const data = await res.json()
      setLoader(false)

      if (data.error) {
        const error = ticketErrors.getTodaysRate.errRates[language].replace(
          '{{arrival}}',
          formatDate(new Date(startDate), language),
        )
        setError(error)
        return
      }
      if (data?.data && data?.data.length > 0) {
        const isRateAvailable = data.data.some((slot) => slot.vehicleType === manageTicket.tickets[0].vehicleType)
        if (isRateAvailable) {
          const newTicketSlots = JSON.parse(JSON.stringify(ticketSlots))
          newTicketSlots[`form-${index + 1}`] = data.data
          // const filteredSlots = data.data.filter(
          //   (slt) =>
          //     slt.ticketType === manageTicket.tickets[0].ticketType &&
          //     slt.vehicleType === manageTicket.tickets[0].vehicleType,
          // )
          const hasSameRate = data.data.some(
            (slot) =>
              slot.vehicleType === manageTicket.tickets[0].vehicleType &&
              slot.ticketType === manageTicket.tickets[0].ticketType &&
              slot.prices[0].amount + (slot.prices[0].serviceAmount || 0) ===
                manageTicket.tickets[0].amount + (manageTicket.tickets[0].serviceAmount || 0),
            // &&
            // slot.prices[0].serviceAmount
            //   ? slot.prices[0].serviceAmount === manageTicket.tickets[0].serviceAmount
            //   : 0 === manageTicket.tickets[0].serviceAmount,
          )
          if (hasSameRate) {
            setTickteSlots(newTicketSlots)
            setManageTicket((prev) => ({
              ...prev,
              tickets: prev.tickets.map((item, i) => {
                if (index === i) {
                  return {
                    ...item,
                    arrival: new Date(startDate),
                    departure: new Date(endDate),
                  }
                } else {
                  return item
                }
              }),
            }))
          } else {
            setManageTicket((prev) => ({
              ...prev,
              tickets: prev.tickets.map((item, i) => {
                if (index === i) {
                  return {
                    ...item,
                    arrival: tempManageTicket?.tickets[0].arrival,
                    departure: tempManageTicket?.tickets[0].departure,
                  }
                } else {
                  return item
                }
              }),
            }))
            if (!flagHideError) {
              const error = ticketErrors.getTodaysRate.differentRates[language]
              setError(error)
            }
          }
        } else {
          const error = ticketErrors.getTodaysRate.noRates[language].replace(
            '{{arrival}}',
            formatDate(new Date(startDate), language),
          )
          setError(error)
        }
      } else {
        const error = ticketErrors.getTodaysRate.noRates[language].replace(
          '{{arrival}}',
          formatDate(new Date(startDate), language),
        )
        setError(error)
      }
    } catch (e) {
      setLoader(false)
      const error = ticketErrors.getTodaysRate.errRates[language].replace(
        '{{arrival}}',
        formatDate(new Date(startDate), language),
      )
      setError(error)
    }
  }

  /**
   * @description Call API to check ticket slot is available or not.
   * @param {index, { arrival, vehicleType, parkId, plate, email, reservationId }}
   */
  const checkSlotAvailability = async (index, { arrival, vehicleType, parkId, plate, email, reservationId }) => {
    setLoader(true)
    try {
      const res = await get(
        `reservation/checkslotavailability`,
        {
          vehicleType,
          parkId,
          arrival: customDate(new Date(arrival), 'mm-dd-yyyy'),
        },
        {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      )
      const data = await res.json()
      setLoader(false)

      if (data?.error) {
        setManageTicket((prev) => ({
          ...prev,
          tickets: prev.tickets.map((item, i) => {
            if (index === i) {
              return {
                ...item,
                arrival: tempManageTicket?.tickets[0].arrival,
                departure: tempManageTicket?.tickets[0].departure,
              }
            } else {
              return item
            }
          }),
        }))
        const error = ticketErrors.checkSlotAvailability.errAvailability[language].replace(
          '{{arrival}}',
          formatDate(new Date(arrival), language),
        )
        setError(error)
        return
      }

      if (data?.data?.available && data?.data?.available > 0) {
        setPopup()
      } else {
        setManageTicket((prev) => ({
          ...prev,
          tickets: prev.tickets.map((item, i) => {
            if (index === i) {
              return {
                ...item,
                arrival: tempManageTicket?.tickets[0].arrival,
                departure: tempManageTicket?.tickets[0].departure,
              }
            } else {
              return item
            }
          }),
        }))
        const error = ticketErrors.checkSlotAvailability.noAvailability[language].replace(
          '{{arrival}}',
          formatDate(new Date(arrival), language),
        )
        setError(error)
      }
    } catch (e) {
      setLoader(false)
      const error = ticketErrors.checkSlotAvailability.errAvailability[language].replace(
        '{{arrival}}',
        formatDate(new Date(arrival), language),
      )
      setError(error)
    }
  }

  // * Form handling
  const handleChangeInputs = (index) => (e) => {
    const { name, value } = e.target
    setManageTicket((prev) => ({
      ...prev,
      tickets: prev.tickets.map((item, i) => {
        if (index === i) {
          if (name === 'plate') {
            return {
              ...item,
              [name]: value.toUpperCase(),
              template: item.plate ? item.template.replace(item.plate, value) : item.template.replace('N/A', value),
            }
          } else {
            return { ...item, [name]: value }
          }
        } else {
          return item
        }
      }),
    }))
  }

  const handleChange = (index, name, value) => {
    if (name === 'arrival') {
      getTodaysRate(index, {
        // startDate: customDate(new Date(value), 'mm-dd-yyyy'),
        // endDate: customDate(new Date(value), 'mm-dd-yyyy'),
        startDate: new Date(value),
        endDate: new Date(value),
        parkId: manageTicket?.tickets[index].parkId,
        vehicleType: manageTicket?.tickets[index].vehicleType,
      })
    } else {
      setManageTicket((prev) => ({
        ...prev,
        tickets: prev.tickets.map((item, i) => {
          if (index === i) {
            // if (name === 'arrival') {
            //   return { ...item, [name]: value.getTime(), departure: value.getTime() }
            // } else {
            return { ...item, [name]: value }
            // }
          } else {
            return item
          }
        }),
      }))
    }
  }

  const handleBlur = (e, index) => {
    // const { name } = e.target
    // const errors = validateFormValues(tickets, validations, language)
    // const newErrors = formErrors.map((error, errIndex) => {
    //   if(errIndex === index){
    //     error[name] = errors[index][name]
    //   }
    // })
    // // setFormErrors({ ...formErrors, [name]: errors[name] })
    // setFormErrors(validateFormValues(newErrors))
  }

  const handleSubmit = (e, index) => {
    e.preventDefault()
    const errors = validateFormValues(manageTicket?.tickets[0], validations, language)
    setFormErrors(errors)
    if (Object.keys(errors).length <= 0) {
      const { reservationId, vehicleType, parkId, arrival, plate } = manageTicket?.tickets[0]
      const { email } = manageTicket?.customer
      // if (new Date(arrival) !== new Date(tempManageTicket?.tickets[0]?.arrival)) {
      if (new Date(arrival).getTime() !== new Date(tempManageTicket?.tickets[0]?.arrival).getTime()) {
        checkSlotAvailability(index, { arrival, vehicleType, parkId, plate, email, reservationId })
      } else {
        setPopup()
      }
      // setIsSubmit(true)
    }
    //  else {
    //   setIsSubmit(false)
    // }
  }

  // useEffect(() => {
  //   if (isSubmit && Object.keys(formErrors).length <= 0) {
  //   }
  // }, [isSubmit, formErrors])
  // * Get slot availability for a vehicle and slot details of manage ticket
  useEffect(() => {
    if (Object.keys(datesAvailability).length <= 0 && Object.keys(ticketSlots).length <= 0) {
      const date = manageTicket?.tickets[0]?.arrival ? new Date(manageTicket?.tickets[0]?.arrival) : new Date(),
        y = date.getFullYear(),
        m = date.getMonth()

      // if (Object.keys(datesAvailability).length < 0) {
      getAvailability(0, {
        vehicleType: manageTicket?.tickets[0]?.vehicleType,
        parkId: manageTicket?.tickets?.[0].parkId,
        startDate: new Date(y, m, 1),
        endDate: new Date(y, m + 1, 0),
      })
      // }
      // if (Object.keys(ticketSlots).length < 0) {
      getTodaysRate(
        0,
        {
          // startDate: customDate(new Date(manageTicket?.tickets[0]?.arrival), 'mm-dd-yyyy'),
          // endDate: customDate(new Date(manageTicket?.tickets[0]?.departure), 'mm-dd-yyyy'),
          startDate: new Date(manageTicket?.tickets[0]?.arrival),
          endDate: new Date(manageTicket?.tickets[0]?.departure),
          parkId: manageTicket?.tickets[0].parkId,
          vehicleType: manageTicket?.tickets[0].vehicleType,
        },
        true,
      )
    }
    // }
  }, [])

  return (
    <form id={`form-1`} className="MTS__w-full" onSubmit={(e) => handleSubmit(e, 0)}>
      {/* <div className="grid mx-auto font-secondary bg-whitesmoke sm:w-[60%] lg:w-[90%] xl:w-[50%] p-8 sm:p-12 lg:p-16"> */}
      <div className="MTS__grid sm:MTS__grid-cols-2 MTS__gap-2 sm:MTS__gap-4 lg:MTS__gap-8 MTS__font-secondary MTS__bg-whitesmoke">
        {inputs.map((input, inputIndex) => (
          <div key={inputIndex}>
            {input.type === 'date' ? (
              <FormDate
                {...input}
                value={manageTicket?.tickets[0][input.name]}
                errorMessage={formErrors && formErrors[input.name]}
                onBlur={(e) => handleBlur(e, 0)}
                onKeyUp={(e) => handleBlur(e, 0)}
                date={manageTicket?.tickets[0]?.arrival}
                onChange={(value) => handleChange(0, input.name, value)}
                calenderLocale={calenderLocale}
                // minDate={new Date(manageTicket?.tickets[0][input.name]) > addDays(new Date(), 3) && addDays(new Date(manageTicket?.tickets[0][input.name]), -3)}
                // disableField={new Date(manageTicket?.tickets[0][input.name]) < addDays(new Date(), 3)}
                availableDates={datesAvailability[`form-${0 + 1}`] || []}
                getAvailability={(satrtDate) => {
                  const date = new Date(satrtDate),
                    y = satrtDate.getFullYear(),
                    m = satrtDate.getMonth()
                  getAvailability(0, {
                    vehicleType: manageTicket?.tickets[0].vehicleType,
                    parkId: manageTicket?.tickets[0].parkId,
                    // startDate: satrtDate,
                    startDate: new Date(y, m, 1),
                    endDate: new Date(y, m + 1, 0),
                  })
                }}
                calendarClass="!MTS__top-[-20px] sm:!MTS__left-[-50px] md:!MTS__top-[-30px]"
              ></FormDate>
            ) : input.type === 'select' ? (
              <FormSelectTickets
                {...input}
                ticket={manageTicket?.tickets[0]}
                value={manageTicket?.tickets[0][input.name]}
                onChange={(value) => handleChange(0, input.name, value)}
                errorMessage={formErrors && formErrors[input.name]}
                onBlur={(e) => handleBlur(e, 0)}
                onKeyUp={(e) => handleBlur(e, 0)}
                optionsArray={
                  input.name === 'ticketType'
                    ? ticketSlots && ticketSlots?.[`form-${0 + 1}`]
                      ? ticketSlots?.[`form-${0 + 1}`]
                          .map((slot) => {
                            if (slot?.vehicleType === manageTicket?.tickets[0].vehicleType) {
                              const res = JSON.parse(JSON.stringify(input.optionsArray)).find(
                                (opt) => opt.value === slot.ticketType,
                              )
                              if (res) {
                                for (let i in res.label) {
                                  if (manageTicket?.tickets[0].serviceAmount) {
                                    res.label[i] =
                                      res.label[i] +
                                      ' | ' +
                                      // formatPrice(slot?.['prices']?.[0]?.['amount'], language, 2, 2)
                                      formatPrice(manageTicket?.tickets[0].amount, language, 2, 2) +
                                      ' + ' +
                                      formatPrice(manageTicket?.tickets[0].serviceAmount, language, 2, 2)
                                  } else {
                                    res.label[i] =
                                      res.label[i] +
                                      ' | ' +
                                      // formatPrice(slot?.['prices']?.[0]?.['amount'], language, 2, 2)
                                      formatPrice(manageTicket?.tickets[0].amount, language, 2, 2)
                                  }
                                }
                              }
                              return res
                            }
                          })
                          .filter((item) => item !== undefined)
                      : input.optionsArray
                    : input.optionsArray
                }
                defaultOption={input.defaultOption}
                disableField={true}
                infoMessage={
                  input.name === 'ticketType' &&
                  manageTicket?.tickets[0].serviceAmount > 0 &&
                  ticketInfo[language].replace(
                    '{{serviceAmount}}',
                    formatPrice(manageTicket?.tickets[0].serviceAmount, language, 2, 2),
                  )
                }
              ></FormSelectTickets>
            ) : (
              <FormInput
                {...input}
                value={manageTicket?.tickets[0][input.name]}
                onChange={handleChangeInputs(0)}
                errorMessage={formErrors && formErrors[input.name]}
                onBlur={(e) => handleBlur(e, 0)}
                onKeyUp={(e) => handleBlur(e, 0)}
              ></FormInput>
            )}
          </div>
        ))}
        {/* <div className="MTS__grid MTS__gap-4 md:MTS__col-span-2 lg:MTS__col-span-3">
          <div className="MTS__flex MTS__items-center MTS__gap-2">
            <input
              className={`MTS__bg-whitesmoke MTS__inline-block MTS__border MTS__border-tertiary MTS__mt-1`}
              type="checkbox"
              name="rentalCar"
              id="rentalCar"
              value={manageTicket?.tickets[0]?.rentalCar || false}
              // checked={manageTicket?.tickets[0]?.rentalCar || false}
              onChange={handleChangeInputs(0)}
              // onBlur={handleBlur}
            />
            <label className="MTS__inline MTS__text-primary-100 MTS__leading-5" htmlFor="rentalCar">
              {lblRentalCar[language]}
            </label>
          </div>
        </div> */}
        <div className="MTS__grid MTS__place-items-center MTS__gap-4 sm:MTS__col-span-2">
          <div className="MTS__flex MTS__justify-between MTS__w-full MTS__my-4">
            <Button
              className="MTS__min-w-[80px] lg:MTS__min-w-[148px] MTS__px-2 MTS__py-2 lg:MTS__px-4 lg:MTS__py-5"
              varient="background-image-light"
              type="button"
              onClick={setPopupBack}
            >
              {buttons?.back[language]}
            </Button>

            <Button
              className="MTS__min-w-[80px] lg:MTS__min-w-[148px] MTS__px-2 MTS__py-2 lg:MTS__px-4 lg:MTS__py-5"
              varient="background-image-dark"
              type="submit"
            >
              {buttons?.save[language]}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ChangeTicket
